import CryptoJS from "crypto-js";
import axios from "axios";
import Signature from "../../../../../helpers/Signature";

export const fetchGetthreadAPI = async (id) => {
    let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    let resultUUID = JSON.parse(atob(decrypt));
    let payloadSignature = [id];

    let signature = Signature(payloadSignature);

    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'userindex': resultUUID.user.userindex,
        'tokenlogin': resultUUID.user.usertokenlogin,
        'signature': signature
    }

    let body = {
        'tablethreadindex': id
    }

    const currentTimestamp = new Date().getTime();

    try {
        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/getthread?id='+currentTimestamp, body, {
            headers: dataHeaders
        });
        if (response.data.status.status === 1) {
            return response;
        }
    } catch (err) {
        console.log('err', err)
    }
};
export const fetchDetailThread = async (id, startdate, endDate) => {
    let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    let resultUUID = JSON.parse(atob(decrypt));
    let payloadSignature = [id];

    let signature = Signature(payloadSignature);

    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'userindex': resultUUID.user.userindex,
        'tokenlogin': resultUUID.user.usertokenlogin,
        'signature': signature
    }

    let body = {
        'tablethreadindex': id,
        "periodstart": startdate,
        "periodend": endDate
    }

    const currentTimestamp = new Date().getTime();
    console.log('body', body)
    try {
        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/detailthread?id='+currentTimestamp, body, {
            headers: dataHeaders
        });
        console.log('res_detailthread', response)
        if (response.data.status.status === 1) {
            return response;
        }
    } catch (err) {
        console.log('err', err)
    }
};
export const fetchAddtoken = async (id, reviewtarget, tokenallocation) => {
    let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    let resultUUID = JSON.parse(atob(decrypt));
    let payloadSignature = [id, reviewtarget, tokenallocation];

    let signature = Signature(payloadSignature);

    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'userindex': resultUUID.user.userindex,
        'tokenlogin': resultUUID.user.usertokenlogin,
        'signature': signature
    }

    let body = {
        "threadindex": id,
        "reviewtarget": reviewtarget,
        "tokenallocation": tokenallocation
    }

    const currentTimestamp = new Date().getTime();

    try {
        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/addtoken?id='+currentTimestamp, body, {
            headers: dataHeaders
        });
        if (response.data.status.status === 1) {
            return response.data.status
        }
        else {
            return response.data.status
        }

    } catch (err) {
        console.log('err', err)
    }
};
export const fetchWalletBallanceAPI = async () => {

    let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    let resultUUID = JSON.parse(atob(decrypt));
    let payloadSignature = [];

    let signature = Signature(payloadSignature);

    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'userindex': resultUUID.user.userindex,
        'tokenlogin': resultUUID.user.usertokenlogin,
        'signature': signature
    }

    const currentTimestamp = new Date().getTime();
    try {
        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/walletbalance?id='+currentTimestamp, {
            headers: dataHeaders
        });
        if (response.data.status.status === 1) {
            return response.data.data;
        }
    } catch (err) {
        console.log(err)

    }
}