import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { LineCharts } from "../components/LineCharts";
export const ThreadStatistic = (props) => {
  const { show, statistic, remounting, periodDateEnd, periodDateStart, overal } = props;
  console.log("statistric", statistic);
  const getRating = (id) => {
    const data = statistic.threadRating.threadRating;
    let total;
    data.forEach((element) => {
      if (element.rating === id) {
        total = element.total_rating;
      }
    });
    if (typeof total === "undefined") {
      return 0;
    } else {
      return total;
    }
  };
  const getLastItem = (array) => {
    const data = array[array.length - 1];
    return data.name;
  };
  function getTwoDecimals(number) {
    if (number === null) {
      return 0;
    } else {
      const numbers = number.toString();
      const decimalIndex = numbers.indexOf(".");
      let result = numbers.slice(0, decimalIndex + 3);
      return result;
    }
  }
  const findMonthToString = (month) => {
    switch (month) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        break;
    }
  };
  const modifyData = () => {
    var getDaysArray = function (start, end) {
      for (
        var arr = [], dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    };
    var daylist = getDaysArray(
      new Date(periodDateStart),
      new Date(periodDateEnd)
    );
    daylist.map((v) => v.toISOString().slice(0, 10)).join("");
    return daylist;
  };
  const returnDateFormat = (dateData) => {
    const dateString = dateData;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = `${findMonthToString(month)} ${day}, ${year}`;

    return formattedDate;
  };
  const loopAll = () => {
    let data = [];
    modifyData().forEach((item) => {
      data.push({ name: returnDateFormat(item) });
    });
    return data;
  };
  const mapViewData = () => {
    const loopData = loopAll();
    const statisData = statistic.threadViews.data;
    const statisReviewData = statistic.threadViews.totalReview;
    loopData.map((item) => {
      let temp2 = statisData.filter((f) => {
        return f.name === item.name;
      });
      let temp3 = statisReviewData.filter((f) => {
        return f.name === item.name;
      });
      if (temp2.length !== 0) {
        item.views = temp2[0].views;
      } else {
        item.views = 0;
      }
      if (temp3.length !== 0) {
        item.review = temp3[0].review;
      } else {
        item.review = 0;
      }
      return item;
    });
    return loopData;
  };
  const mapDisbursementData = () => {
    const loopData = loopAll();
    const statisData = statistic.tokenDisbursement.data;
    loopData.map((item) => {
      let temp2 = statisData.filter((f) => {
        return f.name === item.name;
      });
      if (temp2.length !== 0) {
        return (item.anoa = temp2[0].anoa);
      } else {
        return (item.anoa = 0);
      }
    });
    return loopData;
  };
  const mapRatingData = () => {
    const loopData = loopAll();
    const statisData = statistic.threadRating.data;
    loopData.map((item) => {
      let temp2 = statisData.filter((f) => {
        return f.name === item.name;
      });
      if (temp2.length !== 0) {
        return (item.avg_rating = temp2[0].avg_rating);
      } else {
        return (item.avg_rating = "0");
      }
    });
    return loopData;
  };

  return (
    <div key={remounting} className={show === 'statistic' ? " h-[500px]" : "hidden"}>
      <span className="text-2xl font-semibold block">Overall Thread Performance</span>
      <span className="text-[#5D5F61] text-[16px]">Total performa selama periode thread</span>
      <div className="my-2">
        <div className="flex flex-row items-center gap-3">
          <div className="bg-white p-[16px] rounded-xl w-full">
            <p className="text-[#707375] text-[16px]">Total Thread Views</p>
            <h1 className="font-semibold text-[28px]">{overal.totalView} Views</h1>
          </div>

          <div className="bg-white p-[16px] rounded-xl w-full">
            <p className="text-[#707375] text-[16px]">Total Review</p>
            <h1 className="font-semibold text-[28px]">{overal.totalReview} Reviews</h1>
          </div>

          <div className="bg-white p-[16px] rounded-xl w-full">
            <p className="text-[#707375] text-[16px]">Conversion Rate</p>
            <h1 className="font-semibold text-[28px]">{overal.conversionRate}%</h1>
          </div>

          <div className="bg-white p-[16px] rounded-xl w-full">
            <p className="text-[#707375] text-[16px]">AVG Ratings</p>
            <h1 className="font-semibold text-[28px]">{overal.AVGRating}</h1>
          </div>

          <div className="bg-white p-[16px] rounded-xl w-full">
            <p className="text-[#707375] text-[16px]">Total Disbursement</p>
            <h1 className="font-semibold text-[28px]">{overal.totalDisbursment} ANOA</h1>
          </div>
        </div>
      </div>
      <span className="text-2xl font-semibold">Thread View</span>
      <div className="bg-[#FFFF] h-[600px] flex flex-col border-2 border-gray-300 rounded-2xl p-6 mb-6 shadow-xl">
        <div className="flex flex-row gap-4 mb-5 ">
          <div className="flex flex-col h-full p-6 border-[3px] border-[#8884d8] bg-[#F5F5FF] rounded-2xl w-fit">
            <span>Total Thread View</span>
            <span className="text-lg font-semibold text-black">
              {statistic.threadViews.totalView}
            </span>
            <span>
              Periode {mapViewData()[0].name} - {getLastItem(mapViewData())}
            </span>
          </div>
          <div className="flex flex-col h-full p-6 border-[3px] border-[#82ca9d] bg-[#ebfff3] rounded-2xl ">
            <span>Total Review</span>
            <span className="text-lg font-semibold text-black">
              {statistic.threadViews.totalAllReview }
            </span>
            <span>
              Periode {mapViewData()[0].name} - {getLastItem(mapViewData())}
            </span>
          </div>
          <div className="flex flex-col h-full p-6 border-[3px] border-gray-300 rounded-2xl ">
            <span>Total Conversion Rate</span>
            <span className="text-lg font-semibold text-black">
              {getTwoDecimals(statistic.threadViews.conversionRate)}%
            </span>
            <span>
              Periode {mapViewData()[0].name} - {getLastItem(mapViewData())}
            </span>
          </div>
        </div>
        <LineCharts data={mapViewData()}></LineCharts>
      </div>
      <span className="text-2xl font-semibold">Thread Rating</span>
      <div className="bg-[#FFFF] flex flex-row border-2 border-gray-300 rounded-2xl p-6 my-6 shadow-xl">
        <div className="flex flex-col w-1/5 gap-4 overflow-auto">
          <div className="flex flex-col p-6 border-[3px] border-gray-300 rounded-2xl h-fit">
            <span>Total AVG Rating </span>
            <span className="text-lg font-semibold text-black">
              {getTwoDecimals(statistic.threadRating.totalAVGRating)}
            </span>
            <span>
              Periode {mapViewData()[0].name} - {getLastItem(mapViewData())}
            </span>
          </div>
          <div className="flex flex-col p-6 border-[3px] border-gray-300 rounded-2xl h-fit">
            <span>Total Rating</span>

            <div className="flex flex-col mt-3 ">
              <div className="inline-flex items-center justify-between gap-6 flex-wrap">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="h-[2vh] w-[2vh] "
                    style={{ color: "#FDD500" }}
                  />
                  <span>1</span>
                </div>
                <span>{getRating(1)} Rating</span>
              </div>
            </div>
            <div className="flex flex-col mt-3">
                      <div className="inline-flex items-center justify-between gap-6 flex-wrap">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="h-[2vh] w-[2vh] "
                    style={{ color: "#FDD500" }}
                  />
                  <span>2</span>
                </div>
                <span>{getRating(2)} Rating</span>
              </div>
            </div>
            <div className="flex flex-col mt-3">
                      <div className="inline-flex items-center justify-between gap-6 flex-wrap">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="h-[2vh] w-[2vh] "
                    style={{ color: "#FDD500" }}
                  />
                  <span>3</span>
                </div>
                <span> {getRating(4)} Rating</span>
              </div>
            </div>
            <div className="flex flex-col mt-3">
                      <div className="inline-flex items-center justify-between gap-6 flex-wrap">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="h-[2vh] w-[2vh] "
                    style={{ color: "#FDD500" }}
                  />
                  <span>4</span>
                </div>
                <span>{getRating(4)} Rating</span>
              </div>
            </div>
            <div className="flex flex-col mt-3">
                      <div className="inline-flex items-center justify-between gap-6 flex-wrap">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="h-[2vh] w-[2vh] "
                    style={{ color: "#FDD500" }}
                  />
                  <span>5</span>
                </div>
                <span>{getRating(5)} Rating</span>
              </div>
            </div>
          </div>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            // data={data}
            data={mapRatingData()}
            margin={{
              top: 5,
              right: 20,
              left: 10,
              bottom: 5,
            }}
            barSize={70}
          >
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ left: 50, right: 20 }}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 0" />
            <Bar
              dataKey="avg_rating"
              fill="#212FB4"
              background={{ fill: "#eee" }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <span className="text-2xl font-semibold">Token Disbursement</span>
      <div className="bg-[#FFFF]  h-[600px] flex flex-col border-2 border-gray-300 rounded-2xl p-6 my-6 shadow-xl">
        <div className="flex flex-row gap-4 mb-5">
          <div className="flex flex-col h-full p-6 border-[3px] border-[#4C4DDC] bg-[#F5F5FF] rounded-2xl w-fit">
            <span>Total Token Disbursemen</span>
            <span className="text-lg font-semibold text-black">
              {statistic.tokenDisbursement.totalTokenDisbursment} ANOA
            </span>
            <span>
              Periode {mapViewData()[0].name} - {getLastItem(mapViewData())}
            </span>
          </div>
          {/* <div className='flex flex-col p-6 border-2 border-gray-300 rounded-2xl h-fit'>
                        <span>Total Review</span>
                        <span className='text-lg font-semibold text-black'>{statistic.tokenDisbursement.totalReview}</span>
                        <span>Periode  {(mapViewData())[0].name} - {getLastItem(mapViewData())}</span>
                    </div> */}
        </div>
        <LineCharts
          data={mapDisbursementData()}
          from={"disbursement"}
        ></LineCharts>
      </div>
    </div>
  );
};
