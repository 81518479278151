import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import { Transition } from "@headlessui/react";

import Navbar from "../../../components/layouts/Navbar";
import Sidebar from "../../../components/layouts/Sidebar";
import Signature from "../../../helpers/Signature";
import ErrorCode from "../../../helpers/errorcode.json";
import VerificationModal from "../home/VerificationModal";
import { easeOutTransition } from "../../../transitions/Transitions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DetailReview = () => {
    const [listThread, setlistThread] = useState([]);
    const [activeThread, setActiveThread] = useState("");
    const [totalThreads, setTotalThreads] = useState("");
    const [averageScoring, setAverageScoring] = useState("");
    const [userData, setUserData] = useState(null);
    const [KYCStatus, setKYCStatus] = useState("");
    //sort
    const [showTransition] = useState(true);
    const [isSortStartDate, setIsSortStartDate] = useState(false);
    const [isSortEndDate, setIsSortEndDate] = useState(false);
    const [isSortThreadTittle, setIsSortThreadTittle] = useState(false);
    const [isSortTotalReview, setIsSortTotalReview] = useState(false);
    const [isSortThreadIndex, setIsSortThreadIndex] = useState(false);

    //Button
    const [openVerificationModal, setOpenVerificationModal] = useState(false);
    const navigate = useNavigate();

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const {id} = useParams();

    const [dataReviewThreads, setDataReviewThreads] = useState({});
    const [dataReviewList, setDataReviewList] = useState([]);

    const detailThreadsHandle = (id) => {
        navigate("/developer/threads/detail/" + id);
    };
    // const loadthreads = useCallback(async () => {
    //     let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    //     let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    //     let resultUUID = JSON.parse(atob(decrypt));

    //     let payloadSignature = [];

    //     let signature = Signature(payloadSignature);

    //     let dataHeaders = {
    //         'platform': 'WEBSITE',
    //         'gtoken': 'PASSS',
    //         'userindex': resultUUID.user.userindex,
    //         'tokenlogin': resultUUID.user.usertokenlogin,
    //         'signature': signature
    //     }

    //     let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/listthread', {
    //         headers: dataHeaders
    //     });

    //     if (response.data.status.status === 1) {
    //         setlistThread(response.data.data.listThread);
    //         setActiveThread(response.data.data.activeThread);
    //         setTotalThreads(response.data.data.totalThread);
    //         const listOfThread = response.data.data.listThread;
    //         const lengthOfListThread = (response.data.data.listThread).length
    //         let totalAverage = 0
    //         let newValue = [];

    //         (listOfThread).forEach(element => {
    //             if (element.total_review >= 0) {
    //                 newValue.push(element.total_review)
    //             }
    //         })
    //         for (var i in newValue) {
    //             totalAverage += newValue[i];
    //         }
    //         setAverageScoring((totalAverage / lengthOfListThread).toFixed(2));
    //     }

    //     if (response.data.status.status === 0) {
    //         response.data.status.message.forEach(element => {
    //             // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
    //             // let replaceError = resultError.replace('###', element.codevariable);

    //             switch (element.code.substring(0, 9)) {
    //                 case ErrorCode.unauthenticated:
    //                     navigate('/', {
    //                         state: {
    //                             errorMessage: "ErrorCode unauthenticated"
    //                         }
    //                     });
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         })
    //     }
    // }, [navigate]);

    const sortEndingDate = () => {
        if (isSortEndDate) {
            const sortEndingDate = listThread.sort(
                (a, b) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortEndingDate]);
        } else {
            const sortEndingDate = listThread.sort(
                (b, a) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortEndingDate]);
        }
    };
    const sortPublishDate = () => {
        if (isSortStartDate) {
            const sortPublishDate = listThread.sort(
                (a, b) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortPublishDate]);
        } else {
            const sortPublishDate = listThread.sort(
                (b, a) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortPublishDate]);
        }
    };
    const sortTitle = () => {
        if (isSortThreadTittle) {
            let sortedNames = listThread.sort((b, a) => {
                if (a.tablethreadtitle < b.tablethreadtitle) {
                    return -1;
                }
                if (a.tablethreadtitle > b.tablethreadtitle) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        } else {
            let sortedNames = listThread.sort((a, b) => {
                if (a.tablethreadtitle < b.tablethreadtitle) {
                    return -1;
                }
                if (a.tablethreadtitle > b.tablethreadtitle) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        }
    };
    const sortTotalReview = () => {
        if (isSortTotalReview) {
            let sortedTotalReview = listThread.sort((b, a) => {
                if (a.total_review < b.total_review) {
                    return -1;
                }
                if (a.total_review > b.total_review) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedTotalReview]);
        } else {
            let sortedTotalReview = listThread.sort((a, b) => {
                if (a.total_review < b.total_review) {
                    return -1;
                }
                if (a.total_review > b.total_review) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedTotalReview]);
        }
    };

    const sortIndex = () => {
        if (isSortThreadIndex) {
            let sortedNames = listThread.sort((b, a) => {
                if (a.tablethreadindex < b.tablethreadindex) {
                    return -1;
                }
                if (a.tablethreadindex > b.tablethreadindex) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        } else {
            let sortedNames = listThread.sort((a, b) => {
                if (a.tablethreadindex < b.tablethreadindex) {
                    return -1;
                }
                if (a.tablethreadindex > b.tablethreadindex) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        }
    };

    const toggleSort = (param) => {
        switch (param) {
            case "index":
                setIsSortThreadIndex(!isSortThreadIndex);
                sortIndex();
                break;
            case "title":
                setIsSortThreadTittle(!isSortThreadTittle);
                sortTitle();
                break;
            case "publishDate":
                setIsSortStartDate(!isSortStartDate);
                sortPublishDate();
                break;
            case "endingDate":
                setIsSortEndDate(!isSortEndDate);
                sortEndingDate();
                break;
            case "totalReview":
                setIsSortTotalReview(!isSortTotalReview);
                sortTotalReview();
                break;
            default:
                break;
        }
    };
    const closeModal = () => {
        setOpenVerificationModal(!openVerificationModal);
        getUserProfile();
    };
    const fillKYCForm = () => {
        setOpenVerificationModal(true);
    };
    const getUserProfile = useCallback(async () => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
        console.log("getU", getUUID);
        let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
            CryptoJS.enc.Utf8
        );
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: resultUUID.user.userindex,
            tokenlogin: resultUUID.user.usertokenlogin,
            signature: signature,
        };

        let response = await axios.get(
            process.env.REACT_APP_HOSTNAMEAPI + "/api/user/v01/profile",
            {
                headers: dataHeaders,
            }
        );

        try {
            console.log("masuk try", response);

            if (response.data.status.status === 1) {
                console.log("res_profile", response);
                setUserData(response.data.data);
                setKYCStatus(response.data.data.userstatus);
                localStorage.setItem("profileData", JSON.stringify(response.data.data));
            }

            if (response.data.status.status === 0) {
                response.data.status.message.forEach((element) => {
                    // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                    // let replaceError = resultError.replace('###', element.codevariable);

                    switch (element.code.substring(0, 9)) {
                        case ErrorCode.unauthenticated:
                            navigate("/mainLogin", {
                                state: {
                                    errorMessage: "ErrorCode unauthenticated",
                                },
                            });
                            break;
                        default:
                            break;
                    }
                });
            }
        } catch (err) {
            console.log("err_ProfileAPI");
        }
    }, [navigate]);
    useEffect(() => {
        const loadthreads = async () => {
            let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
            let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
                CryptoJS.enc.Utf8
            );
            let resultUUID = JSON.parse(atob(decrypt));

            let payloadSignature = [];

            let signature = Signature(payloadSignature);

            let dataHeaders = {
                platform: "WEBSITE",
                gtoken: "PASSS",
                userindex: resultUUID.user.userindex,
                tokenlogin: resultUUID.user.usertokenlogin,
                signature: signature,
            };

            let response = await axios.get(
                process.env.REACT_APP_HOSTNAMEAPI + "/api/thread/v01/listthread",
                {
                    headers: dataHeaders,
                }
            );
            try {
                if (response.data.status.status === 1) {
                    setlistThread(response.data.data.listThread);
                    setActiveThread(response.data.data.activeThread);
                    setTotalThreads(response.data.data.totalThread);
                    const listOfThread = response.data.data.listThread;
                    const lengthOfListThread = response.data.data.listThread.length;
                    let totalAverage = 0;
                    let newValue = [];

                    listOfThread.forEach((element) => {
                        if (element.total_review >= 0) {
                            newValue.push(element.total_review);
                        }
                    });
                    for (var i in newValue) {
                        totalAverage += newValue[i];
                    }
                    setAverageScoring((totalAverage / lengthOfListThread).toFixed(2));
                }

                if (response.data.status.status === 0) {
                    response.data.status.message.forEach((element) => {
                        // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                        // let replaceError = resultError.replace('###', element.codevariable);

                        switch (element.code.substring(0, 9)) {
                            case ErrorCode.unauthenticated:
                                navigate("/mainLogin", {
                                    state: {
                                        errorMessage: "ErrorCode unauthenticated",
                                    },
                                });
                                break;
                            default:
                                break;
                        }
                    });
                }
            } catch (err) {
                console.log("err", err);
            }
        };
        getUserProfile();
        loadthreads();
    }, [navigate, getUserProfile]);

    // const data = ['Item 1', 'Item 2', 'Item 3'];

    const rating = [
        {
            id: 1,
            selected: false
        },
        {
            id: 2,
            selected: false
        },
        {
            id: 3,
            selected: false
        },
        {
            id: 4,
            selected: false
        },
        {
            id: 5,
            selected: false
        },
    ]

    const loadReviewList = useCallback(async () => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
        let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
            CryptoJS.enc.Utf8
        );
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: resultUUID.user.userindex,
            tokenlogin: resultUUID.user.usertokenlogin,
            signature: signature,
        };

        let body = {
            'threadindex' : id
        }

        let ISOStartDate = new Date(startDate);
        let ISOFormatStartDate = ISOStartDate.toISOString().split('T')[0];

        let ISOEndDate = new Date(endDate);
        let ISOFormatEndDate = ISOEndDate.toISOString().split('T')[0];

        if(startDate === null && endDate === null){
            let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/review/v01/developer/listreview', body, {
                headers: dataHeaders
            });
    
            if(response.data.status.status === 1){
                setDataReviewThreads(response.data.data);
                setDataReviewList(response.data.data.listdata);
                console.log(response.data.data.listdata);
            }
        }else{
            let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/review/v01/developer/listreview?datestart='+ISOFormatStartDate+'&dateend='+ISOFormatEndDate, body, {
                headers: dataHeaders
            });
    
            if(response.data.status.status === 1){
                setDataReviewThreads(response.data.data);
                setDataReviewList(response.data.data.listdata);
                console.log(response.data.data.listdata);
            }
        }
    }, [id, startDate, endDate]);

    useEffect(() => {
        loadReviewList();
    }, [loadReviewList]);

    const displayRating = (val) => {
        let totalRating = rating;   
        totalRating.forEach(element => {
            if (element.id < val || element.id === val) {
                element.selected = true;
            }else{
                element.selected = false;
            }
        });
        return (
            totalRating.map((item, index) => {
                return (
                    item.selected ?
                        <div className='mt-1' key={index}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4970_5314)">
                                    <path d="M9.14394 1.41785C9.53287 0.773676 10.4671 0.773676 10.8561 1.41785L13.0794 5.1002C13.2188 5.33115 13.4457 5.49596 13.7084 5.55721L17.8976 6.5338C18.6304 6.70464 18.9191 7.59317 18.4266 8.16213L15.6116 11.4145C15.435 11.6185 15.3484 11.8852 15.3713 12.154L15.737 16.4399C15.801 17.1897 15.0452 17.7388 14.3519 17.4463L10.3888 15.774C10.1402 15.6692 9.8598 15.6692 9.61124 15.774L5.64811 17.4463C4.95482 17.7388 4.19899 17.1897 4.26297 16.4399L4.6287 12.154C4.65164 11.8852 4.56499 11.6185 4.38843 11.4145L1.57335 8.16213C1.08089 7.59317 1.36959 6.70464 2.10243 6.5338L6.29159 5.55721C6.55433 5.49596 6.78118 5.33115 6.92062 5.1002L9.14394 1.41785Z" fill="#FAFF00" stroke="#0A0A0A" stroke-width="2"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_4970_5314">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        :
                        <div className='w-6 mt-1' key={index}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4970_5321)">
                                    <path d="M9.14394 1.41785C9.53287 0.773676 10.4671 0.773676 10.8561 1.41785L13.0794 5.1002C13.2188 5.33115 13.4457 5.49596 13.7084 5.55721L17.8976 6.5338C18.6304 6.70464 18.9191 7.59317 18.4266 8.16213L15.6116 11.4145C15.435 11.6185 15.3484 11.8852 15.3713 12.154L15.737 16.4399C15.801 17.1897 15.0452 17.7388 14.3519 17.4463L10.3888 15.774C10.1402 15.6692 9.8598 15.6692 9.61124 15.774L5.64811 17.4463C4.95482 17.7388 4.19899 17.1897 4.26297 16.4399L4.6287 12.154C4.65164 11.8852 4.56499 11.6185 4.38843 11.4145L1.57335 8.16213C1.08089 7.59317 1.36959 6.70464 2.10243 6.5338L6.29159 5.55721C6.55433 5.49596 6.78118 5.33115 6.92062 5.1002L9.14394 1.41785Z" stroke="#5D5F61" stroke-width="2"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_4970_5321">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                )
            })
        )
    }

    return (
        <>
            <div
                className="w-full min-h-full p-4 bg-no-repeat bg-cover"
                style={{ backgroundImage: "url('/images/bg-2.png')" }}
            >
                <div className="w-full h-full">
                    <Sidebar />
                    <Transition
                        show={openVerificationModal}
                        enter="transition-opacity duration-705"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <VerificationModal cancel={() => closeModal()}/>
                    </Transition>

                    <div className="w-full h-full pl-[256px]">
                        <Transition
                            className="w-[100%] h-[100%] "
                            appear={true}
                            show={showTransition}
                            {...easeOutTransition}
                        >
                            <div className="flex flex-col w-full h-full">
                                <div className="w-full h-full max-w-[1500px] pl-6 m-auto flex flex-col">
                                    {userData ? (
                                        <Navbar
                                            heading={`Review : `+dataReviewThreads.threadtitle}
                                            paragraf="Monitoring your content growth"
                                            profile={getUserProfile}
                                        />
                                    ) : null}
                                    
                                    <div className="flex flex-row items-center mt-5 mb-5 justify-between">
                                        <div className="relative">
                                            {/* <input type="text" className="py-[5px] w-[320px] rounded-[8px] pl-5 pr-10" placeholder="Search Threads"/>
                                            <div className="absolute right-3 top-2">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z" stroke="#5D5F61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div> */}
                                        </div>
                                        <div className="flex flex-row gap-3 items-center">
                                            <DatePicker
                                                showIcon
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update) => {
                                                    setDateRange(update);
                                                }}
                                                isClearable
                                                className="w-[240px] px-3"
                                                placeholderText="Filter By Date"
                                            />
                                            <button
                                                className="flex flex-row items-center bg-[#FF0000] py-[5px] px-[10px] text-white rounded-md"
                                                onClick={() => navigate("/developer/threads/new")}
                                            >
                                                New Threads
                                                <div className="pl-3">
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M10.0002 3.33325V16.6666M16.6668 9.99992L3.3335 9.99992"
                                                            stroke="white"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="w-full">
                                        <div className="">
                                            <div className="flex flex-row justify-between gap-3">
                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5 h-full">
                                                        <div className="flex-auto w-full h-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="h-full">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Total Review
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {dataReviewThreads.totalReview} Reviews
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5 h-full">
                                                        <div className="flex-auto w-full h-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="h-full">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Total AVG Rating
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {dataReviewThreads.avg_rating}
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5 h-full">
                                                        <div className="flex-auto w-full h-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="h-full">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Total Token Disbursement
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {dataReviewThreads.totalTokenDisbursment} Anoa
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full my-[24px] bg-white rounded-[12px] p-[24px]">
                                        <div className="border-b-2 pb-[16px]">
                                            <h2 className="text-[20px] font-semibold text-black">Total Review</h2>
                                            <span className="text-[#707375]">See gamers reviews from <span className="text-[#4C4DDC] font-semibold">Anoa Attack</span></span>
                                        </div>
                                        {dataReviewList.map((item, index) => (
                                        <div className="flex flex-row py-[48px] gap-10 border-b-2" style={{ borderBottom: index === dataReviewList.length - 1 ? 'none' : '' }}>
                                            <div>
                                                <h2 className="text-black font-semibold text-[28px]">{item.tokendisbursement} Anoa</h2>
                                                <span>{item.tablereviewtimestamp}</span>
                                            </div>
                                            <div className="flex flex-row gap-5">
                                                <img src="https://back.thespace.world/public/0eb7bcf2-b9da-4.jpeg" alt="Gambar" className="w-[48px] h-[48px] rounded-[48px]"/>
                                                <div>
                                                    <p className="text-black font-semibold">{item.tableuserfullname}</p>
                                                    <div className="flex flex-row gap-1 py-2">
                                                        {displayRating(item.tablereviewrating)}
                                                    </div>
                                                    <p className>{item.tablereviewdescription}</p>
                                                    <a href={item.tablereviewyoutubelink} className="pb-2 block text-blue-500" target="_blank">{item.tablereviewyoutubelink}</a>
                                                    <div className="grid grid-cols-4 gap-2">
                                                        {item.tablereviewpictpath.map((result, index) => (
                                                            <img src={`https://back.thespace.world/public/`+result} alt="Gambar" className="w-[210px] h-[205px] rounded-[6px]" key={index}/>
                                                        ))}
                                                        {item.tablereviewvideopath.map((result, index) => (
                                                            <video controls autoPlay className="object-cover w-[210px] h-[205px]" loop>
                                                                <source src={`https://back.thespace.world/public/`+result} type="video/mp4"/>
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailReview;
