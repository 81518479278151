import { Suspense, useRef, useState } from "react";
import { SuspenseImg } from "../../helpers/ImageCache";
import BarLoader from "../../components/loader/BarLoader";
import Signature from "../../helpers/Signature";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ErrorCode from "../../helpers/errorcode.json";
import Swal from 'sweetalert2'

const Username = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [errorPassword, setErrorPassword] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    const inputPassword = useRef();

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const newPasswordHandle = async (e) => {
        e.preventDefault();

        let payloadSignature = [
            'WEBSITE',
            'PASSS',
            inputPassword.current.value,
            'developer'
        ];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature
        }

        let body = {
            'useremailverificationcode': id,
            'usernamenew': inputPassword.current.value
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/newusername', body, {
            headers: dataHeaders
        });

        console.log(response.data);

        if (response.data.status.status === 1) {
            Swal.fire({
                title: "Success",
                text: "Your password updated successfuly, please wait 2 seconds, you will be redirected to login",
                icon: "success",
                showConfirmButton: false
            });

            setTimeout(() => {
                Swal.closeModal();
                navigate('/login/gamers')
            }, 2000);
        }

        if (response.data.status.status === 0) {
            response.data.status.message.forEach(element => {
                let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                let replaceError = resultError.replace('###', element.codevariable);
                switch (element.code.substring(0, 9)) {
                    case ErrorCode.password:
                        setErrorPassword(replaceError);
                        break;
                    default:
                        inputPassword.current.value = '';
                        break;
                }
            });
        }
    }

    return(
        <div className="w-full h-full overflow-hidden">
            <Suspense fallback={<BarLoader />}>
                <SuspenseImg alt="element" src={process.env.PUBLIC_URL + '/images/bg-1.png'} className="w-full h-full overflow-hidden bg-no-repeat bg-cover" />
                
                    <div className="absolute top-0 left-0 w-full h-screen px-6 m-auto overflow-hidden">
                        <div className="relative float-right w-full h-full max-w-6xl">
                            <div className="absolute translate-y-[-50%] top-[50%] w-full">
                                <img src={process.env.PUBLIC_URL + '/images/component-2.png'} alt="element" className="float-left animate-wiggle-slow" />
                                <img src={process.env.PUBLIC_URL + '/images/component-3.png'} alt="element" className="float-right mt-[-450px] animate-wiggle-slow " />
                            </div>
                        </div>

                        <div className="w-full h-full relative max-w-[550px] ">
                            <div className="absolute translate-y-[-50%] max-h-[100vh] overflow-auto top-[50%] w-full">
                                <h3 className="text-white text-[36px]">Username</h3>
                                <p className="text-[#989B9D]">Create your new username</p>

                                <div className="mt-10">
                                    <form onSubmit={(e) =>newPasswordHandle(e)}>
                                        <div className="flex flex-col gap-5 mb-3">
                                            <div className="flex-auto">
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">New Username</label>
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={togglePassword} >
                                                    </div>
                                                    <input type="text" ref={inputPassword} autoComplete="on" className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-3 p-2.5" placeholder="Password" />
                                                </div>
                                                {errorPassword !== '' ?
                                                <span className="flex flex-row items-center w-full p-1 text-white rounded bg-bg2">
                                                    <div className="pr-2">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.0003 7.5V9.16667M10.0003 12.5H10.0086M4.22677 15.8333H15.7738C17.0568 15.8333 17.8587 14.4444 17.2172 13.3333L11.4436 3.33333C10.8021 2.22222 9.1984 2.22222 8.5569 3.33333L2.78339 13.3333C2.14189 14.4444 2.94377 15.8333 4.22677 15.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>

                                                    {errorPassword}
                                                </span> : ''}
                                            </div>
                                        </div>
                                        <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px] m-auto block" type="submit">Change Username</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            
            </Suspense>
        </div>
    );
}

export default Username;