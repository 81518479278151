import MainHeader from "../../../main/MainHeader";
import { useEffect, useState, useCallback } from "react";
import CryptoJS from "crypto-js";
import axios from 'axios';
import Signature from "../../../../helpers/Signature";
import { useNavigate } from "react-router-dom";
import ErrorCode from "../../../../helpers/errorcode.json"
// import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import GamersUpdateProfile from "../modal/GamersUpdateProfile";
const Earning = () => {
    const [walletIsLinked, setWalletIsLinked] = useState(false);
    const [userData, setUserData] = useState('');
    const [walletData, setWalletData] = useState('');
    const [readyToRender, setReadyToRender] = useState(false);
    const [profileImgPreview, setProfileImgPreview] = useState(null);
    const [modal, setModal] = useState(false);
    const [type, setType] = useState('');
    const [emailNotFound, setEmailNotFound] = useState(false);
    const navigate = useNavigate();
    const [earningResult, setEarningResult] = useState([]);

    const [percentage , setPercentage] = useState(0);

    const earningList = useCallback( async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        try {
            let res = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/gamerearninglist?id='+currentTimestamp, {
                headers: dataHeaders
            });

            if(res.data.status.status === 1){
                setEarningResult(res.data.data.earningList);
            }
        } catch (error) {
            console.log('err', error);
        }
    }, []);

    useEffect(() =>{
        earningList();
    }, [earningList]);

    const _linkWalletHandler = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        let payload = {}
        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        try {
            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/linkwallet?id='+currentTimestamp, payload, {
                headers: dataHeaders
            });
            if (res.data.status.message[0].code === "008005035015") {
                setEmailNotFound(true)
            }
            if (res.data.status.message[0].code === "008005035010") {
                setWalletIsLinked(true);
                fetchWalletBallanceAPI()
            }
            console.log('response_linkwallet', res)
        } catch (err) {
            console.log('err', err)
        }

    }
    const fetchProfileAPI = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/profile?id='+currentTimestamp, {
            headers: dataHeaders
        });
        if (response.data.status.status === 1) {
            
            setUserData(response.data.data)
            setProfileImgPreview(response.data.data.userphotopict)
        }
        else {
            navigate('/logout');
        }

    }
    const fetchWalletBallanceAPI = async () => {

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        try {
            let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/walletbalance?id='+currentTimestamp, {
                headers: dataHeaders
            });
            if (response.data.status.status === 1) {

                setWalletIsLinked(true);
                setWalletData(response.data.data)
            }
            else {
                response.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        case ErrorCode.unauthenticated:
                            navigate('/login/developers');
                            break;
                        default:
                            break;
                    }
                })
            }
        } catch (err) {
            console.log(err)

        }
    }
    const getProfileData = async () => {
        const [profileAPI, walletballanceAPI] = await Promise.allSettled([
            fetchProfileAPI(),
            fetchWalletBallanceAPI()
        ])
        if (profileAPI.status === 'fulfilled' && walletballanceAPI.status === 'fulfilled') {
            setReadyToRender(true)
        }

    }
    const _handleUpdateProfileImg = async (e) => {

        const file = e.target.files[0];
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);

            reader.onloadend = () => {

            };
        }
        fetchUpdateProfileImgAPI(file);


    }
    const fetchUpdateProfileImgAPI = async (file) => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }
        let formData = new FormData();
        formData.append('file', file);
        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/uploadphoto', formData, {
            headers: dataHeaders
        });
        try {
            console.log('res_uploadphoto', response)
            if (response.data.status.status === 1) {
                fetchProfileAPI();
            }
        }
        catch (err) {

        }
    }
    const _handleUpdate = (id) => {
        console.log('id', id)
        setType(id);
        setModal(true);
    }
    useEffect(() => {
        getProfileData();
        setTimeout(() => {
            setPercentage(66)
        }, 1000);
         // eslint-disable-next-line
    }, [modal]);
    return (
        <div className=" m-0 p-0 overflow-hidden">
            <div style={{ backgroundImage: "url('/images/main/mainBackground.svg')" }} className="h-[100vh] w-[100vw] bg-cover bg-no-repeat flex flex-col">
                <GamersUpdateProfile modal={modal} type={type} cancel={() => setModal(false)} color={'developer'} />
                {
                    readyToRender ?
                        <>
                            <MainHeader isAuthenticated={readyToRender} userData={userData} />

                            <div className="flex flex-col px-12 py-6 justify-center items-center">
                                <div className="flex flex-col px-12 py-6">
                                    <div className="flex flex-row items-center text-[#FFFF] gap-3 py-3">
                                        <a href="/gamers/gamersProfile">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 17.0005L6 12.0005M6 12.0005L11 7.00049M6 12.0005L18 12.0005" stroke="#D7DCDF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </a>

                                        <label className="text-base">Back</label>
                                    </div>
                                    <div className="flex flex-col w-[80vw] gap-3">
                                        <h1 className="text-white text-[28px]">Your Earning</h1>
                                        <div className="w-full overflow-auto max-h-[500px]">
                                            <table className="w-full">
                                                <thead>
                                                    <tr className="bg-[#1E1F23]">
                                                        <th className="text-white text-left py-[12px] px-[12px] rounded-tl-[12px] rounded-bl-[12px]">ID</th>
                                                        <th className="text-white text-left py-[12px] px-[12px]">Transaction Date</th>
                                                        <th className="text-white text-left">Token Earn</th>
                                                        <th className="text-white text-left rounded-tr-[12px] rounded-br-[12px]">From</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {earningResult.map((result, key) => (
                                                        <tr className="border-y-2 border-[#3D3F40]" key={key}>
                                                            <td className="text-white text-left py-[12px] px-[12px]">{result.tabletransactionindex}</td>
                                                            <td className="text-white text-left py-[12px] px-[12px]">{result.timestamp}</td>
                                                            <td className="text-white text-left">{result.tokenearn} ANOA</td>
                                                            <td className="text-white text-left">{result.threadtitle}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                        : null
                }




            </div>
        </div>
    )
}
export default Earning;