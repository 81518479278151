import { useNavigate } from "react-router-dom";

const BlankPage = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/login/gamers')
    }
    return(
        <>
        <div className="bg-[#0A0B27] w-full h-full">
            <div className="flex flex-col items-center justify-center h-full">
                <img src={process.env.PUBLIC_URL+"/images/404.png"} alt="404"/>
                <p className="text-white font-bold text-[36px]">Looks like you’re lost in space</p>
                <span className="text-[#989B9D] text-[20px]">Page not found. Your request has been expired. Please make another request</span>
                <button className="bg-bm2 py-[12px] px-[50px] rounded-[12px] text-white mt-5" onClick={handleRedirect}>
                    Go Back
                </button>
            </div>
        </div>
        </>
    );
}

export default BlankPage;