import { useNavigate } from "react-router-dom";

import { Transition } from '@headlessui/react'
import { useState, useEffect } from "react";
import { easeInOutClasses } from "../../transitions/Transitions";

const Modal = (props) => {
    // console.log('props', props)
    const { color, content, cancel, from, modal, type } = props;
    const [showTranstion, setShowTransition] = useState(true);
    const navigate = useNavigate();

    const [count, setCount] = useState(5);

    useEffect(() => {
        let intervalId;

        if (modal && type !== 'error') {
            intervalId = setInterval(() => {
                setCount(count => count - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [modal]);

    useEffect(() => {
        if (count === 0) {
            navigateToGamersHome();
            console.log('Countdown selesai!');
        }
    }, [count]);

    const loginDevelopers = () => {
        navigate('/login/developers');
    }
    const loginGamers = () => {
        navigate('/login/gamers');
    }
    const navigateToGamersHome = () => {
        navigate('/');
    }
    const navigateToDevHome = () => {
        navigate('/developer/home');
    }
    const doCancel = () =>{
        // cancel;
        setShowTransition(false);
        setTimeout(() => {
            cancel();
            setShowTransition(true);
        }, 500);
    }
    return (
        <>
            {modal ? <>
                <div className="bg-bg1 backdrop-blur-[3px] fixed z-10 inset-0"></div>
                <div className="fixed inset-0 z-20">
                    <div className="relative w-full h-full ">
                        <div className="absolute translate-y-[-50%] top-[50%] w-full">
                            <Transition
                                appear={true}
                                show={showTranstion}
                                {...easeInOutClasses}
                            >
                                <div className="max-w-[725px] m-auto">
                                    <div className="mb-[-100px] w-full">
                                        {type === 'error' ?
                                            <img src={process.env.PUBLIC_URL + '/images/component-5.png'} alt="element" aria-hidden className="block ml-auto" />
                                            :
                                            <img src={process.env.PUBLIC_URL + '/images/component-4.png'} alt="element" aria-hidden className="block ml-auto" />
                                        }
                                    </div>
                                    <div className="bg-[#000032] flex flex-col gap-6 rounded-rd1 px-[40px] py-[60px]">
                                        <h3 className="text-white text-[48px]">{content.head}</h3>
                                        <p className="text-white text-[20px]">{content.pesan}</p>

                                        <p className="text-[#989B9D]">{content.description}</p>

                                        {
                                            color === 'developer' && type !== 'error' ?
                                                <button className="bg-bm1 w-full p-[10px] text-white rounded-[12px] m-auto block" onClick={from === 'loginDevelopers' ? navigateToDevHome : loginDevelopers}>Close</button>
                                                :

                                                color === 'gamer' && type !== 'error' ?
                                                    <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px] m-auto block" onClick={from === 'loginGamers' ? navigateToGamersHome : loginGamers}>Close</button>

                                                    : <button className="bg-bm1 w-full p-[10px] text-white rounded-[12px] m-auto block" onClick={()=>doCancel()} >Close</button>


                                        }
                                        <span className="text-white text-center block">Redirecting you in {count}</span>
                                    </div>
                                </div>

                            </Transition>

                        </div>
                    </div>
                </div>
            </> : ''}
        </>
    );
}

export default Modal;