import { useCallback, useEffect, useState} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Signature from "../../../../../helpers/Signature";
import CryptoJS from "crypto-js";
import axios from "axios";
import { getCLFormatTime, getTime } from '../../../../../helpers/TypeHelper';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const ThreadsExport = () => {
    const [historyTransaction, setHistoryTransaction] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();

    const location = useLocation();

    const getParams = useCallback((val) => {
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get(val);
        return paramValue;
    }, []);

    const loadHistoryTransaction = useCallback( async () => {
        setIsLoading(false);
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [''];
    
        let signature = Signature(payloadSignature);
    
        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        let transactionid = getParams('traxid') ? getParams('traxid') : '';
        let datestart = getParams('startdate') ? getParams('startdate') : '';
        let dateend = getParams('enddate') ? getParams('enddate') : '';
    
        const currentTimestamp = new Date().getTime();

        try {
            let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/historytransaction?tablethreadindex='+id+'&datestart='+datestart+'&dateend='+dateend+'&transactionid='+transactionid+'&id='+currentTimestamp, {
                headers: dataHeaders
            });
            
            if (response.data.status.status === 1) {
                setHistoryTransaction(response.data.data);
                setIsLoading(true);
            }
        } catch (err) {
            console.log('err', err)
        }
    }, [id]);

    useEffect(() => {
        loadHistoryTransaction();

        if(isLoading){
            window.print();
        }
    }, [loadHistoryTransaction, isLoading]);

    return (
        <>
            <div className='flex flex-col gap-2 p-5'>
                {
                    historyTransaction.map((item, idx) => {
                        return (
                            <div key={idx} className='flex flex-col justify-between w-full gap-3 px-3 py-3 border-2 border-gray-300 border-dashed rounded-md'>
                                <div className='flex justify-between w-full ' >
                                    <div className='flex flex-col'>
                                        <span className='font-semibold'>{item.transaction_type} #{item.transactionindex} </span>
                                        <span className=''>{getCLFormatTime(item.timestamp)} | {getTime(item.timestamp)}</span>
                                    </div>
                                    <div className='flex flex-col items-end'>
                                        <span className={item.status === "Token Added" ? 'text-[#43936C] font-semibold' : 'text-[#CB3A31] font-semibold'}>{item.status === "Token Added" ? '+' + item.amount : '-' + item.amount}</span>
                                        <span>Current Balance : {item.currentbalance}</span>
                                    </div>
                                </div>
                            </div>)
                    })
                }
            </div>
        </>
    )
}