import ReactPlayer from 'react-player'
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getCLFormatTime, getTime } from '../../../../../helpers/TypeHelper';
import ProjectionModal from "../../threadsAdd/view/ProjectionModal";
import { fetchAddtoken } from '../controller/ThreadsDetailController';
import ModalOne from '../../../../../components/modal/ModalOne';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../../../components/loader/Spinner';
export const ThreadInfo = (props) => {

    const { show, detailThreads, history, id, media } = props;
    // const [carousel, setCarousel] = useState(null);
    const [openProjection, setOpenProjection] = useState(false);
    const [remounting, setRemounting] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [modals, setModal] = useState(false);
    const [typeModal, setTypeModal] = useState('success');
    const [contentModal, setContentModal] = useState({});
    const navigate = useNavigate();

    console.log(detailThreads);

    const _openModal = () => {
        setRemounting(!remounting);
        setOpenProjection(true)
    }
    function isImage(url) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }

    const closeModal = () => {
        setOpenProjection(false);
    }

    const _closeModalOne = () => {
        console.log('props.doRemounting', props)
        props.doRemounting();
        setModal(false);
    }
    const _addMoreToken = async (totalReview, tokenAllocation) => {
        const res_addToken = await fetchAddtoken(id, totalReview, tokenAllocation);
        console.log('res_addToken', res_addToken)
        if (res_addToken.status !== 1) {
            switch (res_addToken.message[0].code) {
                case '008999999964':
                    navigate('/logout')
                    break;

                default:
                    break;
            }
            setContentModal(
                {
                    'head': 'Failed',
                    'pesan': res_addToken.message[0].code + ' ' + res_addToken.message[0].errormessage,
                    'description': 'Add more token failed'
                });
            setShowSpinner(false);
            setTypeModal('error');
            setModal(true);
        }
        else {
            setContentModal(
                {
                    'head': 'Success!',
                    'pesan': 'The thread will be published in 1 hour. As long as the thread status is still "Posting", you can submit a cancellation request by contacting our customer service via email info@atn.co.id, or whatsapp to +628-517-236-6789 . Gas fee will not be charged. If the thread status is already active, you can still do an emergency cancellation by contacting the admin.',
                    'description': 'Success add more token'
                });
            setOpenProjection(false)
            setShowSpinner(false);
            setTypeModal('success');
            setModal(true);
        }

    }

    const estimed = (data) => {
        if(data.tablethreaddisbursementfee === 'Gamers'){
            let review = Number(data.tablethreadreviewreward) - 0.1;
            let resultReview = review.toFixed(2);

            let reviewupload = Number(data.tablethreaduploadmediareward) - 0.1;
            let resultReviewUpload = reviewupload.toFixed(2);
            
            return(
                <>
                    <span className='mb-2 text-lg font-semibold text-black'> Estimated Developer Expense : </span>
                    <div className='inline-flex justify-between'>
                        <span> Write Review</span>
                        <span> {detailThreads.tablethreadreviewreward} ANOA</span>
                    </div>
                    <div className='inline-flex justify-between'>
                        <span> Write Review + Media Upload</span>
                        <span> {detailThreads.tablethreaduploadmediareward} ANOA</span>
                    </div>
                    <span className='mb-2 text-lg font-semibold text-black'> Estimated Gamers Earning : </span>
                    <div className='inline-flex justify-between'>
                        <span> Write Review</span>
                        <span> {resultReview} ANOA</span>
                    </div>
                    <div className='inline-flex justify-between'>
                        <span> Write Review + Media Upload</span>
                        <span> {resultReviewUpload} ANOA</span>
                    </div>
                </>
            )
        }

        if(data.tablethreaddisbursementfee === 'Developer'){
            let reviewdev = Number(data.tablethreadreviewreward) + 0.1;
            let resultReviewDev = reviewdev.toFixed(2);

            let reviewdevupload = Number(data.tablethreaduploadmediareward) + 0.1;
            let resultReviewUpload = reviewdevupload.toFixed(2);
            return(
                <>
                    <span className='mb-2 text-lg font-semibold text-black'> Estimated Developer Expense : </span>
                    <div className='inline-flex justify-between'>
                        <span> Write Review</span>
                        <span> {resultReviewDev} ANOA</span>
                    </div>
                    <div className='inline-flex justify-between'>
                        <span> Write Review + Media Upload</span>
                        <span> {resultReviewUpload} ANOA</span>
                    </div>
                    <span className='mb-2 text-lg font-semibold text-black'> Estimated Gamers Earning : </span>
                    <div className='inline-flex justify-between'>
                        <span> Write Review</span>
                        <span> {detailThreads.tablethreadreviewreward} ANOA</span>
                    </div>
                    <div className='inline-flex justify-between'>
                        <span> Write Review + Media Upload</span>
                        <span> {detailThreads.tablethreaduploadmediareward} ANOA</span>
                    </div>
                </>
            )
        }
    }

    console.log(detailThreads)
    return (
        <>
            <ModalOne modal={modals} type={typeModal} content={contentModal} from={"addmoretoken"} cancel={_closeModalOne} color={'ModalOne'} />
            <ProjectionModal modal={openProjection} key={remounting} closeProjection={closeModal} _addMoreToken={_addMoreToken} from={"threadinfo"} detailThreads={detailThreads}></ProjectionModal>
            <div className={show === 'info' ? 'flex flex-row w-full gap-3' : 'hidden'}>
                <div className=" mt-[42px] w-full bg-white rounded-3xl p-[24px]">
                    {
                        showSpinner ?
                            <Spinner></Spinner> : null
                    }
                    <div className="flex flex-col gap-[72px]">
                        <div className="flex-initial">
                            <div className="flex flex-row">
                                <div className="flex-auto">
                                    <p className="text-[#0A0A0A] font-semibold text-lg">Threads Tittle</p>
                                    <h2 className="text-[#707375] pt-[8px]">{detailThreads.tablethreadtitle}</h2>
                                </div>
                                <div>
                                    {detailThreads.tablethreadstatus == 'PENDING' ?
                                        <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{detailThreads.tablethreadstatus}</span>
                                    : '' }

                                    {detailThreads.tablethreadstatus === 'CANCELED' ?
                                        <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{detailThreads.tablethreadstatus}</span>
                                    : '' }

                                    {detailThreads.tablethreadstatus === 'ACTIVE' ?
                                        <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{detailThreads.tablethreadstatus}</span>
                                    : '' }

                                    {detailThreads.tablethreadstatus === 'ENDED' ?
                                        <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{detailThreads.tablethreadstatus}</span>
                                    : '' }
                                </div>
                            </div>

                            <div className="pt-[24px]">
                                <p className="text-[#0A0A0A] font-semibold text-lg">Threads ID</p>
                                <p className="text-[#707375] pt-[8px]">{detailThreads.tablethreadindex}</p>
                            </div>

                            <div className="pt-[24px]">
                                <p className="text-[#0A0A0A] font-semibold text-lg">Threads Description</p>
                                <p className="text-[#707375] pt-[8px]">{detailThreads.tablethreaddescription}</p>
                            </div>
                            <div className="pt-[24px]">
                                <p className="text-[#0A0A0A] font-semibold text-lg">Word Count</p>
                                <div className="flex flex-row flex-wrap gap-3">
                                {detailThreads.tablethreadreviewwordcount}
                                </div>
                            </div>
                            <div className="pt-[24px]">
                                <p className="text-[#0A0A0A] font-semibold text-lg">Genre</p>
                                <div className="flex flex-row flex-wrap gap-3">
                                    {
                                        detailThreads.tablethreadgenre.map((key, index) => {
                                            return (
                                                <div className="mt-3 w-fit">
                                                    <span className="block bg-[#CD7B2E] py-[6px] px-[28px] rounded-3xl text-white">{key.genrename}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="flex flex-row pt-[24px]">
                                <div className="flex-initial w-[50%]">
                                    <p className="text-[#0A0A0A] font-semibold text-lg">Date Start</p>
                                    <div className="flex flex-row items-center gap-2 pt-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3333 5.83333V2.5M6.66667 5.83333V2.5M14.1667 9.16667H5.83333M15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V5.83333C2.5 4.91286 3.24619 4.16667 4.16667 4.16667H15.8333C16.7538 4.16667 17.5 4.91286 17.5 5.83333V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className="text-[#0A0A0A] font-semibold">{getCLFormatTime(detailThreads.tablethreaddatestart)}</p>
                                    </div>
                                </div>
                                <div className="flex-initial w-[50%]">
                                    <p className="text-[#0A0A0A] font-semibold text-lg">Date End</p>
                                    <div className="flex flex-row items-center gap-2 pt-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3333 5.83333V2.5M6.66667 5.83333V2.5M14.1667 9.16667H5.83333M15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V5.83333C2.5 4.91286 3.24619 4.16667 4.16667 4.16667H15.8333C16.7538 4.16667 17.5 4.91286 17.5 5.83333V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className="text-[#0A0A0A] font-semibold">{getCLFormatTime(detailThreads.tablethreaddateend)}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-[24px]">
                                <p className="text-[#0A0A0A] font-semibold text-lg">Demo Link</p>
                                <a href={detailThreads.tablethreaddemolink} className="text-[#0A0A0A] font-semibold pt-[8px]">{detailThreads.tablethreaddemolink ? detailThreads.tablethreaddemolink : "Demo Link is not available"}</a>
                            </div>

                            <div className="pt-[24px]">
                                <p className="text-[#0A0A0A] font-semibold text-lg">Thread Youtube</p>
                                <a href={detailThreads.tablethreadyoutubelink} target="_blank" className="text-[#0A0A0A] font-semibold pt-[8px]">{detailThreads.tablethreadyoutubelink ? detailThreads.tablethreadyoutubelink : "Youtube Link is not available"}</a>
                            </div>
                        </div>
                        
                        {media.length > 0 ?
                        <div className='flex justify-center w-full bg-black sm:h-[30vh]'>
                            <Carousel
                                // autoPlay
                                
                                showThumbs={false}
                                interval="3000" transitionTime="500" infiniteLoop
                                statusFormatter={(current, total) => ``}>
                                {
                                    media.map((item, index) => {
                                        return (
                                            <div className='w-full sm:h-[30vh] py-6'>
                                                {
                                                    isImage(item.tablemediafilenamefullpath) ?
                                                        <div className='w-full h-full bg-black'>
                                                            <img src={item.tablemediafilenamefullpath} key={item.result} alt="gambar-threads" className="w-[100%] h-[100%] object-contain" />
                                                        </div>
                                                        :

                                                        <div className='w-full h-full px-4 '>
                                                            <ReactPlayer  light={item.tablemediathumbnailfullpath} key={item.tablemediafilenamefullpath} url={item.tablemediafilenamefullpath} playing={false} loop={true} controls={true} width="100%" height="100%" volume={0}  />
                                                        </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                        </div> : null }
                    </div>
                </div>
                {/* <div className=" mt-[42px] w-1/2 bg-white rounded-3xl p-[24px]">
                    <div className='flex flex-col gap-2 text-[#5D5F61]'>
                        <span className='mb-2 text-lg font-semibold text-black'> Token Alocation Scheme </span>
                        <div className='inline-flex justify-between'>
                            <span> Estimated Targeted Review</span>
                            <span> {detailThreads.tablethreadreviewtarget ? detailThreads.tablethreadreviewtarget : 0} Review</span>
                        </div>
                        <div className='inline-flex justify-between'>
                            <span>Disbursement Fee By</span>
                            <span> {detailThreads.tablethreaddisbursementfee}</span>
                        </div>
                        <div className='inline-flex justify-between'>
                            <span>Disbursement Fee</span>
                            <span> 0.1 ANOA</span>
                        </div>
                        {estimed(detailThreads)}
                    </div>
                    <div className='flex flex-col mt-5 gap-2 text-[#5D5F61]'>
                        <span className='mb-2 text-lg font-semibold text-black'>Token Allocation</span>
                        <div className='flex items-center justify-center w-full px-3 py-[3vh] border-2 border-gray-300 border-dashed rounded-md'>
                            <div className='flex flex-col items-center justify-center gap-1 py-3'>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/anoaCoin.svg'} alt="element" className="w-[100%] h-[100%] object-contain " />
                                </div>
                                <span className='font-bold'> {detailThreads.currentbalance} ANOA</span>
                                {detailThreads.lasttransactionamount !== undefined || detailThreads.lasttransactiondate !== undefined ?
                                <span>{detailThreads.lasttransactionamount+' From '+detailThreads.lasttransactiondate}</span>
                                : '' }
                                <button onClick={() => detailThreads.tablethreadstatus === 'ACTIVE' ? _openModal() : null} className={detailThreads.tablethreadstatus === 'ACTIVE' ? 'bg-[#CB3A31] text-[#FFFF] text-sm px-3 py-3 rounded-md' : 'bg-gray-300 text-[#FFFF] text-sm px-3 py-3 rounded-md'}>Add More Token</button>
                            </div>

                        </div>
                    </div>
                    <div className='flex flex-col mt-5 gap-2 text-[#5D5F61]'>
                        <span className='mb-2 text-lg font-semibold text-black'>History</span>

                        {
                            history.map((item, idx) => {
                                return (
                                    <div key={idx} className='flex flex-col justify-between w-full gap-3 px-3 py-3 border-2 border-gray-300 border-dashed rounded-md'>
                                        <div className='flex justify-between w-full ' >
                                            <div className='flex flex-col'>
                                                <span className='font-semibold'>{item.transaction_type} #{item.transactionindex} </span>
                                                <span className=''>{getCLFormatTime(item.timestamp)} | {getTime(item.timestamp)}</span>
                                            </div>
                                            <div className='flex flex-col items-end'>
                                                <span className={item.status === "Token Added" ? 'text-[#43936C] font-semibold' : 'text-[#CB3A31] font-semibold'}>{item.status === "Token Added" ? '+' + item.amount : '-' + item.amount}</span>
                                                <span>Current Balance : {item.currentbalance}</span>
                                            </div>
                                        </div>
                                    </div>)
                            })
                        }


                    </div>
                </div> */}
            </div>

        </>
    )
}