import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import Navbar from "../../../components/layouts/Navbar";
import Sidebar from "../../../components/layouts/Sidebar";
import Signature from "../../../helpers/Signature";
import ErrorCode from "../../../helpers/errorcode.json";
import { Transition } from "@headlessui/react";
import { easeOutTransition } from "../../../transitions/Transitions";
import Swal from "sweetalert2";
const Threads = () => {

    const [ascending, setAscending] = useState(false);
    const [descending, setDescending] = useState(false);
    const [listThread, setlistThread] = useState([]);
    const [dropdownThreads, setDropdownThreads] = useState(false);
    const [sortTitle, setSortTitle] = useState(false);
    const [sortReview, setSortReview] = useState(false);
    const [sortStartDate, setSortStartDate] = useState(false);
    const [sortEndDate, setSortEndDate] = useState(false);
    const [sortType, setSorType] = useState('');
    const [sortName, setSortName] = useState('');
    const [showTransition] = useState(true);
    const [KYCStatus, setKYCStatus] = useState("");
    const handleCheckSort = (e) => {

        if (e === 'sortTitle') {
            if (sortTitle === false) {
                setSortName('tablethreadtitle');
                setSortTitle(true);
                setSortStartDate(false);
                setSortEndDate(false);
                setSortReview(false);
            }
            else {
                setSortName('');
            }
            setSortTitle(!sortTitle)
        }
        if (e === 'sortStartDate') {
            if (sortStartDate === false) {
                setSortName('tablethreaddatestart');
                setSortStartDate(true);
                setSortTitle(false);
                setSortEndDate(false);
                setSortReview(false);
            }
            else {
                setSortName('');
            }
            setSortStartDate(!sortStartDate)
        }
        if (e === 'sortEndDate') {
            if (sortEndDate === false) {
                setSortName('tablethreaddateend');
                setSortEndDate(true);
                setSortTitle(false);
                setSortStartDate(false);
                setSortReview(false);
            }
            else {
                setSortName('');
            }
            setSortEndDate(!sortEndDate);
        }
        if (e === 'sortReview') {
            if (sortReview === false) {
                setSortName('total_review');
                setSortReview(true);
                setSortTitle(false);
                setSortStartDate(false);
                setSortEndDate(false);
            }
            else {
                setSortName('');
            }
            setSortReview(!sortReview);
        }
    };

    const handleCheckAsc = (e) => {
        if (e === 'ascending') {
            if (ascending === false) {
                setSorType('')
                setAscending(true);
                setDescending(false);
            }
            else {
                setSorType('')
            }
            setAscending(!ascending);
        }
        if (e === 'descending') {
            if (descending === false) {
                setSorType('-')
                setDescending(true);
                setAscending(false);
            }
            else {
                setSorType('')
            }
            setDescending(!descending)
        }
    }

    const dropdownSortHandle = () => {
        if (dropdownThreads === false) {
            setDropdownThreads(true);
        } else {
            setDropdownThreads(false);
        }
    }
    const navigate = useNavigate();

    const loadthreads = async (status) => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/listthread?status='+status+'&id='+currentTimestamp, {
            headers: dataHeaders
        });
        console.log('res_listthread', response)

        if (response.data.status.status === 1) {
            setlistThread(response.data.data.listThread);
        }

        if (response.data.status.status === 0) {
            response.data.status.message.forEach(element => {
                // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                // let replaceError = resultError.replace('###', element.codevariable);

                switch (element.code.substring(0, 9)) {
                    case (ErrorCode.unauthenticated):
                        navigate('/login/developers');
                        break;
                    default:
                        break;
                }
            })
        }
    }
    const loadSortedThreads = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        var type = sortType === '' ? '' : sortType;
        var name = sortName === '' ? 'tablethreadtitle' : sortName;
        var body = type + name;

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }
        let URL = '/api/thread/v01/listthread?sort=' + body

        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + URL, {
            headers: dataHeaders
        });

        if (response.data.status.status === 1) {
            setlistThread(response.data.data.listThread);
            dropdownSortHandle();
        }

        if (response.data.status.status === 0) {
            response.data.status.message.forEach(element => {
                // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                // let replaceError = resultError.replace('###', element.codevariable);

                switch (element.code.substring(0, 9)) {
                    case ErrorCode.unauthenticated:
                        navigate('/login/developers');
                        break;
                    default:
                        break;
                }
            })
        }
    }
    // const loadSearchThreads = async () => {
    //     let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    //     let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    //     let resultUUID = JSON.parse(atob(decrypt));

    //     var body = searchByName.current.value;

    //     let payloadSignature = [];

    //     let signature = Signature(payloadSignature);

    //     let dataHeaders = {
    //         'platform': 'WEBSITE',
    //         'gtoken': 'PASSS',
    //         'userindex': resultUUID.user.userindex,
    //         'tokenlogin': resultUUID.user.usertokenlogin,
    //         'signature': signature
    //     }
    //     let URL = '/api/thread/v01/listthread?title=' + body

    //     let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + URL, {
    //         headers: dataHeaders
    //     });

    //     if (response.data.status.status === 1) {
    //         setlistThread(response.data.data.listThread);
    //     }

    //     if (response.data.status.status === 0) {
    //         response.data.status.message.forEach(element => {
    //             // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
    //             // let replaceError = resultError.replace('###', element.codevariable);

    //             switch (element.code.substring(0, 9)) {
    //                 case ErrorCode.unauthenticated:
    //                     navigate('/login/developers');
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         })
    //     }
    // }

    useEffect(() => {
        loadthreads('');
        // eslint-disable-next-line
    }, []);

    const detailThreadsHandle = (id) => {
        navigate('/developer/threads/' + id);
    }

    const getUserProfile = useCallback(async () => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
        console.log("getU", getUUID);
        let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
            CryptoJS.enc.Utf8
        );
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: resultUUID.user.userindex,
            tokenlogin: resultUUID.user.usertokenlogin,
            signature: signature
        };

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(
            process.env.REACT_APP_HOSTNAMEAPI + "/api/user/v01/profile?id="+currentTimestamp,
            {
                headers: dataHeaders,
            }
        );

        try {
            console.log("masuk try", response);

            if (response.data.status.status === 1) {
                setKYCStatus(response.data.data.userstatus);
                localStorage.setItem("profileData", JSON.stringify(response.data.data));
            }
        } catch (err) {
            console.log("err_ProfileAPI");
        }
    }, [navigate]);

    useEffect(() => {
        getUserProfile();
    }, [getUserProfile])

    const filterStatusHandle = (e) => {
        loadthreads(e.target.value);
    }
    return (
        <>
            <div className="w-full min-h-full bg-no-repeat bg-cover" style={{ backgroundImage: "url('/images/bg-2.png')" }}>
                <div className="w-full h-full">
                    <Sidebar />

                    <div className="w-full h-full pl-[256px]">
                        <Transition
                            className='flex flex-row flex-wrap w-[100%] h-[100%] gap-[1%] justify-between  '
                            appear={true}
                            show={showTransition}
                            {...easeOutTransition}
                        >
                            <div className="flex flex-col w-full h-full pl-3">
                                <div className="w-full h-full max-w-[1500px] m-auto flex flex-col">
                                    <Navbar heading="Threads" paragraf="Manage your thread(s)"/>

                                    <div className="w-full">
                                        <div className="mt-[41px]">
                                            <div className="flex flex-row items-center">
                                                <div className="flex-auto">
                                                    {/* <div className="relative w-full max-w-[320px]">
                                                        <input type="search" id="search-dropdown" className="block p-2.5 w-full z-20 rounded-lg text-sm bg-gray-50 rounded-r-lg focus:outline-none" placeholder="Search" ref={searchByName} required />
                                                        <button type="submit" className="absolute top-0 right-0 p-2.5 text-sm font-medium focus:outline-none" onClick={() => loadSearchThreads()}>
                                                            <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                                        </button>
                                                    </div> */}
                                                </div>
                                                <div>
                                                    <div className="flex flex-row gap-3">
                                                        <select className="outline-none px-4 cursor-pointer rounded-md" onChange={filterStatusHandle}>
                                                            <option value="">All</option>
                                                            <option value="PENDING">PENDING</option>
                                                            <option value="ACTIVE">ACTIVE</option>
                                                            <option value="ENDED">ENDED</option>
                                                            <option value="CANCELED">CANCELED</option>
                                                        </select>
                                                        <button className="flex flex-row items-center text-black bg-white rounded-md p-pd1" onClick={dropdownSortHandle}>
                                                            <div className="pr-3">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.83333 9.99992H14.1667M3.75 5.83325H16.25M7.91667 14.1666H12.0833" stroke="#5D5F61" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                            Sort
                                                        </button>
                                                        <div className={dropdownThreads ? 'z-10 block absolute bg-white divide-y divide-gray-100 rounded shadow w-44  pb-3 mt-12' : 'z-10 hidden absolute bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700'}>
                                                            <ul className="py-1 pb-3 text-sm text-black border-b-2">
                                                                <li>
                                                                    <div className="flex flex-row items-center pt-3 pl-3">
                                                                        <input
                                                                            type="checkBox"
                                                                            checked={sortTitle}
                                                                            value={sortTitle}
                                                                            onChange={() => handleCheckSort('sortTitle')}
                                                                            className="w-5 h-5 cursor-pointer" />
                                                                        <label className="text-[14px] pl-3 ">Threads Title</label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="flex flex-row items-center pt-3 pl-3">
                                                                        <input
                                                                            type="checkBox"
                                                                            checked={sortStartDate}
                                                                            value={sortStartDate}
                                                                            onChange={() => handleCheckSort('sortStartDate')}
                                                                            className="w-5 h-5 cursor-pointer" />
                                                                        <label className="text-[14px] pl-3 ">Publish Date</label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="flex flex-row items-center pt-3 pl-3">
                                                                        <input
                                                                            type="checkBox"
                                                                            checked={sortEndDate}
                                                                            value={sortEndDate}
                                                                            onChange={() => handleCheckSort('sortEndDate')}
                                                                            className="w-5 h-5 cursor-pointer" />
                                                                        <label className="text-[14px] pl-3 ">Ending Date</label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="flex flex-row items-center pt-3 pl-3">
                                                                        <input
                                                                            type="checkBox"
                                                                            checked={sortReview}
                                                                            value={sortReview}
                                                                            onChange={() => handleCheckSort('sortReview')}
                                                                            className="w-5 h-5 cursor-pointer" />
                                                                        <label className="text-[14px] pl-3 ">Review</label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <ul className="py-1 text-sm text-black border-b-2">
                                                                <li>
                                                                    <div className="flex flex-row items-center pt-3 pl-3">
                                                                        <input
                                                                            type="checkBox"
                                                                            checked={ascending}
                                                                            value={ascending}
                                                                            onChange={() => handleCheckAsc('ascending')}
                                                                            className="w-5 h-5 cursor-pointer" />
                                                                        <label className="text-[14px] pl-3 ">Ascending</label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="flex flex-row items-center pt-3 pl-3">
                                                                        <input
                                                                            type="checkBox"
                                                                            checked={descending}
                                                                            value={descending}
                                                                            onChange={() => handleCheckAsc('descending')}
                                                                            className="w-5 h-5 cursor-pointer" />
                                                                        <label className="text-[14px] pl-3 ">Descending</label>
                                                                    </div>
                                                                </li>
                                                                <li className="pt-4 pl-3 pr-3">
                                                                    <button className=" flex-row items-center w-[100%]  bg-[#FF0000] p-pd1 text-white rounded-md" onClick={() => loadSortedThreads()}>Sort</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <button className="flex flex-row items-center bg-[#FF0000] p-pd1 text-white rounded-md" onClick={() => {
                                                            KYCStatus === "INACTIVE" || KYCStatus === "REJECT" || KYCStatus === "NOT ACTIVE" ? 
                                                            Swal.fire({
                                                                icon: "error",
                                                                title: "Unable to Create Thread",
                                                                text: "Please activate your account to enjoy all the features.",
                                                            }) :
                                                            navigate("/developer/threads/new")
                                                        }}>
                                                            New Thread
                                                            <div className="pl-3">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.0002 3.33325V16.6666M16.6668 9.99992L3.3335 9.99992" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <div className="relative overflow-x-auto">
                                                    <table className="w-full text-sm text-left">
                                                        <thead className="text-xs text-[14px]">
                                                            <tr className="text-[#5D5F61] border-b-2 border-[#D7DCDF]">
                                                                <th scope="col" className="py-3">
                                                                    Thread Index
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    Thread Title
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    Publish Date
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    Ending Date
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    Total Reviews
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    Status
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listThread.map((result, key) => (
                                                                <tr className="border-b-2 border-[#D7DCDF] hover:bg-slate-400 cursor-pointer" key={key} onClick={() => detailThreadsHandle(result.tablethreadindex)}>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreadindex}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreadtitle}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreaddatestart}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreaddateend}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.total_review} <label className="text-[#707375]">Reviews</label>
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreadstatus == 'PENDING' ?
                                                                            <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{result.tablethreadstatus}</span>
                                                                        : '' }

                                                                        {result.tablethreadstatus === 'CANCELED' ?
                                                                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{result.tablethreadstatus}</span>
                                                                        : '' }

                                                                        {result.tablethreadstatus === 'ACTIVE' ?
                                                                            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{result.tablethreadstatus}</span>
                                                                        : '' }

                                                                        {result.tablethreadstatus === 'ENDED' ?
                                                                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{result.tablethreadstatus}</span>
                                                                        : '' }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Threads;