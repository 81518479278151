import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import { Transition } from "@headlessui/react";

import Navbar from "../../../components/layouts/Navbar";
import Sidebar from "../../../components/layouts/Sidebar";
import Signature from "../../../helpers/Signature";
import ErrorCode from "../../../helpers/errorcode.json";
import VerificationModal from "./VerificationModal";
import { easeOutTransition } from "../../../transitions/Transitions";
import Swal from "sweetalert2";

const Home = () => {
    const [listThread, setlistThread] = useState([]);
    const [activeThread, setActiveThread] = useState("");
    const [totalThreads, setTotalThreads] = useState("");
    const [averageScoring, setAverageScoring] = useState("");
    const [totalReview, setTotalReview] = useState('');
    const [userData, setUserData] = useState(null);
    const [KYCStatus, setKYCStatus] = useState("");
    //sort
    const [showTransition] = useState(true);
    const [isSortStartDate, setIsSortStartDate] = useState(false);
    const [isSortEndDate, setIsSortEndDate] = useState(false);
    const [isSortThreadTittle, setIsSortThreadTittle] = useState(false);
    const [isSortTotalReview, setIsSortTotalReview] = useState(false);
    const [isSortThreadIndex, setIsSortThreadIndex] = useState(false);

    //Button
    const [openVerificationModal, setOpenVerificationModal] = useState(false);
    const navigate = useNavigate();

    const detailThreadsHandle = (id) => {
        navigate("/developer/threads/" + id);
    };
    // const loadthreads = useCallback(async () => {
    //     let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    //     let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    //     let resultUUID = JSON.parse(atob(decrypt));

    //     let payloadSignature = [];

    //     let signature = Signature(payloadSignature);

    //     let dataHeaders = {
    //         'platform': 'WEBSITE',
    //         'gtoken': 'PASSS',
    //         'userindex': resultUUID.user.userindex,
    //         'tokenlogin': resultUUID.user.usertokenlogin,
    //         'signature': signature
    //     }

    //     let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/listthread', {
    //         headers: dataHeaders
    //     });

    //     if (response.data.status.status === 1) {
    //         setlistThread(response.data.data.listThread);
    //         setActiveThread(response.data.data.activeThread);
    //         setTotalThreads(response.data.data.totalThread);
    //         const listOfThread = response.data.data.listThread;
    //         const lengthOfListThread = (response.data.data.listThread).length
    //         let totalAverage = 0
    //         let newValue = [];

    //         (listOfThread).forEach(element => {
    //             if (element.total_review >= 0) {
    //                 newValue.push(element.total_review)
    //             }
    //         })
    //         for (var i in newValue) {
    //             totalAverage += newValue[i];
    //         }
    //         setAverageScoring((totalAverage / lengthOfListThread).toFixed(2));
    //     }

    //     if (response.data.status.status === 0) {
    //         response.data.status.message.forEach(element => {
    //             // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
    //             // let replaceError = resultError.replace('###', element.codevariable);

    //             switch (element.code.substring(0, 9)) {
    //                 case ErrorCode.unauthenticated:
    //                     navigate('/', {
    //                         state: {
    //                             errorMessage: "ErrorCode unauthenticated"
    //                         }
    //                     });
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         })
    //     }
    // }, [navigate]);

    const sortEndingDate = () => {
        if (isSortEndDate) {
            const sortEndingDate = listThread.sort(
                (a, b) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortEndingDate]);
        } else {
            const sortEndingDate = listThread.sort(
                (b, a) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortEndingDate]);
        }
    };
    const sortPublishDate = () => {
        if (isSortStartDate) {
            const sortPublishDate = listThread.sort(
                (a, b) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortPublishDate]);
        } else {
            const sortPublishDate = listThread.sort(
                (b, a) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortPublishDate]);
        }
    };
    const sortTitle = () => {
        if (isSortThreadTittle) {
            let sortedNames = listThread.sort((b, a) => {
                if (a.tablethreadtitle < b.tablethreadtitle) {
                    return -1;
                }
                if (a.tablethreadtitle > b.tablethreadtitle) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        } else {
            let sortedNames = listThread.sort((a, b) => {
                if (a.tablethreadtitle < b.tablethreadtitle) {
                    return -1;
                }
                if (a.tablethreadtitle > b.tablethreadtitle) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        }
    };
    const sortTotalReview = () => {
        if (isSortTotalReview) {
            let sortedTotalReview = listThread.sort((b, a) => {
                if (a.total_review < b.total_review) {
                    return -1;
                }
                if (a.total_review > b.total_review) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedTotalReview]);
        } else {
            let sortedTotalReview = listThread.sort((a, b) => {
                if (a.total_review < b.total_review) {
                    return -1;
                }
                if (a.total_review > b.total_review) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedTotalReview]);
        }
    };

    const sortIndex = () => {
        if (isSortThreadIndex) {
            let sortedNames = listThread.sort((b, a) => {
                if (a.tablethreadindex < b.tablethreadindex) {
                    return -1;
                }
                if (a.tablethreadindex > b.tablethreadindex) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        } else {
            let sortedNames = listThread.sort((a, b) => {
                if (a.tablethreadindex < b.tablethreadindex) {
                    return -1;
                }
                if (a.tablethreadindex > b.tablethreadindex) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        }
    };

    const toggleSort = (param) => {
        switch (param) {
            case "index":
                setIsSortThreadIndex(!isSortThreadIndex);
                sortIndex();
                break;
            case "title":
                setIsSortThreadTittle(!isSortThreadTittle);
                sortTitle();
                break;
            case "publishDate":
                setIsSortStartDate(!isSortStartDate);
                sortPublishDate();
                break;
            case "endingDate":
                setIsSortEndDate(!isSortEndDate);
                sortEndingDate();
                break;
            case "totalReview":
                setIsSortTotalReview(!isSortTotalReview);
                sortTotalReview();
                break;
            default:
                break;
        }
    };
    const closeModal = () => {
        setOpenVerificationModal(!openVerificationModal);
        getUserProfile();
    };
    const fillKYCForm = () => {
        setOpenVerificationModal(true);
    };
    const getUserProfile = useCallback(async () => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
        console.log("getU", getUUID);
        let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
            CryptoJS.enc.Utf8
        );
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: resultUUID.user.userindex,
            tokenlogin: resultUUID.user.usertokenlogin,
            signature: signature
        };

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(
            process.env.REACT_APP_HOSTNAMEAPI + "/api/user/v01/profile?id="+currentTimestamp,
            {
                headers: dataHeaders,
            }
        );

        try {
            console.log("masuk try", response);

            if (response.data.status.status === 1) {
                console.log("res_profile", response);
                setUserData(response.data.data);
                setKYCStatus(response.data.data.userstatus);
                localStorage.setItem("profileData", JSON.stringify(response.data.data));
            }

            if (response.data.status.status === 0) {
                response.data.status.message.forEach((element) => {
                    // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                    // let replaceError = resultError.replace('###', element.codevariable);

                    switch (element.code.substring(0, 9)) {
                        case ErrorCode.unauthenticated:
                            navigate("/mainLogin", {
                                state: {
                                    errorMessage: "ErrorCode unauthenticated",
                                },
                            });
                            break;
                        default:
                            break;
                    }
                });
            }
        } catch (err) {
            console.log("err_ProfileAPI");
        }
    }, [navigate]);
    useEffect(() => {
        const loadthreads = async () => {
            let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
            let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
                CryptoJS.enc.Utf8
            );
            let resultUUID = JSON.parse(atob(decrypt));

            let payloadSignature = [];

            let signature = Signature(payloadSignature);

            let dataHeaders = {
                platform: "WEBSITE",
                gtoken: "PASSS",
                userindex: resultUUID.user.userindex,
                tokenlogin: resultUUID.user.usertokenlogin,
                signature: signature,
            };

            const currentTimestamp = new Date().getTime();

            let response = await axios.get(
                process.env.REACT_APP_HOSTNAMEAPI + "/api/thread/v01/listthread?id="+currentTimestamp,
                {
                    headers: dataHeaders,
                }
            );
            try {
                if (response.data.status.status === 1) {
                    setlistThread(response.data.data.listThread);
                    setActiveThread(response.data.data.activeThread);
                    setTotalThreads(response.data.data.totalThread);
                    const listOfThread = response.data.data.listThread;
                    const lengthOfListThread = response.data.data.listThread.length;
                    let totalAverage = 0;
                    let newValue = [];

                    listOfThread.forEach((element) => {
                        if (element.total_review >= 0) {
                            newValue.push(element.total_review);
                        }
                    });
                    for (var i in newValue) {
                        totalAverage += newValue[i];
                    }
                    setAverageScoring(response.data.data.averageScore);

                    setTotalReview(response.data.data.totalReview);
                }

                if (response.data.status.status === 0) {
                    response.data.status.message.forEach((element) => {
                        // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                        // let replaceError = resultError.replace('###', element.codevariable);

                        switch (element.code.substring(0, 9)) {
                            case ErrorCode.unauthenticated:
                                navigate("/mainLogin", {
                                    state: {
                                        errorMessage: "ErrorCode unauthenticated",
                                    },
                                });
                                break;
                            default:
                                break;
                        }
                    });
                }
            } catch (err) {
                console.log("err", err);
            }
        };
        getUserProfile();
        loadthreads();
    }, [navigate, getUserProfile]);

    return (
        <>
            <div
                className="w-full h-screen bg-no-repeat bg-cover"
                style={{ backgroundImage: "url('/images/bg-2.png')" }}
            >
                <div className="w-full h-full">
                    <Sidebar />
                    <Transition
                        show={openVerificationModal}
                        enter="transition-opacity duration-705"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <VerificationModal cancel={() => closeModal()} profile={getUserProfile}/>
                    </Transition>

                    <div className="w-full h-full pl-[256px]">
                        <Transition
                            className="w-[100%] h-[100%] "
                            appear={true}
                            show={showTransition}
                            {...easeOutTransition}
                        >
                            <div className="flex flex-col w-full h-full">
                                <div className="w-full h-full max-w-[1500px] pl-6 m-auto flex flex-col">
                                    {userData ? (
                                        <Navbar
                                            heading="Dashboard"
                                            paragraf={"Welcome back, " + userData.userfullname}
                                            profile={getUserProfile}
                                            modalactive={() => fillKYCForm()}
                                        />
                                    ) : null}
                                    {userData ?
                                    userData.useraccounttype !== 'gamer' ?
                                    KYCStatus === "INACTIVE" || KYCStatus === "REJECTED" || KYCStatus === "NOT ACTIVE" ? (
                                        <div className="flex flex-row w-[100%] bg-[#EEB4B0] px-5 py-4  rounded-xl justify-between mt-6">
                                            <div className="flex flex-col gap-3">
                                                <span className="font-semibold">
                                                    Verify your identity to enjoy our full services!
                                                </span>
                                                <label className="font-normal text-[14px]">
                                                    Protect your identity and gain access to all the
                                                    features you need with just a few clicks.
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <button
                                                    className="bg-[#CB3A31] justify-center py-[10px] px-[25px] font-normal text-[14px] rounded-lg text-[#FFFFFF]"
                                                    onClick={() => fillKYCForm()}
                                                >
                                                    {" "}
                                                    Activate now
                                                </button>
                                            </div>
                                        </div>
                                    ) : null : '' : ''}
                                    {
                                        KYCStatus === 'NEED REVIEW' ?
                                            <div className="flex flex-row w-[100%] bg-[#fcd097] px-5 py-4  rounded-xl justify-between mt-6">
                                                <div className="flex flex-col gap-3">
                                                    <span className="font-semibold">
                                                        Your KYC status is On Review
                                                    </span>
                                                    <label className="font-normal text-[14px]">
                                                        Please wait until your KYC confirmed by our admin to enjoy our features!
                                                    </label>
                                                </div>

                                            </div> : null
                                    }

                                    {userData ? 
                                    userData.userstatus === 'SUSPENDED' ?
                                    <div className="flex flex-row w-[100%] bg-[#EEB4B0] px-5 py-4  rounded-xl justify-between mt-6">
                                        <div className="flex flex-col gap-3">
                                            <span className="font-semibold">
                                                Your account has been suspended!
                                            </span>
                                            <label className="font-normal text-[14px]">
                                                Your account has been suspended due to our concerns. You won’t be able to access all the features in the space. Your account can be unsuspended by contacting our customer service via email info@atn.co.id, or whatsapp to +628-517-236-6789.
                                            </label>
                                        </div>
                                    </div> : '' : '' }

                                    <div className="w-full">
                                        <div className="mt-[41px]">
                                            <div className="flex flex-row justify-between gap-3">
                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + "/images/card-1.png"
                                                            }
                                                            alt="card-1.png"
                                                        />
                                                        <div className="flex-auto w-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="flex-auto">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Active Thread(s)
                                                                        </label>
                                                                        {/* <div className="text-[#707375] cursor-pointer">
                                                                            <svg
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                                                                    stroke="#9AA4B2"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                                                                    stroke="#9AA4B2"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                                                                    stroke="#9AA4B2"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="w-full">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {activeThread}
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + "/images/card-2.png"
                                                            }
                                                            alt="card-2.png"
                                                        />
                                                        <div className="flex-auto w-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="flex-auto">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Total Thread(s)
                                                                        </label>
                                                                        <div className="text-[#707375] cursor-pointer">
                                                                            {/* <svg
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                                                                    stroke="#9AA4B2"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                                                                    stroke="#9AA4B2"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                                                                    stroke="#9AA4B2"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {totalThreads}
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + "/images/card-3.png"
                                                            }
                                                            alt="card-1.png"
                                                        />
                                                        <div className="flex-auto w-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="flex-auto">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Total Review(s)
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {totalReview}
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + "/images/card-4.png"
                                                            }
                                                            alt="card-3.png"
                                                        />
                                                        <div className="flex-auto w-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="flex-auto">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Average Scoring
                                                                        </label>
                                                                        <div className="text-[#707375] cursor-pointer">
                                                                            {/* <svg
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                                                                    stroke="#9AA4B2"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                                                                    stroke="#9AA4B2"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                                                                    stroke="#9AA4B2"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {averageScoring}
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full">
                                        <div className="mt-[41px]">
                                            <div className="flex flex-row items-center">
                                                <div className="flex-auto">
                                                    <h2 className="text-[20px] font-semibold">
                                                        Recent Thread(s)
                                                    </h2>
                                                </div>
                                                <div>
                                                    <button
                                                        className="flex flex-row items-center bg-[#FF0000] p-pd1 text-white rounded-md"
                                                        onClick={() => {
                                                            KYCStatus === "INACTIVE" || KYCStatus === "REJECT" || KYCStatus === "NOT ACTIVE" ? 
                                                            Swal.fire({
                                                                icon: "error",
                                                                title: "Unable to Create Thread",
                                                                text: "Please activate your account to enjoy all the features.",
                                                            }) :
                                                            navigate("/developer/threads/new")
                                                        }}
                                                    >
                                                        New Thread
                                                        <div className="pl-3">
                                                            <svg
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M10.0002 3.33325V16.6666M16.6668 9.99992L3.3335 9.99992"
                                                                    stroke="white"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <div className="relative overflow-x-auto">
                                                    <table className="w-full text-sm text-left">
                                                        <thead className="text-xs text-[14px]">
                                                            <tr className="text-[#5D5F61] border-b-2 border-[#D7DCDF]">
                                                                <th scope="col" className="py-3">
                                                                    <button
                                                                        onClick={() => toggleSort("index")}
                                                                        className="flex items-center"
                                                                    >
                                                                        <span className="text-[14px] pr-3">
                                                                            Thread ID
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortThreadIndex ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <button
                                                                        onClick={() => toggleSort("title")}
                                                                        className="flex items-center"
                                                                    >
                                                                        <span className="text-[14px] pr-3">
                                                                            Thread Title
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortThreadTittle ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <button
                                                                        onClick={() => toggleSort("publishDate")}
                                                                        className="flex items-center"
                                                                    >
                                                                        <span className="text-[14px] pr-3">
                                                                            Publish Date
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortStartDate ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <button
                                                                        onClick={() => toggleSort("endingDate")}
                                                                        className="flex items-center"
                                                                    >
                                                                        <span className="text-[14px] pr-3">
                                                                            Ending Date
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortEndDate ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <button className="flex items-center" onClick={() => toggleSort("totalReview")}>
                                                                        <span
                                                                            className="text-[14px] pr-3"
                                                                        >
                                                                            Total Review
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortTotalReview ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <span className="text-[14px] pr-3">
                                                                        Status
                                                                    </span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listThread.map((result, key) => (
                                                                <tr
                                                                    className="border-b-2 border-[#D7DCDF] cursor-pointer hover:bg-slate-400"
                                                                    key={key}
                                                                    onClick={() =>
                                                                        detailThreadsHandle(result.tablethreadindex)
                                                                    }
                                                                >
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreadindex}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreadtitle}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreaddatestart}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreaddateend}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.total_review}{" "}
                                                                        <label className="text-[#707375]">
                                                                            Reviews
                                                                        </label>
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreadstatus == 'PENDING' ?
                                                                            <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{result.tablethreadstatus}</span>
                                                                        : '' }

                                                                        {result.tablethreadstatus === 'CANCELED' ?
                                                                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{result.tablethreadstatus}</span>
                                                                        : '' }

                                                                        {result.tablethreadstatus === 'ACTIVE' ?
                                                                            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{result.tablethreadstatus}</span>
                                                                        : '' }

                                                                        {result.tablethreadstatus === 'ENDED' ?
                                                                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{result.tablethreadstatus}</span>
                                                                        : '' }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
