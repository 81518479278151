import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Signature from '../../helpers/Signature';
import CryptoJS from 'crypto-js';
import axios from "axios";

const ShareThreads = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const fetchProfileAPI = useCallback(async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let userRole = localStorage.getItem('userRole');

        if(getUUID){
            let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
            let resultUUID = JSON.parse(atob(decrypt));
            let payloadSignature = [];
            let signature = Signature(payloadSignature);

            let dataHeaders = {
                'platform': 'WEBSITE',
                'gtoken': 'PASSS',
                'userindex': resultUUID.user.userindex,
                'tokenlogin': resultUUID.user.usertokenlogin,
                'signature': signature
            }
            let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/profile', {
                headers: dataHeaders
            });

            if (response.data.status.status === 1) {
                navigate('/gamers/gamesDetail/' + id,
                {
                    state: {
                        isAuthenticated: userRole,
                        userData: response.data.data,
                        tableThreadData: []
                    }

                });
            }
        }else{
            navigate('/gamers/gamesDetail/' + id,
                {
                    state: {
                        isAuthenticated: userRole,
                        userData: {},
                        tableThreadData: []
                    }

                });
        }        
    }, []);

    useEffect(() => {
        fetchProfileAPI();
    }, [fetchProfileAPI]);

    return(
        <>Blank</>
    )
}

export default ShareThreads;