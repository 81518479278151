import { useRef } from "react";
import Signature from "../../../../helpers/Signature";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useState } from "react";

const GamersUpdateProfile = (props) => {
    const inputVal = useRef();
    const [errMessage, setErrorMessage] = useState('');
    const [isSuccessUpdate, setIsSuccessUpdate] = useState(false)
    const _updateHandler = async () => {

        setErrorMessage("")
        let payloadSignature;
        let key = props.type;
        let payload;

        switch (key) {
            case 'Username':
                payload = { "username": inputVal.current.value };
                payloadSignature = [inputVal.current.value];
                break;
            case 'Fullname':
                payload = { "userfullname": inputVal.current.value };
                payloadSignature = [inputVal.current.value];
                break;
            case 'Phone Number':
                payload = { "userphonecountrycode": "62", "userphonenumbershort": inputVal.current.value };
                payloadSignature = ["62", inputVal.current.value];
                break;
            // case 'Email':
            //     payload = { "username": inputVal.target.value }
            //     break;
            default:
                break;
        }
        let signature = Signature(payloadSignature);

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));


        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        try {
            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/changeprofile', payload, {
                headers: dataHeaders
            });

            console.log('res_update', res)
            if (res.data.status.status !== 1) {
                switch (res.data.status.message[0].code) {
                    case "008005020005":
                        setErrorMessage(res.data.status.message[0].errormessage)
                        break;
                    case "008005020035":
                        setErrorMessage(res.data.status.message[0].errormessage)
                        break;
                    case "008005020040":
                        setErrorMessage(res.data.status.message[0].errormessage)
                        break;
                    case "008005020045":
                        setErrorMessage(res.data.status.message[0].errormessage)
                        break;
                    case "008005055015":
                        setErrorMessage(res.data.status.message[0].errormessage)
                        break;
                    case "0088005060015":
                        setErrorMessage(res.data.status.message[0].errormessage)
                        break;
                    case "008005010030":
                        setErrorMessage(res.data.status.message[0].errormessage)
                        break;

                    default:
                        break;
                }
            }
            else{
                setIsSuccessUpdate(true);
            }

        }
        catch (err) {
            console.log('err', err)
        }
    }
    const closeModal = () =>{
        setIsSuccessUpdate(false);
        props.cancel();
        
    }
    return (
        <>
            {props.modal ?
                <>
                    <div className="bg-bg1  backdrop-blur-[3px] fixed z-10 inset-0"></div>
                    <div className="fixed z-20 inset-0">
                        <div className=" w-full h-full relative">
                            <div className="absolute translate-y-[-50%] top-[50%] w-full" >
                                <div className="max-w-[600px] max-h-[50%] m-auto text-white">
                                    <div className="bg-[#000032] border-[#49454F] border-[1px] overflow-y-scroll flex flex-col gap-3 rounded-lg px-[48px] py-[48px]">
                                        {
                                            !isSuccessUpdate ?
                                                <div className="flex flex-col text-[#FFFF] min-h-[400px]">
                                                    {/* <div className="flex justify-start relative  " onClick={props.cancel}> */}

                                                    <div className="flex-initial ml-auto hover:cursor-pointer" onClick={props.cancel}>
                                                        <img src={process.env.PUBLIC_URL + '/images/ReviewModal/closeIcon.svg'} alt="element" className='' />
                                                    </div>
                                                    {/* </div> */}
                                                    <div className="flex flex-col  justify-between h-[40vh]">
                                                        <h4 className="text-center">Change {props.type}</h4>
                                                        <div className="flex flex-col">
                                                            <div className="flex flex-row items-center gap-3">
                                                                {
                                                                    props.type === "Phone Number" ? <select className="w-[20%] h-[5vh] text-black pl-2 rounded-md">
                                                                        <option>+62</option>
                                                                    </select> : null
                                                                }
                                                                <input type="text" ref={inputVal} className="w-[100%] h-[5vh] text-black pl-2 rounded-md" placeholder={props.type}></input>
                                                            </div>
                                                            <span className="text-red">{errMessage}</span>
                                                        </div>

                                                        <button onClick={() => _updateHandler()} className="bg-bm2  h-[5vh] w-[100%] rounded-xl">
                                                            <span className="text-[#FFFF]">Change</span>
                                                        </button>
                                                    </div>


                                                </div>
                                                :
                                                <div className="flex flex-col text-[#FFFF] ">
                                                    <div className="flex justify-end cursor-pointer" onClick={()=>closeModal()}>
                                                        <img src={process.env.PUBLIC_URL + '/images/ReviewModal/closeIcon.svg'} alt="element" className='' />
                                                    </div>
                                                    <div className="justify-center items-center flex flex-col gap-3 py-12">
                                                        <img src={process.env.PUBLIC_URL + '/images/ReviewModal/anoaFinish.svg'} alt="element" className='w-[50%]' />
                                                        <label className="text-xl">Congratulations!</label>
                                                        <label>Your update successfully change!</label>
                                                    </div>

                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null}
        </>
    );
}

export default GamersUpdateProfile;