import axios from "axios";
import { useCallback } from "react";
import { useRef, useState } from "react";
import CryptoJS from "crypto-js";
import Signature from "../../../helpers/Signature";
import { Transition } from '@headlessui/react'
import { spinTransitionClasses, fadeSpinTranstitionClasses, easeInOutClasses } from "../../../transitions/Transitions";
import BlurBarLoader from "../../../components/loader/BlurBarLoader";
import Swal from "sweetalert2";
import YouTube from 'react-youtube';

const randomId = function (length = 6) {
    return Math.random().toString(36).substring(2, length + 2);
};

const ReviewModal = (props) => {
    const { cancel, tokenTrans, threadIndex, datathreads } = props;
    const [textAreaLength, setTextAreaLength] = useState(0);
    const [isRemounting, setisRemounting] = useState(false);
    const [isRemountingRating, setisRemountingRating] = useState(false);
    const [isFinish, setIsFinish] = useState(false);
    const inputReview = useRef();
    const [isWalletLinked, setIsWalletLinked] = useState(false);
    const [showTranstion, setShowTransition] = useState(true);
    const [loading, setIsLoading] = useState(false);
    const [fileImageReview, setFileImageReview] = useState([]);
    const [errorOther, setErrorOther] = useState([]);
    const [arrayUploadForm, setArrayUploadForm] = useState([]);
    const [linkReview, setLinkReview] = useState(null);
    const [linkYoutube, setLinkYoutube] = useState(null);

    const inputLinkYoutube = useRef();

    const [rateStar, setRateStar] = useState([
        {
            id: 1,
            selected: false
        },
        {
            id: 2,
            selected: false
        },
        {
            id: 3,
            selected: false
        },
        {
            id: 4,
            selected: false
        },
        {
            id: 5,
            selected: false
        },
    ]);

    console.log(datathreads);

    const AddMoreReviewImage = () => {
        let id = 'img' + randomId(5);
        setArrayUploadForm(arrayUploadForm => [...arrayUploadForm,
        {
            id: id,
            value: ''
        }]);
    }

    const _handleFileInputReview = (e, item) => {
        // const file = e.target.files;
        // const reader = new FileReader();

        // if (file) {
        //     reader.readAsDataURL(file);

        //     reader.onloadend = () => {
        //         let temp = arrayUploadForm;
        //         temp.forEach((elem) => {
        //             if (elem.id === item.id) {
        //                 elem.value = reader.result;
        //             }
        //         })

        //         setArrayUploadForm(temp);
        //         setisRemounting(!isRemounting);
        //     };

        //     setFileImageReview([...fileImageReview, file]);
        // }

        const selectedFiles = e.target.files;

        if (!selectedFiles) {
            return;
        }

        const newPreviews = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            const reader = new FileReader();
            const file = selectedFiles[i];

            if (!file) {
                continue;
            }

            reader.onload = (event) => {
                const dataURL = event.target.result;
                newPreviews.push({ file, dataURL });

                if (newPreviews.length === selectedFiles.length) {
                    let arrayFiles = Array.from(selectedFiles);
                    let Videos  = arrayFiles.filter(item => item.type === 'video/mp4');
                    let Image   = arrayFiles.filter(item => item.type === 'image/jpeg');

                    if(Videos.length > 1){
                        Swal.fire('Upload Video (Max 1)', '', 'info')
                    }else{
                        if(Videos.length < 0){
                            const maxSizeInBytes = 20 * 1024 * 1024;

                            if(maxSizeInBytes > Videos[0].size){
                                setArrayUploadForm((prevPreviews) => [...prevPreviews, ...newPreviews]);
                            }else{
                                Swal.fire('Maximum video size 20mb', '', 'info')
                            }
                        }
                        else{
                            if(Image.length > 7){
                                Swal.fire('Upload Image (Max 7)', '', 'info')
                            }else{
                                setArrayUploadForm((prevPreviews) => [...prevPreviews, ...newPreviews]);
                            }
                        }
                    }
                }
            };

            reader.readAsDataURL(file);
        }

        setFileImageReview([...fileImageReview, ...selectedFiles]);
    };

    const updateRating = (id) => {
        let temp = rateStar;
        temp.forEach(elem => {
            if (elem.id === id || elem.id < id) {
                elem.selected = true
            }
            else {
                elem.selected = false
            }
        })
        setRateStar(temp);
        setisRemountingRating(!isRemountingRating);
    }
    const _handleChangeTextArea = useCallback(
        (e) => {
            let typed = e.target.value.split(" ");
            let count = typed.length;
            setTextAreaLength(count);
        }, [[setTextAreaLength, inputReview]]
    )
    const uploadReviewImage = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature,
            'tokentrans': tokenTrans,
            'threadindex': threadIndex
            // 'Content-Type': 'multipart/form-data'
        }

        let formData = new FormData();

        fileImageReview.forEach((result) => {
            formData.append('file', result);
        })

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/review/v01/upload', formData, { headers: dataHeaders, });

        console.log('token_image' + tokenTrans);

        try {
            return response;
        }
        catch (err) {
            console.log('err', err)
        }
    }
    const uploadReviewData = async () => {
        let count = 0;
        rateStar.forEach(element => {
            if (element.selected === true) {
                count++
            }
        });
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [inputReview.current.value, count, 5, threadIndex];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature,
            'tokentrans': tokenTrans
        }
        let payload = {
            "description": inputReview.current.value,
            "rating": count,
            "threadindex": threadIndex,
            "youtube_link" : linkYoutube
        }
        console.log('payload', payload)
        console.log('token_review' + tokenTrans);

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/review/v01/addreview', payload, { headers: dataHeaders, });

        try {
            return response;
        }
        catch (err) {
            console.log('err', err)
        }
    }
    // const fileHandle = async () => {
    //     setCancelModal(true);
    //     setIsProcessUpload(true);

    //     let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    //     let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    //     let resultUUID = JSON.parse(atob(decrypt));

    //     let payloadSignature = [];

    //     let signature = Signature(payloadSignature);

    //     let dataHeaders = {
    //         'platform': 'WEBSITE',
    //         'gtoken': 'PASSS',
    //         'userindex': resultUUID.user.userindex,
    //         'tokenlogin': resultUUID.user.usertokenlogin,
    //         'signature': signature,
    //         'tokentrans': localStorage.getItem('tokentrans'),
    //         'Content-Type': 'multipart/form-data'
    //     }

    //     let formData = new FormData();
    //     // formData.append('file', fileGambar);
    //     // formData.append('file', fileVideo);

    //     arrayPreviewArtWork.forEach(element => {
    //         formData.append('file', element.file);
    //     });



    //     let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/upload', formData, {
    //         headers: dataHeaders,

    //     });

    //     if (response.data.status.status === 1) {
    //         let contentModal = {
    //             'head': 'Success',
    //             'pesan': 'Upload file succesfully',
    //             'description': 'file upload process is successful, continue the threads data input process.'
    //         };
    //         setContentModal(contentModal);
    //         setTypeModal('success');
    //         setModal(true);
    //     }

    //     if (response.data.status.status === 0) {
    //         response.data.status.message.forEach(element => {
    //             // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
    //             // let replaceError = resultError.replace('###', element.codevariable);

    //             switch (element.code.substring(0, 9)) {
    //                 case ErrorCode.unauthenticated:
    //                     navigate('/login/developers');
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         })
    //     }

    //     setIsProcessUpload(false);
    // }
    const doCancel = () => {
        setShowTransition(false);
        setTimeout(() => {
            cancel();
            setShowTransition(true);
        }, 500);
    }
    const _postReviewHandler = async () => {
       
        // return Promise.all([uploadReviewImage(), uploadReviewData()]).then(([revieImage, reviewData]) => {
        //     console.log('res_revieImage', revieImage)
        //     console.log('res_reviewData', reviewData)
        //     if (reviewData.data.status.status === 1 && revieImage.data.status.status === 1) {
        //         props.loadgetthreads()
        //         setIsLoading(false);
        //         setIsWalletLinked(true)
        //         setIsFinish(true);
        //     }
        //     if (reviewData.data.status.status !== 1) {
        //         if (reviewData.data.status.message[0].code === "008040065035") {
        //             setIsWalletLinked(false)
        //             setIsFinish(true);
        //         }
        //         setIsLoading(false);
        //     }
        //     if (revieImage.data.status.status !== 1) {
        //         if (revieImage.data.status.message[0].code === "008040065035") {
        //             setIsWalletLinked(false)
        //             setIsFinish(true);
        //         }
        //         setIsLoading(false);
        //     }
        // })

        setIsLoading(true);
        let revieImage = await uploadReviewImage();
        let reviewData = await uploadReviewData();

        if (reviewData.data.status.status === 1) {
            props.loaddatapage();
            setIsWalletLinked(true)
            setIsFinish(true);
            setIsLoading(false);
        }
        if (reviewData.data.status.status !== 1) {
            if (reviewData.data.status.message[0].code === "008040065035") {
                setIsWalletLinked(false)
                setIsFinish(true);
                setIsLoading(false);
            }

            setIsLoading(false);
            setErrorOther(reviewData.data.status.message);
        }
        if (revieImage.data.status.status !== 1) {
            if (revieImage.data.status.message[0].code === "008040065035") {
                setIsWalletLinked(false)
                setIsFinish(true);
                setIsLoading(false);
            }
        }
    }

    const opts = {
        height: '390',
        width: '100%',
        playerVars: {
        autoplay: 1,
        },
    };

    const uploadYoutubeHandle = () => {
        let link = inputLinkYoutube.current.value;
        let regex = /[?&]v=([^&]+)/;
        let match = link.match(regex);

        if(match){
            setLinkYoutube(link)
            setLinkReview(match[1]);
        }else{
            setLinkReview(null);
            Swal.fire('Please input link youtube', '', 'info');
        }
    }
    return (
        <>
            <div className="bg-bg1  backdrop-blur-[3px] fixed z-10 inset-0"></div>
            <div className={loading ? 'block' : 'hidden'}>
                <BlurBarLoader></BlurBarLoader>
            </div>
            <div className={loading ? 'hidden' : 'fixed inset-0 z-20'}>
                <div className="relative w-full h-full ">
                    <div className="absolute translate-y-[-50%] top-[50%] w-full" >
                        <Transition
                            appear={true}
                            show={showTranstion}
                            {...easeInOutClasses}
                        >
                            <div className="max-w-[925px] m-auto max-h-[100vh]">
                                <div className="bg-[#000032] border-[#49454F] border-[1px] overflow-auto flex flex-col justify-between gap-3 rounded-lg px-[48px] py-[48px] max-h-[100vh] h-[800px] ">
                                    {
                                        !isFinish ?
                                            <>
                                                <div className="flex flex-row gap-3">
                                                    <img src={process.env.PUBLIC_URL + '/images/ReviewModal/pageIcon.svg'} alt="element" className='' />
                                                    <div className="flex flex-col gap-1 text-[#E6E1E5]">
                                                        <label className="text-lg"> Review </label>
                                                        <label className="text-sm"> Contribute your best review to this thread and <span className="text-[#8182E6]">get 0,3 Anoa!</span></label>
                                                    </div>
                                                </div>
                                                {errorOther.length > 0 ?
                                                <div className="bg-red-500 p-2 rounded-md">
                                                    {errorOther.map((result, key) => (
                                                        <ul key={key} className="text-white list-disc pl-5">
                                                            <li>{result.errormessage}</li>
                                                        </ul>
                                                    ))}
                                                </div> : '' }
                                                <div className="flex flex-col gap-2 text-[#E6E1E5]">
                                                    <div className="flex flex-row justify-between">
                                                        <label className="text-lg font-medium">Write Review</label>
                                                    </div>
                                                    <textarea minLength={props.datathreads.tablethreadreviewwordcount} ref={inputReview} onChange={(e) => _handleChangeTextArea(e)} className="bg-[#04003C] px-2 h-36 invalid:border-2 border-red-500"></textarea>
                                                    <label className="text-lg font-medium text-end">{textAreaLength}/{props.datathreads.tablethreadreviewwordcount}</label>
                                                </div>
                                                <div className="flex flex-col gap-2 text-[#E6E1E5]">
                                                    <label className="text-lg font-medium">Game Rating</label>
                                                    <div className="flex flex-row gap-2" key={isRemountingRating}>
                                                        {
                                                            rateStar.map((elem, index) => {
                                                                return (
                                                                    elem.selected ?
                                                                        <Transition
                                                                            appear={true}
                                                                            show={showTranstion}
                                                                            {...spinTransitionClasses}
                                                                        >
                                                                            <img src={process.env.PUBLIC_URL + '/images/ReviewModal/ratedStar.svg'} alt="element" className='cursor-pointer' onClick={() => updateRating(elem.id)}></img>
                                                                        </Transition>
                                                                        :
                                                                        <img src={process.env.PUBLIC_URL + '/images/ReviewModal/unratedStar.svg'} alt="element" className='cursor-pointer' onClick={() => updateRating(elem.id)} ></img>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {datathreads.tablethreadisuploadmedia !== 0 ?
                                                <div className="flex flex-col gap-2 text-[#E6E1E5]">
                                                    <div className="flex flex-row justify-between">
                                                        <div className="flex flex-col">
                                                            <label className="text-lg font-medium">Upload Your Experience ( Opsional )</label>
                                                            <label>Share your game play experience and <span className="text-[#8182E6]">get extra 0.2 Anoa!</span></label>
                                                        </div>
                                                        {arrayUploadForm.length > 0 ?
                                                        <label className="text-lg font-medium text-red-500 cursor-pointer" onClick={() => setArrayUploadForm([])}>Remove Picture</label>
                                                        : '' }
                                                    </div>
                                                    <div className="border-2 rounded-md border-dashed cursor-pointer" onClick={
                                                        arrayUploadForm.length > 0 ? '' : () => document.querySelector(`input[type=file]`).click()
                                                    }>
                                                        <input
                                                            id="dropzone-file"
                                                            type="file"
                                                            accept=".png, .jpg, .jpeg, .mp4"
                                                            onChange={(e) => _handleFileInputReview(e)}
                                                            style={{ display: "none" }}
                                                            multiple={true}
                                                        />
                                                        {console.log(arrayUploadForm)}
                                                        {arrayUploadForm.length > 0 ? 
                                                            <div className="grid grid-cols-4 w-full h-full gap-2 p-2">
                                                                {arrayUploadForm.map((result, key) => {
                                                                    let index = key;
                                                                    return(
                                                                        <>
                                                                            <div key={index}>
                                                                                {result.file.type.startsWith('image/') ? (
                                                                                    <img src={result.dataURL} alt={`Preview ${index}`}  className="w-full h-[200px]"/>
                                                                                ) : result.file.type.startsWith('video/') ? (
                                                                                    <div className="w-full h-[200px]">
                                                                                        <video controls autoPlay={true} className="object-cover h-full">
                                                                                            <source src={result.dataURL} type={result.file.type} />
                                                                                            Your browser does not support the video tag.
                                                                                        </video>
                                                                                    </div>
                                                                                ) : (
                                                                                    <p>Unsupported file type: {result.file[index].name}</p>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        : 
                                                            <div className="w-full h-full flex flex-col justify-center items-center py-10">
                                                                <img src={process.env.PUBLIC_URL + '/images/ReviewModal/upload.svg'} alt="element" className='' />
                                                                <label className="text-lg block">Drag file here </label>
                                                                <label className="block">or, click to browse </label>
                                                                <label className="block">(JPG, JPEG, PNG or MP4 File)</label>
                                                            </div>
                                                        }
                                                    </div>
                                                </div> : '' }
                                                {datathreads.tablethreadisuploadmedia !== 0 ? 
                                                <div className="mt-5">
                                                    <label className="text-lg font-medium text-white">Attach video URL</label>
                                                    <div className="mb-3 mt-2 relative">
                                                        <div className="absolute top-1 left-3">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.8284 10.1716C12.2663 8.60948 9.73367 8.60948 8.17157 10.1716L4.17157 14.1716C2.60948 15.7337 2.60948 18.2663 4.17157 19.8284C5.73367 21.3905 8.26633 21.3905 9.82843 19.8284L10.93 18.7269M10.1716 13.8284C11.7337 15.3905 14.2663 15.3905 15.8284 13.8284L19.8284 9.82843C21.3905 8.26633 21.3905 5.73367 19.8284 4.17157C18.2663 2.60948 15.7337 2.60948 14.1716 4.17157L13.072 5.27118" stroke="#8182E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                        </div>
                                                        <input type='text' ref={inputLinkYoutube} placeholder="Example : https://www.youtube.com/watch?v=t--PEdHXR-0" className="bg-[#04003C] h-[30px] invalid:border-2 border-red-500 w-full text-white pl-10 pr-20"/>
                                                        <span className="text-[#8182E6] font-bold absolute top-1 right-3 cursor-pointer" onClick={uploadYoutubeHandle}>Upload</span>
                                                    </div>
                                                    {linkReview !== null ?
                                                    <div className="border-2 border-gray-600 rounded-lg">
                                                        <YouTube videoId={linkReview} opts={opts} />
                                                    </div> : '' }
                                                </div>
                                                : '' }
                                                <div className="flex flex-row justify-between text-[#FFFF]">
                                                    <button className="border-[#49454F] rounded-lg w-[49%] border-2 py-3" onClick={() => doCancel()}>Cancel</button>
                                                    <button className="bg-bm2 rounded-lg w-[49%]" onClick={() => _postReviewHandler()}> Post Review</button>
                                                </div> 
                                            </>
                                            :
                                            <>
                                                {
                                                    !isWalletLinked === true ?

                                                        <div className="flex flex-col text-[#FFFF] ">
                                                            <div className="flex justify-end cursor-pointer" onClick={doCancel}>
                                                                <img src={process.env.PUBLIC_URL + '/images/ReviewModal/closeIcon.svg'} alt="element" className='' />
                                                            </div>
                                                            <div className="flex flex-col items-center justify-center gap-3 py-12">
                                                                <Transition
                                                                    className=" flex justify-center w-[12vw]"
                                                                    appear={true}
                                                                    show={showTranstion}
                                                                    {...easeInOutClasses}
                                                                >
                                                                    <img src={process.env.PUBLIC_URL + '/images/ReviewModal/anoaRocket.svg'} alt="element" className='w-[100%]' />
                                                                </Transition>
                                                                <label className="text-xl">Please connect your wallet to enjoy this feature!</label>
                                                                <label className="text-xl">You can link your wallet at your profile page</label>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="flex flex-col text-[#FFFF] ">
                                                            <div className="flex justify-end cursor-pointer" onClick={doCancel}>
                                                                <img src={process.env.PUBLIC_URL + '/images/ReviewModal/closeIcon.svg'} alt="element" className='' />
                                                            </div>
                                                            <div className="flex flex-col items-center justify-center gap-3 py-12">
                                                                <Transition
                                                                    className="h-[20vh] flex justify-center"
                                                                    appear={true}
                                                                    show={showTranstion}
                                                                    {...fadeSpinTranstitionClasses}
                                                                >
                                                                    <img src={process.env.PUBLIC_URL + '/images/ReviewModal/anoaFinish.svg'} alt="element" className='w-[100%]' />
                                                                </Transition>

                                                                <label className="text-xl">Congratulations!</label>
                                                                <label>Your review is in the process of being reviewed!</label>
                                                            </div>
                                                        </div>
                                                }
                                            </>

                                    }

                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
            {/* </> : null} */}
        </>
    );
}

export default ReviewModal;