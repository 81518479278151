import { useCallback, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import { Transition } from "@headlessui/react";

import Navbar from "../../../components/layouts/Navbar";
import Sidebar from "../../../components/layouts/Sidebar";
import Signature from "../../../helpers/Signature";
import ErrorCode from "../../../helpers/errorcode.json";
import VerificationModal from "../home/VerificationModal";
import { easeOutTransition } from "../../../transitions/Transitions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRef } from "react";

const Review = () => {
    const [listThread, setlistThread] = useState([]);
    const [activeThread, setActiveThread] = useState("");
    const [totalThreads, setTotalThreads] = useState("");
    const [averageScoring, setAverageScoring] = useState("");
    const [userData, setUserData] = useState(null);
    const [KYCStatus, setKYCStatus] = useState("");
    //sort
    const [showTransition] = useState(true);
    const [isSortStartDate, setIsSortStartDate] = useState(false);
    const [isSortEndDate, setIsSortEndDate] = useState(false);

    const [isSortStatus, setIsSortStatus] = useState(false);
    const [isSortThreadTittle, setIsSortThreadTittle] = useState(false);
    const [isSortThreadIndex, setIsSortThreadIndex] = useState(false);
    const [isSortTotalReview, setIsSortTotalReview] = useState(false);
    const [isSortAvgRating, setIsSortAvgRating] = useState(false);
    const [isSortTotalDisbursement, seIsSortTotalDisbursement] = useState(false);

    //Button
    const [openVerificationModal, setOpenVerificationModal] = useState(false);
    const navigate = useNavigate();

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [dataReviewThreads, setDataReviewThreads] = useState({});
    const [dataReviewList, setDataReviewList] = useState([]);

    const inputSearchThreads = useRef();

    // const loadthreads = useCallback(async () => {
    //     let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    //     let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    //     let resultUUID = JSON.parse(atob(decrypt));

    //     let payloadSignature = [];

    //     let signature = Signature(payloadSignature);

    //     let dataHeaders = {
    //         'platform': 'WEBSITE',
    //         'gtoken': 'PASSS',
    //         'userindex': resultUUID.user.userindex,
    //         'tokenlogin': resultUUID.user.usertokenlogin,
    //         'signature': signature
    //     }

    //     let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/listthread', {
    //         headers: dataHeaders
    //     });

    //     if (response.data.status.status === 1) {
    //         setlistThread(response.data.data.listThread);
    //         setActiveThread(response.data.data.activeThread);
    //         setTotalThreads(response.data.data.totalThread);
    //         const listOfThread = response.data.data.listThread;
    //         const lengthOfListThread = (response.data.data.listThread).length
    //         let totalAverage = 0
    //         let newValue = [];

    //         (listOfThread).forEach(element => {
    //             if (element.total_review >= 0) {
    //                 newValue.push(element.total_review)
    //             }
    //         })
    //         for (var i in newValue) {
    //             totalAverage += newValue[i];
    //         }
    //         setAverageScoring((totalAverage / lengthOfListThread).toFixed(2));
    //     }

    //     if (response.data.status.status === 0) {
    //         response.data.status.message.forEach(element => {
    //             // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
    //             // let replaceError = resultError.replace('###', element.codevariable);

    //             switch (element.code.substring(0, 9)) {
    //                 case ErrorCode.unauthenticated:
    //                     navigate('/', {
    //                         state: {
    //                             errorMessage: "ErrorCode unauthenticated"
    //                         }
    //                     });
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         })
    //     }
    // }, [navigate]);

    const sortEndingDate = () => {
        if (isSortEndDate) {
            const sortEndingDate = listThread.sort(
                (a, b) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortEndingDate]);
        } else {
            const sortEndingDate = listThread.sort(
                (b, a) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddateend
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortEndingDate]);
        }
    };
    const sortPublishDate = () => {
        if (isSortStartDate) {
            const sortPublishDate = listThread.sort(
                (a, b) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortPublishDate]);
        } else {
            const sortPublishDate = listThread.sort(
                (b, a) =>
                    Date.parse(
                        new Date(
                            b.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    ) -
                    Date.parse(
                        new Date(
                            a.tablethreaddatestart
                                .split("-")
                                .reverse()
                                .join("-")
                                .split("/")
                                .reverse()
                                .join("-")
                        )
                    )
            );
            setlistThread([...sortPublishDate]);
        }
    };
    const sortTitle = () => {
        if (isSortThreadTittle) {
            let sortedNames = listThread.sort((b, a) => {
                if (a.tablethreadtitle < b.tablethreadtitle) {
                    return -1;
                }
                if (a.tablethreadtitle > b.tablethreadtitle) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        } else {
            let sortedNames = listThread.sort((a, b) => {
                if (a.tablethreadtitle < b.tablethreadtitle) {
                    return -1;
                }
                if (a.tablethreadtitle > b.tablethreadtitle) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedNames]);
        }
    };
    const sortTotalReview = () => {
        if (isSortTotalReview) {
            let sortedTotalReview = listThread.sort((b, a) => {
                if (a.total_review < b.total_review) {
                    return -1;
                }
                if (a.total_review > b.total_review) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedTotalReview]);
        } else {
            let sortedTotalReview = listThread.sort((a, b) => {
                if (a.total_review < b.total_review) {
                    return -1;
                }
                if (a.total_review > b.total_review) {
                    return 1;
                }
                return 0;
            });
            setlistThread([...sortedTotalReview]);
        }
    };
    const closeModal = () => {
        setOpenVerificationModal(!openVerificationModal);
        getUserProfile();
    };
    const fillKYCForm = () => {
        setOpenVerificationModal(true);
    };
    const getUserProfile = useCallback(async () => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
        console.log("getU", getUUID);
        let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
            CryptoJS.enc.Utf8
        );
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: resultUUID.user.userindex,
            tokenlogin: resultUUID.user.usertokenlogin,
            signature: signature,
        };

        let response = await axios.get(
            process.env.REACT_APP_HOSTNAMEAPI + "/api/user/v01/profile",
            {
                headers: dataHeaders,
            }
        );

        try {
            console.log("masuk try", response);

            if (response.data.status.status === 1) {
                console.log("res_profile", response);
                setUserData(response.data.data);
                setKYCStatus(response.data.data.userstatus);
                localStorage.setItem("profileData", JSON.stringify(response.data.data));
            }

            if (response.data.status.status === 0) {
                response.data.status.message.forEach((element) => {
                    // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                    // let replaceError = resultError.replace('###', element.codevariable);

                    switch (element.code.substring(0, 9)) {
                        case ErrorCode.unauthenticated:
                            navigate("/mainLogin", {
                                state: {
                                    errorMessage: "ErrorCode unauthenticated",
                                },
                            });
                            break;
                        default:
                            break;
                    }
                });
            }
        } catch (err) {
            console.log("err_ProfileAPI");
        }
    }, [navigate]);
    useEffect(() => {
        const loadthreads = async () => {
            let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
            let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
                CryptoJS.enc.Utf8
            );
            let resultUUID = JSON.parse(atob(decrypt));

            let payloadSignature = [];

            let signature = Signature(payloadSignature);

            let dataHeaders = {
                platform: "WEBSITE",
                gtoken: "PASSS",
                userindex: resultUUID.user.userindex,
                tokenlogin: resultUUID.user.usertokenlogin,
                signature: signature,
            };

            let response = await axios.get(
                process.env.REACT_APP_HOSTNAMEAPI + "/api/thread/v01/listthread",
                {
                    headers: dataHeaders,
                }
            );
            try {
                if (response.data.status.status === 1) {
                    setlistThread(response.data.data.listThread);
                    setActiveThread(response.data.data.activeThread);
                    setTotalThreads(response.data.data.totalThread);
                    const listOfThread = response.data.data.listThread;
                    const lengthOfListThread = response.data.data.listThread.length;
                    let totalAverage = 0;
                    let newValue = [];

                    listOfThread.forEach((element) => {
                        if (element.total_review >= 0) {
                            newValue.push(element.total_review);
                        }
                    });
                    for (var i in newValue) {
                        totalAverage += newValue[i];
                    }
                    setAverageScoring((totalAverage / lengthOfListThread).toFixed(2));
                }

                if (response.data.status.status === 0) {
                    response.data.status.message.forEach((element) => {
                        // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                        // let replaceError = resultError.replace('###', element.codevariable);

                        switch (element.code.substring(0, 9)) {
                            case ErrorCode.unauthenticated:
                                navigate("/mainLogin", {
                                    state: {
                                        errorMessage: "ErrorCode unauthenticated",
                                    },
                                });
                                break;
                            default:
                                break;
                        }
                    });
                }
            } catch (err) {
                console.log("err", err);
            }
        };
        getUserProfile();
        loadthreads();
    }, [navigate, getUserProfile]);

    const loadReviewThreads = useCallback(async (value) => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
        let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
            CryptoJS.enc.Utf8
        );
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: resultUUID.user.userindex,
            tokenlogin: resultUUID.user.usertokenlogin,
            signature: signature,
        };

        let ISOStartDate = new Date(startDate);
        let ISOFormatStartDate = ISOStartDate.toISOString().split('T')[0];

        let ISOEndDate = new Date(endDate);
        let ISOFormatEndDate = ISOEndDate.toISOString().split('T')[0];

        const currentTimestamp = new Date().getTime();

        if(startDate === null && endDate === null){
            let response = await axios.get(
                process.env.REACT_APP_HOSTNAMEAPI + "/api/review/v01/developer/listthread?title="+value+"&id="+currentTimestamp,
                {
                    headers: dataHeaders,
                }
            );
    
            if(response.data.status.status === 1){
                setDataReviewThreads(response.data.data);   
                setDataReviewList(response.data.data.listThread);
            }
        }else{
            let response = await axios.get(
                process.env.REACT_APP_HOSTNAMEAPI + "/api/review/v01/developer/listthread?title="+value+"datestart="+ISOFormatStartDate+"&dateend="+ISOFormatEndDate+"&id="+currentTimestamp,
                {
                    headers: dataHeaders,
                }
            );
    
            if(response.data.status.status === 1){
                console.log(response.data.data);
                setDataReviewThreads(response.data.data);   
                setDataReviewList(response.data.data.listThread);
            }
        }

        
    }, [startDate, endDate]);

    useEffect(() => {
        loadReviewThreads('');
    }, [loadReviewThreads]);

    const searchHandle = () => {
        let value = inputSearchThreads.current.value;

        loadReviewThreads(value);
    }

    const sortStatus = () => {
        if (isSortStatus) {
            let sortedNames = dataReviewList.sort((b, a) => {
                if (a.tablethreadstatus < b.tablethreadstatus) {
                    return -1;
                }
                if (a.tablethreadstatus > b.tablethreadstatus) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        } else {
            let sortedNames = dataReviewList.sort((a, b) => {
                if (a.tablethreadstatus < b.tablethreadstatus) {
                    return -1;
                }
                if (a.tablethreadstatus > b.tablethreadstatus) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        }
    };

    const sortThreadTitle = () => {
        if (isSortThreadTittle) {
            let sortedNames = dataReviewList.sort((b, a) => {
                if (a.tablethreadtitle < b.tablethreadtitle) {
                    return -1;
                }
                if (a.tablethreadtitle > b.tablethreadtitle) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        } else {
            let sortedNames = dataReviewList.sort((a, b) => {
                if (a.tablethreadtitle < b.tablethreadtitle) {
                    return -1;
                }
                if (a.tablethreadtitle > b.tablethreadtitle) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        }
    };

    const sortThreadIndex = () => {
        if (isSortThreadIndex) {
            let sortedNames = dataReviewList.sort((b, a) => {
                if (a.tablethreadindex < b.tablethreadindex) {
                    return -1;
                }
                if (a.tablethreadindex > b.tablethreadindex) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        } else {
            let sortedNames = dataReviewList.sort((a, b) => {
                if (a.tablethreadindex < b.tablethreadindex) {
                    return -1;
                }
                if (a.tablethreadindex > b.tablethreadindex) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        }
    };

    const sortTotalReviews = () => {
        if (isSortTotalReview) {
            let sortedNames = dataReviewList.sort((b, a) => {
                if (a.total_review < b.total_review) {
                    return -1;
                }
                if (a.total_review > b.total_review) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        } else {
            let sortedNames = dataReviewList.sort((a, b) => {
                if (a.total_review < b.total_review) {
                    return -1;
                }
                if (a.total_review > b.total_review) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        }
    };

    const sortAvgRating = () => {
        if (isSortAvgRating) {
            let sortedNames = dataReviewList.sort((b, a) => {
                if (a.avg_rating < b.avg_rating) {
                    return -1;
                }
                if (a.avg_rating > b.avg_rating) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        } else {
            let sortedNames = dataReviewList.sort((a, b) => {
                if (a.avg_rating < b.avg_rating) {
                    return -1;
                }
                if (a.avg_rating > b.avg_rating) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        }
    };

    const sortTotalDisbursement = () => {
        if (isSortTotalDisbursement) {
            let sortedNames = dataReviewList.sort((b, a) => {
                if (a.totaldisbursement < b.totaldisbursement) {
                    return -1;
                }
                if (a.totaldisbursement > b.totaldisbursement) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        } else {
            let sortedNames = dataReviewList.sort((a, b) => {
                if (a.totaldisbursement < b.totaldisbursement) {
                    return -1;
                }
                if (a.totaldisbursement > b.totaldisbursement) {
                    return 1;
                }
                return 0;
            });
            setDataReviewList([...sortedNames]);
        }
    };

    const toggleSort = (param) => {
        switch (param) {
            case "tablethreadstatus":
                setIsSortStatus(!isSortStatus);
                sortStatus();
                break;
            case "tablethreadtitle":
                setIsSortThreadTittle(!isSortThreadTittle);
                sortThreadTitle();
                break;
            case "tablethreadindex":
                setIsSortThreadIndex(!isSortThreadIndex);
                sortThreadIndex();
                break;
            case "total_review":
                setIsSortTotalReview(!isSortTotalReview);
                sortTotalReviews();
                break;
            case "avg_rating":
                setIsSortAvgRating(!isSortAvgRating);
                sortAvgRating();
                break;
            case "totaldisbursement":
                seIsSortTotalDisbursement(!isSortTotalDisbursement);
                sortTotalDisbursement();
                break;
            default:
                break;
        }
    };

    const detailHandle = (value) => {
        navigate('/developer/review/'+value);
    }

    return (
        <>
            <div
                className="w-full min-h-full bg-no-repeat bg-cover"
                style={{ backgroundImage: "url('/images/bg-2.png')" }}
            >
                <div className="w-full h-full">
                    <Sidebar />
                    <Transition
                        show={openVerificationModal}
                        enter="transition-opacity duration-705"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <VerificationModal cancel={() => closeModal()}/>
                    </Transition>

                    <div className="w-full h-full pl-[256px]">
                        <Transition
                            className="w-[100%] h-[100%] "
                            appear={true}
                            show={showTransition}
                            {...easeOutTransition}
                        >
                            <div className="flex flex-col w-full h-full">
                                <div className="w-full h-full max-w-[1500px] pl-6 m-auto flex flex-col">
                                    {userData ? (
                                        <Navbar
                                            heading="Reviews"
                                            paragraf="Monitoring your content's growth"
                                            profile={getUserProfile}
                                        />
                                    ) : null}
                                    
                                    <div className="flex flex-row items-center mt-5 mb-5 justify-between">
                                        <div className="relative">
                                            <input type="text" ref={inputSearchThreads} className="py-[5px] w-[320px] rounded-[8px] pl-5 pr-10" placeholder="Search Thread(s)" onChange={searchHandle}/>
                                            <div className="absolute right-3 top-2">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z" stroke="#5D5F61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="flex flex-row gap-3 items-center">
                                            <DatePicker
                                                showIcon
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update) => {
                                                    setDateRange(update);
                                                }}
                                                isClearable
                                                className="w-[240px] px-3"
                                                placeholderText="Filter By Date"
                                            />
                                            <button
                                                className="flex flex-row items-center bg-[#FF0000] py-[5px] px-[10px] text-white rounded-md"
                                                onClick={() => navigate("/developer/threads/new")}
                                            >
                                                New Thread
                                                <div className="pl-3">
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M10.0002 3.33325V16.6666M16.6668 9.99992L3.3335 9.99992"
                                                            stroke="white"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="w-full">
                                        <div className="">
                                            <div className="flex flex-row justify-between gap-3">
                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5 h-full">
                                                        <div className="flex-auto w-full h-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="h-full">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Total Review(s)
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {dataReviewThreads.totalReview} Review(s)
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5 h-full">
                                                        <div className="flex-auto w-full h-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="h-full">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Total AVG Rating
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {dataReviewThreads.avg_rating}
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-white w-[480px] rounded-3xl h-[172px] p-[24px]">
                                                    <div className="flex flex-row w-full gap-5 h-full">
                                                        <div className="flex-auto w-full h-full">
                                                            <div className="flex flex-col h-full">
                                                                <div className="h-full">
                                                                    <div className="flex flex-row">
                                                                        <label className="flex-auto text-[#707375]">
                                                                            Total Token Disbursement
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <h1 className="text-[36px] font-semibold">
                                                                        {dataReviewThreads.totalTokenDisbursment} ANOA
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full">
                                        <div className="mt-[41px]">
                                            <div className="mt-5">
                                                <div className="relative overflow-x-auto">
                                                    <table className="w-full text-sm text-left">
                                                        <thead className="text-xs text-[14px]">
                                                            <tr className="text-[#5D5F61] border-b-2 border-[#D7DCDF]">
                                                                <th scope="col" className="py-3">
                                                                    <button
                                                                        onClick={() => toggleSort("tablethreadstatus")}
                                                                        className="flex items-center"
                                                                    >
                                                                        <span className="text-[14px] pr-3">
                                                                            Status
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortStatus ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <button
                                                                        onClick={() => toggleSort("tablethreadtitle")}
                                                                        className="flex items-center"
                                                                    >
                                                                        <span className="text-[14px] pr-3">
                                                                            Thread Title
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortThreadTittle ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <button
                                                                        onClick={() => toggleSort("tablethreadindex")}
                                                                        className="flex items-center"
                                                                    >
                                                                        <span className="text-[14px] pr-3">
                                                                            Thread ID
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortThreadIndex ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <button
                                                                        onClick={() => toggleSort("total_review")}
                                                                        className="flex items-center"
                                                                    >
                                                                        <span className="text-[14px] pr-3">
                                                                            Total Review(s)
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortTotalReview ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <button
                                                                        onClick={() => toggleSort("avg_rating")} 
                                                                        className="flex items-center">
                                                                        <span
                                                                            className="text-[14px] pr-3"
                                                                        >
                                                                            AVG Rating
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortAvgRating ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                                <th scope="col" className="py-3">
                                                                    <button 
                                                                        onClick={() => toggleSort("totaldisbursement")} 
                                                                        className="flex items-center">
                                                                        <span
                                                                            className="text-[14px] pr-3"
                                                                        >
                                                                            Total Disbursement
                                                                        </span>
                                                                        <div className="flex flex-row items-center">
                                                                            <svg
                                                                                width="15"
                                                                                height="10"
                                                                                viewBox="0 0 15 10"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3.50008 4.99992H11.8334M1.41675 0.833252H13.9167M5.58341 9.16659H9.75008"
                                                                                    stroke="#0A0A0A"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            {isSortTotalDisbursement ?
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> :
                                                                            <svg width="20px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 6V18M12 18L7 13M12 18L17 13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg> }
                                                                        </div>
                                                                    </button>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dataReviewList.map((result, key) => (
                                                                <tr className="border-b-2 border-[#D7DCDF] cursor-pointer hover:bg-slate-400" key={key} onClick={() => detailHandle(result.tablethreadindex)}>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreadstatus == 'PENDING' ?
                                                                            <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{result.tablethreadstatus}</span>
                                                                        : '' }

                                                                        {result.tablethreadstatus === 'CANCELED' ?
                                                                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{result.tablethreadstatus}</span>
                                                                        : '' }

                                                                        {result.tablethreadstatus === 'ACTIVE' ?
                                                                            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{result.tablethreadstatus}</span>
                                                                        : '' }

                                                                        {result.tablethreadstatus === 'ENDED' ?
                                                                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{result.tablethreadstatus}</span>
                                                                        : '' }
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.tablethreadtitle}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        <Link to={`/developer/threads/`+result.tablethreadindex} className="underline text-blue-600">{result.tablethreadindex}</Link>
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.total_review} Reviews
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.avg_rating !== null ? result.avg_rating : '-'}
                                                                    </td>
                                                                    <td className="py-4 font-semibold">
                                                                        {result.totaldisbursement} Anoa
                                                                    </td>
                                                                </tr> 
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Review;
