import { Suspense, useEffect, useRef, useState} from "react";
import { SuspenseImg } from "../../helpers/ImageCache";

import BarLoader from "../../components/loader/BarLoader";
import Signature from "../../helpers/Signature";
import axios from "axios";
import Swal from "sweetalert2";

const ForgotPasswordGamer = () => {
    const emailInput = useRef();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature
        }

        let body = {
            'emailusername' : emailInput.current.value
        }

        try {
            let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/forgotpassword',body, {
                headers: dataHeaders
            });

           if(response.data.status.status === 1){
                setSuccess(true)
                // Swal.fire({
                //     title : 'Success',
                //     text: 'We’ve sent an reset password instruction to your email',
                //     icon: 'success',
                // });

                emailInput.current.value = '';
           }else{
                setError(response.data.status.message[0].errormessage)
                // Swal.fire({
                //     title : 'Error!',
                //     text: response.data.status.message[0].errormessage,
                //     icon: 'error',
                // });
           }
        } catch (err) {
            console.log(err)
        };
       
    }

    useEffect(() => {
        if(success){
            setInterval(() => {
                setSuccess(false);
            }, 5000);
        }
    }, [success]);
    return (
        <>
            {success ?
            <>
            <div className="bg-bg1 backdrop-blur-[3px] fixed z-10 inset-0"></div>
            <div className="fixed inset-0 z-20">
                <div className="relative w-full h-full ">
                    <div className="absolute translate-y-[-50%] top-[50%] w-full">
                        <div className="max-w-[725px] m-auto">
                            <div className="mb-[-100px] w-full">
                                <img src={process.env.PUBLIC_URL + '/images/component-4.png'} alt="element" aria-hidden className="block ml-auto" />
                            </div>
                            <div className="bg-[#000032] flex flex-col gap-6 rounded-rd1 px-[40px] py-[60px]">
                                <h3 className="text-white text-[48px]">Reset Password!</h3>
                                <p className="text-white text-[20px]">We’ve sent an reset password instruction to your email</p>

                                <p className="text-[#989B9D]">Please check your email and follow the instruction to reset your account password</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div></> : '' }
            <div className="w-full h-full overflow-hidden">
                <Suspense fallback={<BarLoader />}>
                    <SuspenseImg alt="element" src={process.env.PUBLIC_URL + '/images/bg-1.png'} className="flex items-center justify-center w-full h-full bg-no-repeat bg-cover" />
                    <div className="absolute top-0 left-0 w-full h-screen px-6 m-auto overflow-hidden">
                        <div className="relative float-right w-full h-full max-w-6xl">
                            <div className="absolute translate-y-[-50%] top-[50%] w-full">
                                <img src={process.env.PUBLIC_URL + '/images/component-2.png'} alt="element" className="float-left animate-wiggle-slow" />
                                <img src={process.env.PUBLIC_URL + '/images/component-3.png'} alt="element" className="float-right mt-[-450px] animate-wiggle-slow " />
                            </div>
                        </div>

                        <div className="w-full h-full relative max-w-[550px] ">
                            <div className="absolute translate-y-[-50%] max-h-[100vh] overflow-auto top-[50%] w-full">
                                <h3 className="text-white text-[36px]">Forgot Password?</h3>
                                <p className="text-[#989B9D]">Please enter your registered email and we’ll send you an reset password instruction.</p>

                                <div className="mt-10">
                                    <form onSubmit={(e) => handleForgotPassword(e)}>
                                        <div className="flex flex-col gap-5 mb-3">
                                            <div className="flex-auto">
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">Email</label>
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" stroke="#5D5F61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </div>
                                                    <input type="text" ref={emailInput} className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-10 p-2.5" placeholder="anoa@anoa.id" />
                                                </div>
                                                
                                                {error !== '' ?
                                                <span className="flex flex-row items-center w-full p-1 text-white rounded bg-bg2">
                                                    <div className="pr-2">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.0003 7.5V9.16667M10.0003 12.5H10.0086M4.22677 15.8333H15.7738C17.0568 15.8333 17.8587 14.4444 17.2172 13.3333L11.4436 3.33333C10.8021 2.22222 9.1984 2.22222 8.5569 3.33333L2.78339 13.3333C2.14189 14.4444 2.94377 15.8333 4.22677 15.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    {error}
                                                </span>
                                                : '' }
                                            </div>
                                        </div>
                                        <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px] m-auto block">Forgot Password</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </div>
        </>
    );
}

export default ForgotPasswordGamer;