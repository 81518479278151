import { useRef, useCallback, useEffect } from 'react';
import './main_css/MainPage.css';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Signature from "../../helpers/Signature";
import io from 'socket.io-client';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MainHeader = (props) => {
    console.log('props_header', props)
    const navigate = useNavigate();
    const isHome = typeof (props.menu) === 'undefined' ? false : props.menu[0].selected ? false : true;
    const inputSearch = useRef();
    const [modalNotif, setModalNotif] = useState(false);
    const [jumlahNotif, setJumlahNotif] = useState(0);
    const [notifikasiList, setNotifikasiList] = useState([]);
    const [configSocket, setConfigSocket] = useState(null);

    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [userData, setUserData] = useState('');
    const [notifButton, setNotifButton] = useState(false);

    const fetchProfileAPI = useCallback(async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();
        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/profile?id='+currentTimestamp, {
            headers: dataHeaders
        });
        if (response.data.status.status === 1) {
            setUserData(response.data.data)
        }
        else {
            navigate('/logout');
        }
    }, []);

    const _handleSearch = (e) => {

        if (e.keyCode === 13) {
            console.log('You pressed Enter ', e.target.value)
            const value = e.target.value;
            props.onChangeSearch(value);
        }
    }
    const navigateToProfile = () => {
        navigate('/gamers/gamersProfile')
    }
    const navigateToHome = () => {
        navigate('/')
    }
    const navigateToDashboard = () => {
        navigate('/developer/home')
    }

    const newNotif = useCallback(async () => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
        let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
            CryptoJS.enc.Utf8
        );

        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: resultUUID.user.userindex,
            tokenlogin: resultUUID.user.usertokenlogin,
            signature: signature,
        };

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(
            process.env.REACT_APP_HOSTNAMEAPI + "/api/user/v01/newnotif?id="+currentTimestamp,
            {
                headers: dataHeaders,
            }
        );

        if(response.data.status.status === 1){
            console.log('jumlah notif > '+response.data.data.total_notif)
            setJumlahNotif(response.data.data.total_notif);
        }
    }, []); 

    const loadNotification = useCallback(async (unread) => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
        let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
            CryptoJS.enc.Utf8
        );

        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: resultUUID.user.userindex,
            tokenlogin: resultUUID.user.usertokenlogin,
            signature: signature,
        };

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(
            process.env.REACT_APP_HOSTNAMEAPI + "/api/user/v01/notification?unread="+unread+"&id="+currentTimestamp,
            {
                headers: dataHeaders,
            }
        );

        if(response.data.status.status === 1){
            console.log('asdasdasd natnoasnasdkasdni')
            setNotifikasiList(response.data.data);
        }
    }, []);

    useEffect(() => {
        const socketio = io('https://back.thespace.world');
        setConfigSocket(socketio);
    }, []);

    useEffect(() => {
        newNotif();
        loadNotification('false');
    }, [newNotif]);

    useEffect(() => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");

        if(getUUID){
            let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
                CryptoJS.enc.Utf8
            );
    
            let resultUUID = JSON.parse(atob(decrypt));
    
            if(configSocket !== null){
                configSocket.on(resultUUID.user.userindex, (data) => {
                    newNotif();
                    props.profile();
                })
            }
        }
        
    }, [configSocket, newNotif, loadNotification, props]);

    const readNotifHandle = async (result) => {
        if(result.tablenotificationtitle === 'KYC Rejected!'){
            let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
            let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
                CryptoJS.enc.Utf8
            );
    
            let resultUUID = JSON.parse(atob(decrypt));
            let payloadSignature = [];
            let signature = Signature(payloadSignature);
    
            let dataHeaders = {
                platform: "WEBSITE",
                gtoken: "PASSS",
                userindex: resultUUID.user.userindex,
                tokenlogin: resultUUID.user.usertokenlogin,
                signature: signature,
            };
    
            let body = {
                notificationindex : result.tablenotificationindex
            }
    
            const currentTimestamp = new Date().getTime();
    
            let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + "/api/user/v01/readnotif?id="+currentTimestamp,body,{
                headers: dataHeaders,
            });
    
            if(response.data.status.status === 1){
                newNotif();
                loadNotification();
                setModalNotif(false)
                navigate('/'+result.tablenotificationlink, {
                    state: {
                        isAuthenticated: isAuthenticated,
                        userData: userData
                    }
        
                });
            }

            props.activeKyc()
        }else{
            let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
            let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
                CryptoJS.enc.Utf8
            );

            let resultUUID = JSON.parse(atob(decrypt));
            let payloadSignature = [];
            let signature = Signature(payloadSignature);

            let dataHeaders = {
                platform: "WEBSITE",
                gtoken: "PASSS",
                userindex: resultUUID.user.userindex,
                tokenlogin: resultUUID.user.usertokenlogin,
                signature: signature,
            };

            let body = {
                notificationindex : result.tablenotificationindex
            }

            const currentTimestamp = new Date().getTime();

            let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + "/api/user/v01/readnotif?id="+currentTimestamp,body,{
                headers: dataHeaders,
            });

            if(response.data.status.status === 1){
                newNotif();
                loadNotification();
                setModalNotif(false)
                navigate('/'+result.tablenotificationlink, {
                    state: {
                        isAuthenticated: isAuthenticated,
                        userData: userData
                    }
        
                });
            }
        }
    }

    const clickNotif = () => {
        let userRole = localStorage.getItem('userRole');

        if (userRole !== null) {
            setIsAuthenticated(userRole)
            fetchProfileAPI();
        }

        setModalNotif(true);
        loadNotification();
        setTimeout(() => {
            newNotif();
        }, 2000);
    }

    const handleButtonNotif = (val) => {
        setNotifButton(val);
        loadNotification(val);
    }

    return (
        <>
        <div className={props.isAuthenticated !== null ? "headerContainer justify-between" : "headerContainer "}>
            <div className='  hover:cursor-pointer sm:w-[30%]  lg:w-[10vw]' onClick={() => navigateToHome()}>

                <img src={process.env.PUBLIC_URL + '/images/main/SpaceLogo.png'} alt="element" className="w-full h-full " />

            </div>
            {/* <div className='headerMenuContainer'>
                {
                    isHome ? <input type={"text"} ref={inputSearch} className="mainInput" placeholder='Search' onKeyDown={(e) => _handleSearch(e)}></input> : null
                }
            </div> */}
            {
                props.isAuthenticated !== null ?
                    <Menu as="div" className="relative inline-block ml-3 ">
                        <div className='flex flex-row items-center sm:gap-0 lg:gap-3'>
                            <div className="relative">
                                <div className="cursor-pointer" onClick={clickNotif}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17H4L5.40493 15.5951C5.78595 15.2141 6 14.6973 6 14.1584V11C6 8.38757 7.66962 6.16509 10 5.34142V5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5V5.34142C16.3304 6.16509 18 8.38757 18 11V14.1584C18 14.6973 18.2141 15.2141 18.5951 15.5951L20 17H15M9 17V18C9 19.6569 10.3431 21 12 21C13.6569 21 15 19.6569 15 18V17M9 17H15" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                {jumlahNotif > 0 ?
                                <span class="bg-red-100 text-red-800 text-sm font-medium rounded dark:bg-red-900 dark:text-red-300 absolute top-0 right-0 z-10">{jumlahNotif}</span> : '' }
                            </div>

                            <div className='w-[40px] h-[50px]   sm:h-10 flex justify-center items-center'>
                                {
                                    props.userData.userphotopictfullpath !== null ?
                                        <img src={props.userData.userphotopictfullpath} alt="element" className=' w-[100%] h-[100%] object-cover rounded-[50%]' />
                                        : <img src={process.env.PUBLIC_URL+'/images/profil.png'} alt="userPhotoTemp" className='object-contain w-[100%] h-[100%] rounded-[50%]' />
                                }
                            </div>
                            <div className='flex flex-row items-center ml-3 cursor-pointer lg:gap-5 sm:gap-3' >
                                <div className='flex flex-col w-[250px]'>
                                    <label className='text-[#FFFF] text-lg break-words'>{props.userData.userfullname}</label>
                                    {
                                        props.isAuthenticated === "Developer" ?
                                            null
                                            :
                                            <label className='text-[#989B9D]'>{props.userData.useremail}</label>
                                    }
                                </div>

                                <Menu.Button className="inline-flex  justify-center min-w-[20%]  sm:min-w-[25%] rounded-md text-sm font-semibold text-gray-900 shadow-sm  ">
                                    <img src={process.env.PUBLIC_URL + '/images/main/downArrow.svg'} alt="userPhotoTemp" className='object-contain w-[40%] h-[40%] ' />

                                </Menu.Button>
                            </div>

                        </div>


                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            {
                                props.isAuthenticated === "Developer" ?
                                    <Menu.Items className="absolute right-0 z-10 mt-4 min-w-[600px] shadow-xl px-3 origin-top-right divide-y divide-[#21217A] border-[#1100D0] rounded-xl  border-[1px] bg-[#03002C] ring-1 ring-[#ababb0] ring-opacity-5 focus:outline-none">
                                        <div className="py-1">

                                            <Menu.Item>
                                                {/* {({ active }) => ( */}
                                                <div
                                                    className={classNames(
                                                        'text-[#FFFF] font-semibold',
                                                        'flex flex-row px-4 py-3 gap-3 text-base items-center w-[100%] justify-between'
                                                    )}>
                                                    <div className='flex flex-row gap-3'>
                                                        <div className='w-[50px] h-[50px] bg-blue-400 rounded-[50%]'>
                                                            {
                                                                props.userData.userphotopict !== null ?
                                                                    <img src={"https://back.thespace.world/public/" + props.userData.userphotopict} alt="element" className=' w-[100%] h-[100%] object-cover rounded-[50%]' />
                                                                    : <img src={process.env.PUBLIC_URL + '/images/main/userPhotoTemp.svg'} alt="userPhotoTemp" className='w-[100%] h-[100%] object-cover rounded-[50%] ' />
                                                            }
                                                        </div>
                                                        <div className='flex flex-col text-[#FFFF]items-start gap-2'>
                                                            <div className='flex flex-wrap justify-between w-full '>
                                                                <label className='text-[#FFFF] text-lg'>{props.userData.userfullname}</label>

                                                            </div>

                                                            <label className='text-[#989B9D]'>{props.userData.useremail}</label>
                                                        </div>

                                                    </div>

                                                    <div className='flex items-center justify-center px-3 py-1 bg-bm1 rounded-xl'>
                                                        <label className='text-sm'> Developer</label>
                                                    </div>
                                                </div>
                                                {/* )} */}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <div className='flex flex-col gap-2 px-3 py-3 '>
                                                        <label className='text-[#FFFF] text-2xl font-semibold'>
                                                            Space Developer
                                                        </label>
                                                        <label className='text-[#FFFF] text-sm '>
                                                            You can monitor and create your thread from developer space dashbord, click button below here to access your dashboard
                                                        </label>
                                                        <button
                                                            onClick={() => navigateToDashboard()}
                                                            className={'bg-bm1 justify-center no-underline rounded-lg mt-3 text-[#FFFF] font-semibold flex flex-row px-4 py-3 gap-3 text-base items-center w-[100%]'}
                                                        >
                                                            Developer dashboard
                                                        </button>
                                                    </div>

                                                )}
                                            </Menu.Item>

                                        </div>


                                    </Menu.Items>
                                    :
                                    <Menu.Items className="absolute right-0 z-10 mt-4 w-[100%] origin-top-right divide-y divide-[#21217A] border-[#1100D0] rounded-lg  border-[1px] bg-[#03002C] shadow-lg ring-1 ring-[#ababb0] ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => navigateToProfile()}
                                                        className={classNames(
                                                            active ? 'bg-[#1100D0] hover:text-[#FFFF] no-underline' : 'text-[#FFFF] font-semibold',
                                                            'flex flex-row px-4 py-3 gap-3 text-base items-center w-[100%]'
                                                        )}
                                                    >
                                                        <img src={process.env.PUBLIC_URL + '/images/main/myprofile.svg'} alt="userPhotoTemp" className='object-contain w-[6%] ' />

                                                        My Account
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => navigate('/gamers/wallet')} 
                                                        className={classNames(
                                                            active ? 'bg-[#1100D0] hover:text-[#FFFF] no-underline' : 'text-[#FFFF] font-semibold',
                                                            'flex flex-row px-4 py-3 gap-3 text-base items-center w-[100%]'
                                                        )}
                                                    >
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4 12.3105V8.64616C4 7.69198 4.58242 6.84201 5.46803 6.50524L11.8029 4.09975C12.7922 3.72289 13.8533 4.4606 13.8533 5.52703V8.57398M7.59028 11.9819H13.1752M16.3984 13.2245C16.0634 13.5533 15.8719 14.0264 15.9197 14.5315C15.9915 15.3975 16.7814 16.0309 17.6431 16.0309H19.159V16.9851C19.159 18.6449 17.8106 20 16.1591 20H6.99988C5.34835 20 4 18.6449 4 16.9851V11.5888C4 9.92903 5.34835 8.57393 6.99988 8.57393H16.1591C17.8106 8.57393 19.159 9.92903 19.159 11.5888V12.7435H17.5473C17.1005 12.7435 16.6936 12.9198 16.3984 13.2245ZM20 13.5619V15.2137C20 15.6547 19.6489 16.0155 19.2022 16.0315H17.6384C16.7767 16.0315 15.9869 15.3981 15.9151 14.5321C15.8672 14.0269 16.0587 13.5539 16.3938 13.2251C16.689 12.9204 17.0959 12.744 17.5426 12.744H19.2022C19.6489 12.7601 20 13.1209 20 13.5619Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>

                                                        My Wallet
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => navigate('/gamer/'+props.userData.userindex)}
                                                        className={classNames(
                                                            active ? 'bg-[#1100D0] hover:text-[#FFFF] no-underline' : 'text-[#FFFF] font-semibold',
                                                            'flex flex-row px-4 py-3 gap-3 text-base items-center w-[100%]'
                                                        )}
                                                    >
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9 12H15M9 16H15M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12.5858C12.851 3 13.1054 3.10536 13.2929 3.29289L18.7071 8.70711C18.8946 8.89464 19 9.149 19 9.41421V19C19 20.1046 18.1046 21 17 21Z" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>

                                                        My Page
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="/logout"
                                                        className={classNames(
                                                            active ? 'bg-[#1100D0] hover:text-[#FFFF] no-underline' : 'text-[#FFFF] font-semibold no-underline',
                                                            'flex flex-row px-4 py-3 gap-3 text-base items-center'
                                                        )}
                                                    >
                                                        <img src={process.env.PUBLIC_URL + '/images/main/logout.svg'} alt="userPhotoTemp" className='object-contain w-[7%] ' />

                                                        logout
                                                    </a>
                                                )}
                                            </Menu.Item>

                                        </div>


                                    </Menu.Items>
                            }

                        </Transition>
                    </Menu>


                    : null
            }

        </div>
        {/* {modalNotif ? <>
        <div className="bg-bg1  backdrop-blur-[3px] fixed z-10 inset-0"></div>
        <div className="fixed z-20 inset-0">
            <div className=" w-full h-full relative">
                <div className="absolute translate-y-[-50%] top-[50%] w-full" >
                    <div className="max-w-[600px] max-h-[50%] m-auto text-white">
                        <div className="bg-[#000032] border-[#49454F] border-[1px] overflow-y-scroll flex flex-col gap-3 rounded-lg px-[48px] py-[48px]">
                            <div className="flex flex-col text-[#FFFF] min-h-[400px]">
                                <div className="flex flex-row justify-between items-center">
                                    <h1>NOTIFICATION(S)</h1>
                                    <div className="cursor-pointer" onClick={() => setModalNotif(false)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 18L18 6M6 6L18 18" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                
                                <div className="max-h-[400px] overflow-auto">
                                {notifikasiList.map((result, key) => (
                                    <div className="border-y-2 py-5 cursor-pointer mb-1 mt-5" onClick={() => readNotifHandle(result)} key={key}>
                                        <div className="flex flex-row items-center justify-between">
                                            <div className="flex flex-row items-center gap-4">
                                                {result.tablenotificationpict !== null ? result.tablenotificationpict !== '' ?
                                                <img src={`https://back.thespace.world/public/${result.tablenotificationpict}`} alt="picnotif" className="w-[64px] h-[64px] rounded-full"/> : '' : '' }
                                                <div>
                                                    <h1 className="font-bold">{result.tablenotificationtitle}</h1>
                                                    <p>{result.tablenotificationmessage}</p>
                                                    <p className="text-blue-500">
                                                        {result.tablenotificationtimestamp}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                {result.tablenotificationisread !== 1 ?
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="6" fill="#6895FD"/>
                                                </svg> : '' }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </> : '' } */}
        {modalNotif ? 
        <div className="fixed top-0 right-0 w-[414px] bg-[#1B2026] h-screen z-30">
            <div className="p-[16px] flex flex-row items-center justify-between">
                <h1 className="font-semibold text-[20px] text-white">Notification</h1>
                <button onClick={() => setModalNotif(false)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6M6 6L18 18" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className="p-[12px] flex flex-row items-center gap-2">
                <button className="font-semibold w-[94.5px] py-[5px] rounded-[24px]" style={{backgroundColor: notifButton === false ? '#E0E0F3' : '#E0E0F3', color: notifButton === false ? '#4C4DDC' : '#989B9D' }} onClick={() => handleButtonNotif(false)}>All</button>
                <button className="font-semibold w-[94.5px] py-[5px] rounded-[24px]" style={{backgroundColor: notifButton === true ? '#E0E0F3' : '#E0E0F3', color: notifButton === true ? '#4C4DDC' : '#989B9D' }} onClick={() => handleButtonNotif(true)}>Unread</button>
            </div>
            <div className="p-[12px]">
                <div className="flex flex-col gap-3 overflow-auto max-h-[700px]">
                        {notifikasiList.map((result, key) => (
                        <div className="flex flex-row justify-between cursor-pointer" key={key} onClick={() => readNotifHandle(result)}>
                            <div className="flex flex-row gap-3">
                                <div className="w-[40px]">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="40" height="40" rx="20" fill="#F0F3FF"/>
                                        <path d="M23 25H28L26.5951 23.5951C26.2141 23.2141 26 22.6973 26 22.1585V19C26 16.3876 24.3304 14.1651 22 13.3414V13C22 11.8954 21.1046 11 20 11C18.8954 11 18 11.8954 18 13V13.3414C15.6696 14.1651 14 16.3876 14 19V22.1585C14 22.6973 13.7859 23.2141 13.4049 23.5951L12 25H17M23 25V26C23 27.6569 21.6569 29 20 29C18.3431 29 17 27.6569 17 26V25M23 25H17" stroke="#3267E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div className="leading-5">
                                    <p className="font-semibold text-white">{result.tablenotificationtitle}</p>
                                    <span className="text-[#D7DCDF] text-[14px] block line-clamp-2 pr-3">{result.tablenotificationmessage}</span>
                                    <span className="text-[#6895FD] text-[12px]">{result.tablenotificationtimestamp}</span>
                                </div>
                            </div>
                            {result.tablenotificationisread !== 1 ?
                            <div className="w-[12px]">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="6" cy="6" r="6" fill="#6895FD"/>
                                </svg>
                            </div>: null }
                        </div>
                        ))}
                    </div>
            </div>
        </div>
        :''}
        </>
    )

}
export default MainHeader;