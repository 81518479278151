import { useNavigate } from "react-router-dom"

export default function PageNotFound() {
    const navigate = useNavigate();
    return (
        <div className="w-full h-full bg-[#0A0B27] ">
            <div className="flex flex-col items-center justify-center w-full h-full">
                <img src={process.env.PUBLIC_URL + '/images/notfound.svg'} alt="element" className='object-contain h-[50%] w-[50%]' />
                <span className="text-3xl sm:text-5xl text-[#FFFF] font-semibold text-center" >
                    Look like you’re lost in space
                </span>
                <button onClick={()=>navigate('/')} className="mt-6 bg-bm2 text-[#FFFF] px-12 py-3 rounded-lg "> Go Back</button>
            </div>
        </div>
    )
}