import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Signature from "../../helpers/Signature";
import axios from "axios";
import Swal from "sweetalert2";

const Sidebar = () => {
    const [dropdownThreads, setDropdownThreads] = useState(false);
    const [KYCStatus, setKYCStatus] = useState("");
    const navigate = useNavigate();

    const dropdownThreadsHandle = () => {
        if(dropdownThreads === false){
            setDropdownThreads(true);
        }else{
            setDropdownThreads(false);
        }
    }

    const getUserProfile = useCallback(async () => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
        console.log("getU", getUUID);
        let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
            CryptoJS.enc.Utf8
        );
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: resultUUID.user.userindex,
            tokenlogin: resultUUID.user.usertokenlogin,
            signature: signature
        };

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(
            process.env.REACT_APP_HOSTNAMEAPI + "/api/user/v01/profile?id="+currentTimestamp,
            {
                headers: dataHeaders,
            }
        );

        try {
            console.log("masuk try", response);

            if (response.data.status.status === 1) {
                setKYCStatus(response.data.data.userstatus);
                localStorage.setItem("profileData", JSON.stringify(response.data.data));
            }
        } catch (err) {
            console.log("err_ProfileAPI");
        }
    }, [navigate]);

    useEffect(() => {
        getUserProfile();
    }, [getUserProfile])
    return(
        <>
            <div className="fixed bottom-4 top-4">
                <div className="w-[256px] max-w-[256px] h-full rounded-3xl relative ">
                    <img alt="element" src={process.env.PUBLIC_URL+'/images/sidebar.png'} className="block w-full h-full"/>
                    <div className="absolute inset-0 p-10 flex flex-col">
                        <div className="w-full mb-14">
                            <img alt="element" src={process.env.PUBLIC_URL+'/images/LogoTheSpace.png'} className="block m-auto"/>
                        </div>
                        <div className="w-full h-full overflow-x-auto">
                            <ul className="py-10">
                                <li className="mb-[48px]">
                                    <Link to="/developer/home" className="text-white flex flex-row">
                                        <div className="pr-5">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 6C4 4.89543 4.89543 4 6 4H8C9.10457 4 10 4.89543 10 6V8C10 9.10457 9.10457 10 8 10H6C4.89543 10 4 9.10457 4 8V6Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14 6C14 4.89543 14.8954 4 16 4H18C19.1046 4 20 4.89543 20 6V8C20 9.10457 19.1046 10 18 10H16C14.8954 10 14 9.10457 14 8V6Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M4 16C4 14.8954 4.89543 14 6 14H8C9.10457 14 10 14.8954 10 16V18C10 19.1046 9.10457 20 8 20H6C4.89543 20 4 19.1046 4 18V16Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14 16C14 14.8954 14.8954 14 16 14H18C19.1046 14 20 14.8954 20 16V18C20 19.1046 19.1046 20 18 20H16C14.8954 20 14 19.1046 14 18V16Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="mb-[48px]">
                                    <button className="text-[#BABEC1] w-full font-medium text-sm py-2.5 flex flex-row items-center" type="button" onClick={dropdownThreadsHandle}>
                                        <div className="pr-5">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 12H19M5 12C3.89543 12 3 11.1046 3 10V6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V10C21 11.1046 20.1046 12 19 12M5 12C3.89543 12 3 12.8954 3 14V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V14C21 12.8954 20.1046 12 19 12M17 8H17.01M17 16H17.01" stroke="#BABEC1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                        <label className="flex-auto text-start cursor-pointer">Threads</label>
                                        
                                        <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </button>
                                    <div className={dropdownThreads ? 'z-10 block absolute bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700' : 'z-10 hidden absolute bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700'}>
                                        <ul className="py-1 text-sm text-gray-700 dark:text-gray-200">
                                            <li>
                                                <button className="block px-4 py-2 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => {
                                                    KYCStatus === "INACTIVE" || KYCStatus === "REJECTED" || KYCStatus === "NOT ACTIVE" ? 
                                                    Swal.fire({
                                                        icon: "error",
                                                        title: "Unable to Create Thread",
                                                        text: "Please activate your account to enjoy all the features.",
                                                    }) :
                                                    navigate("/developer/threads/new")
                                                }}>New Thread</button>
                                            </li>
                                            <li>
                                                <Link to="/developer/threads" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Thread List</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <Link to={'/developer/review'} className="text-[#BABEC1] flex flex-row">
                                        <div className="pr-5">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 20H5C3.89543 20 3 19.1046 3 18L3 6C3 4.89543 3.89543 4 5 4L15 4C16.1046 4 17 4.89543 17 6V7M19 20C17.8954 20 17 19.1046 17 18L17 7M19 20C20.1046 20 21 19.1046 21 18V9C21 7.89543 20.1046 7 19 7L17 7M13 4L9 4M7 16H13M7 8H13V12H7V8Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        Reviews
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;