import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../../../../components/layouts/Navbar";
import Sidebar from "../../../../../components/layouts/Sidebar";
import { ThreadInfo } from "./ThreadInfo";
import { fetchDetailThread, fetchGetthreadAPI, fetchWalletBallanceAPI } from "../controller/ThreadsDetailController";
import { ThreadStatistic } from "./ThreadStatistic";
import { useRef } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ThreadTransaction } from "./ThreadTransaction";

const ThreadsDetail = () => {
    const [detailThreads, setDetailThreads] = useState({});
    const [getThreadData, setGetThreadData] = useState({});
    const [imageArtWork, setImageArtWork] = useState([]);
    const [video, setVideo] = useState([]);
    const { id } = useParams();
    const [infoMenu, setInfoMenu] = useState('info');
    const [successLoad, setSuccessLoad] = useState(false);
    const [history, setHistory] = useState([]);
    const [statistic, setStatistic] = useState([]);
    const inputStartDate = useRef();
    const inputEndDate = useRef();
    const [periodDateStart, setPeriodDateStart] = useState(convert(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)));
    const [periodDateEnd, setPeriodDateEnd] = useState(convert(new Date(Date.now())));
    const [errorDate, setErrorDate] = useState(false);
    const [errorDateMsg, setErrorDateMsg] = useState('');
    const [remounting, setRemounting] = useState(false);
    const [walletData, setWalletData] = useState({});
    const [media, setMedia] = useState([])

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dataOverall, setDataOverall] = useState({});

    const fetchAPI = useCallback(async () => {
        const res_getthread = await fetchGetthreadAPI(id);
        await getDetailThread(id);
        setWalletData(await fetchWalletBallanceAPI())
        setGetThreadData(res_getthread.data.data);
        let imageData = res_getthread.data.data.tablethreadpictpath;
        let videoData = res_getthread.data.data.tablethreadvideopath;
        let resultImage = [];
        let resultVideo = [];
        for (const data in imageData) {
            resultImage.push(imageData[data])
        }
        resultImage.forEach((element, index) => {
            setImageArtWork((imageArtWork) => [...imageArtWork, { "result": "https://back.thespace.world/public/" + element, "id": index }])
        });
        for (const data in videoData) {
            resultVideo.push(videoData[data])
        }
        resultVideo.forEach((element, index) => {
            setVideo((video) => [...video, { "result": "https://back.thespace.world/public/" + element, "id": index }])
        });
        setSuccessLoad(true)
        // eslint-disable-next-line
    }, [id, remounting]);
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    const getDetailThread = async (id) => {
        const res_detailthread = await fetchDetailThread(id, periodDateStart, periodDateEnd);
        setDetailThreads(res_detailthread.data.data[0]);
        setDataOverall(res_detailthread.data.support.overallThread)
        setMedia(prev => [...prev,...res_detailthread.data.data[0].tablethreadimage,...res_detailthread.data.data[0].tablethreadvideo ])
        setHistory(res_detailthread.data.support.history);
        setStatistic(res_detailthread.data.support);

        console.log(res_detailthread.data)
    }

    const dateOnChange = async (dates) => {
        const [start, end] = dates;
        // const start = inputStartDate.current.value;
        // const end = inputEndDate.current.value;
        setStartDate(start);
        setEndDate(end);
        const x = new Date(start);
        const y = new Date(end);
        const calc = (y.getTime() - x.getTime()) / (1000 * 3600 * 24)

        const myArray = dates;
        const allNullValues = myArray.every(value => value === null);

        if (x > y) {
            setErrorDate(true);
            setErrorDateMsg('Date Invalid!')
        }
        else {
            if (calc < 6 || calc === 6) {
                if(allNullValues !== true){
                    setErrorDate(false);
                    setErrorDateMsg('')
                    const res_detailthread = await fetchDetailThread(id, start, end);
                    setHistory(res_detailthread.data.support.history);
                    setStatistic(res_detailthread.data.support);
                    renderStatistic(x, y);
                    setPeriodDateStart(x)
                    setPeriodDateEnd(y)
                    setRemounting(prev => !prev);
                }else{
                    setErrorDate(false);
                    setErrorDateMsg('')
                    const res_detailthread = await fetchDetailThread(id, periodDateStart, periodDateEnd);
                    setHistory(res_detailthread.data.support.history);
                    setStatistic(res_detailthread.data.support);
                    renderStatistic((new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)), convert(new Date(Date.now())));
                    setPeriodDateStart(convert(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)))
                    setPeriodDateEnd(convert(new Date(Date.now())))
                    setRemounting(prev => !prev);
                }
               
            }
            else {
                setErrorDate(true);
                setErrorDateMsg('Maximum range is 7 days period')
            }
        }
        
    }
    const renderStatistic = (x, y) => {

        const dateStart = typeof(x) !== 'undefined' ? x : periodDateStart;
        const dateEnd = typeof(y) !== 'undefined' ? x : periodDateEnd;
        console.log(x,y)
        return (
            <ThreadStatistic show={infoMenu} overal={dataOverall} statistic={statistic} periodDateStart={dateStart} periodDateEnd={dateEnd}></ThreadStatistic>
        )
    }
    const doRemounting = () => {
        setRemounting(prev => !prev);
    }

    useEffect(() => {
        fetchAPI();
    }, [fetchAPI]);
console.log('media', media)
    return (
        <>
            <div className="w-full min-h-full bg-no-repeat bg-cover" style={{ backgroundImage: "url('/images/bg-2.png')" }}>
                <div className="w-full h-full">
                    <Sidebar />

                    <div className="w-full h-full pl-[256px]">
                        <div className="flex flex-col w-full h-full pl-3">
                            <div className="w-full h-full max-w-[1500px] m-auto flex flex-col">
                                <Navbar heading="Detail Threads" paragraf="Your threads details" />
                                <div className="inline-flex justify-between w-full items-center">
                                    <div className="inline-flex w-1/4 mt-5 rounded-md border-[2px] border-gray-300 h-12">
                                        <button onClick={() => setInfoMenu('info')} className={infoMenu === 'info' ? "w-1/2 rounded-l-md border-r-2 bg-[#FFFF] border-gray-300" : "w-1/2 border-r-2 border-gray-300 bg-transparent"}>
                                            <span className={infoMenu ? "text-base font-semibold" : "text-base font-semibold text-gray-500"}>Thread Info</span>
                                        </button>
                                        <button onClick={() => setInfoMenu('statistic')} className={infoMenu === 'statistic' ? "w-1/2 border-r-2 bg-[#FFFF] border-gray-300" : "w-1/2 border-r-2 border-gray-300 bg-transparent"}>
                                            <span className={!infoMenu ? "text-base font-semibold" : "text-base font-semibold text-gray-500"}>Statistic</span>
                                        </button>
                                        <button onClick={() => setInfoMenu('transaction')} className={infoMenu === 'transaction' ? "w-1/2 rounded-r-md bg-[#FFFF]" : "w-1/2 border-r-2 bg-transparent"}>
                                            <span className={!infoMenu ? "text-base font-semibold" : "text-base font-semibold text-gray-500"}>Transaction</span>
                                        </button>
                                    </div>
                                    <div className="block">
                                        <div className={!infoMenu === 'statistic' ? "flex flex-row gap-10 mt-3 mb-1" : "hidden"}>
                                            {/* <div className="flex-auto">
                                                <label htmlFor="startdate" className="block font-bold">Start Period</label>
                                                <input type="date" ref={inputStartDate} onChange={() => dateOnChange()} className="bg-gray-50 border border-gray-300 focus:outline-none text-sm rounded-lg w-full p-2.5" />
                                            </div>
                                            <div className="flex-auto">
                                                <label htmlFor="startdate" className="block font-bold">End Period </label>
                                                <input type="date" ref={inputEndDate} onChange={() => dateOnChange()} className="bg-gray-50 border border-gray-300 focus:outline-none text-sm rounded-lg w-full p-2.5" />
                                            </div> */}
                                            <DatePicker
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={dateOnChange}
                                                isClearable={true}
                                                showIcon
                                                placeholderText="Pilih Priode"
                                            />
                                        </div>
                                        {errorDate ?
                                            <>
                                                <span className="flex items-center font-sans tracking-wide text-xs ml-1 border px-1.5 py-1.5 bg-[#CB3A3140] rounded-lg text-white">
                                                    <div className="pr-3">
                                                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00003 6.5V8.16667M9.00003 11.5H9.00836M3.22653 14.8333H14.7735C16.0565 14.8333 16.8584 13.4444 16.2169 12.3333L10.4434 2.33333C9.8019 1.22222 8.19815 1.22222 7.55665 2.33333L1.78315 12.3333C1.14165 13.4444 1.94353 14.8333 3.22653 14.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    {errorDateMsg}
                                                </span></> : null
                                        }
                                    </div>

                                </div>

                                <div className="w-full h-[75vh] overflow-auto">
                                    {
                                        successLoad ?
                                            <>
                                                <ThreadInfo show={infoMenu} getThreadData={getThreadData} doRemounting={doRemounting} media={media} walletData={walletData} detailThreads={detailThreads} history={history} imageArtWork={imageArtWork} id={id} video={video}></ThreadInfo>
                                                <ThreadTransaction show={infoMenu} getThreadData={getThreadData} doRemounting={doRemounting} media={media} walletData={walletData} detailThreads={detailThreads} history={history} imageArtWork={imageArtWork} id={id} video={video}/>
                                                {renderStatistic()}
                                            </>

                                            : null
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ThreadsDetail;