import axios from "axios";
import Signature from "../../helpers/Signature";

export const getInsertnewAPI = async () => {
    let payloadSignature = [
        'WEBSITE',
        'PASSS'
    ];

    let signature = Signature(payloadSignature);

    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'signature': signature
    }

    let body = {}

    let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/insertnew', body, {
        headers: dataHeaders
    });
    return response;

}
