import MainHeader from "../../main/MainHeader";
import { useCallback, useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import axios from 'axios';
import Signature from "../../../helpers/Signature";
import { useNavigate } from "react-router-dom";
import ErrorCode from "../../../helpers/errorcode.json"
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import GamersUpdateProfile from "./modal/GamersUpdateProfile";
import Swal from "sweetalert2";
import Slider from "react-slick";

const GamersProfile = () => {
    const [walletIsLinked, setWalletIsLinked] = useState(false);
    const [userData, setUserData] = useState('');
    const [walletData, setWalletData] = useState('');
    const [readyToRender, setReadyToRender] = useState(false);
    const [profileImgPreview, setProfileImgPreview] = useState(null);
    const [modal, setModal] = useState(false);
    const [type, setType] = useState('');
    const [emailNotFound, setEmailNotFound] = useState(false);
    const navigate = useNavigate();
    const [totalReview, setTotalReview] = useState(0);
    const [totalEarning, setTotalEarning] = useState(0);

    const [percentage , setPercentage] = useState(0);
    const [gamerPage, setGamerPage] = useState(null);
    const [step, setStep] = useState('about');

    const [BiographyToggle, setBiographyToggle] = useState(false);
    const biographyInput = useRef();

    const [genreToggle, setGenreToggle] = useState(false);
    const [userIndex, setUserIndex] = useState('');
    const [interestData, setInterestData] = useState([]);

    const settings1 = {
        dots: false,
        speed: 100,
        variableWidth: false,
        arrows:false
    };

    const redirectTopDeveloper = (result) => {
        window.location.href = '/developers/'+result.developeruserindex;
    }

    const detailThreadsHandle = (data) => {
        navigate('/share/threads/' + data.tablethreadindex);
    }

    const rating = [
        {
            id: 1,
            selected: false
        },
        {
            id: 2,
            selected: false
        },
        {
            id: 3,
            selected: false
        },
        {
            id: 4,
            selected: false
        },
        {
            id: 5,
            selected: false
        },
    ]

    const displayRating = (val) => {
        let totalRating = rating;
        totalRating.forEach(element => {
            if (element.id < val || element.id === val) {
                element.selected = true;
            }else{
                element.selected = false;
            }
        });
        return (
            totalRating.map((item, index) => {
                return (
                    item.selected ?
                        <div key={index}>
                            <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 0L20.9844 9.13954L31.2169 11.0557L24.065 18.6205L25.4046 28.9443L16 24.48L6.59544 28.9443L7.93504 18.6205L0.783095 11.0557L11.0156 9.13954L16 0Z" fill="#FFF000"/>
                            </svg>
                        </div>
                        :
                        <div key={index}>
                            <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 0L20.9844 9.13954L31.2169 11.0557L24.065 18.6205L25.4046 28.9443L16 24.48L6.59544 28.9443L7.93504 18.6205L0.783095 11.0557L11.0156 9.13954L16 0Z" fill="#8084AB"/>
                            </svg>
                        </div>

                )
            })
        )
    }

    const slideSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
    };

    const profileGamersStatistic = useCallback( async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        try {
            let res = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/profilegamerstatistic?id='+currentTimestamp, {
                headers: dataHeaders
            });

            if(res.data.status.status === 1){
                setTotalReview(res.data.data.data_review.total);
                setTotalEarning(res.data.data.data_earning.total);
            }
        } catch (error) {
            console.log('err', error)
        }
    }, []);

    useEffect(() => {
        profileGamersStatistic();
    }, [profileGamersStatistic]);

    const _linkWalletHandler = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        let payload = {}
        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        try {
            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/linkwallet?id='+currentTimestamp, payload, {
                headers: dataHeaders
            });
            fetchWalletBallanceAPI();
            if (res.data.status.message[0].code === "008005035015") {
                setEmailNotFound(true);
                fetchWalletBallanceAPI();
            }
            if (res.data.status.message[0].code === "008005035010") {
                setWalletIsLinked(true);
                fetchWalletBallanceAPI();
            }
            console.log('response_linkwallet', res)
        } catch (err) {
            console.log('err', err)
        }

    }
    const fetchProfileAPI = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/profile?id='+currentTimestamp, {
            headers: dataHeaders
        });
        if (response.data.status.status === 1) {
            setUserData(response.data.data)
            setProfileImgPreview(response.data.data.userphotopict)
            setInterestData(response.data.data.interestgenre);
        }
        else {
            navigate('/logout');
        }

    }
    const fetchWalletBallanceAPI = async () => {

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        try {
            let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/walletbalance?id='+currentTimestamp, {
                headers: dataHeaders
            });
            if (response.data.status.status === 1) {

                setWalletIsLinked(true);
                setWalletData(response.data.data)
            }
            else {
                response.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        case ErrorCode.unauthenticated:
                            navigate('/login/developers');
                            break;
                        default:
                            break;
                    }
                })
            }
        } catch (err) {
            console.log(err)

        }
    }
    const getProfileData = async () => {
        const [profileAPI, walletballanceAPI] = await Promise.allSettled([
            fetchProfileAPI(),
            fetchWalletBallanceAPI()
        ])
        if (profileAPI.status === 'fulfilled' && walletballanceAPI.status === 'fulfilled') {
            setReadyToRender(true)
        }

    }
    const _handleUpdateProfileImg = async (e) => {

        const file = e.target.files[0];
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);

            reader.onloadend = () => {

            };
        }
        fetchUpdateProfileImgAPI(file);
        loadGamerPage();


    }
    const fetchUpdateProfileImgAPI = async (file) => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }
        let formData = new FormData();
        formData.append('file', file);
        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/uploadphoto', formData, {
            headers: dataHeaders
        });
        try {
            console.log('res_uploadphoto', response)
            if (response.data.status.status === 1) {
                fetchProfileAPI();
                loadGamerPage();
            }
        }
        catch (err) {

        }
    }
    const _handleUpdate = (id) => {
        console.log('id', id)
        setType(id);
        setModal(true);
    }
    useEffect(() => {
        getProfileData();
        setTimeout(() => {
            setPercentage(66)
        }, 1000);
         // eslint-disable-next-line
    }, [modal]);

    const phoneNumberHandle = async () => {
        Swal.fire({
            title: 'New Phone Number',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Change',
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
                let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
                let resultUUID = JSON.parse(atob(decrypt));
                let payloadSignature = [];
        
                let signature = Signature(payloadSignature);

                let dataHeaders = {
                    'platform': 'WEBSITE',
                    'gtoken': 'PASSS',
                    'userindex': resultUUID.user.userindex,
                    'tokenlogin': resultUUID.user.usertokenlogin,
                    'signature': signature
                }

                let body = {
                    'phonecountrycode' : '62',
                    'phonenumbershort' : login
                }

                try {
                    let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/changephonenumber',body, {
                        headers: dataHeaders
                    });

                    if(response.data.status.status === 1){
                        fetchProfileAPI();
                        Swal.fire(
                            'Success',
                            'Your change phone number successfuly.',
                            'success'
                        )
                    }else{
                        Swal.showValidationMessage(
                            response.data.status.message[0].errormessage
                        )
                    }
                } catch (err) {
                    console.log(err)
        
                }
            },
        });
    }

    const passwordHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        let body = {}

        try {
            let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/changepassword',body, {
                headers: dataHeaders
            });

            if(response.data.status.status === 1){
                Swal.fire({
                    title : 'Password',
                    text: 'Please check your email. please wait 2 seconds, you will be redirected to login',
                    icon: 'success',
                    showConfirmButton: false
                })

                setTimeout(() => {
                    Swal.closeModal();
                    localStorage.clear();
                    navigate('/login/gamers')
                }, 2000);
            }else{
                Swal.showValidationMessage(
                    response.data.status.message[0].errormessage
                )
            }
        } catch (err) {
            console.log(err)

        };
    }

    const usernameHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        let body = {}

        try {
            let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/changeusername',body, {
                headers: dataHeaders
            });

            if(response.data.status.status === 1){
                Swal.fire({
                    title : 'Username',
                    text: 'Please check your email. please wait 2 seconds, you will be redirected to login',
                    icon: 'success',
                    showConfirmButton: false
                })

                setTimeout(() => {
                    Swal.closeModal();
                    localStorage.clear();
                    navigate('/login/gamers')
                }, 2000);
            }else{
                Swal.showValidationMessage(
                    response.data.status.message[0].errormessage
                )
            }
        } catch (err) {
            console.log(err)

        };
    }

    const loadGamerPage = useCallback( async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        setUserIndex(resultUUID.user.userindex)

        let payloadSignature = []
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'signature' : signature
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI+'/api/homepage/v01/gamer?userindexgamer='+resultUUID.user.userindex+'&id='+currentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.status.status === 1){
            console.log(response.data.data);
            setGamerPage(response.data.data);            
        }
    }, []);

    useEffect(() => {
        loadGamerPage();
    }, [loadGamerPage]);

    const changeBiographyHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        let body = {
            biography : biographyInput.current.value
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI+'/api/user/v01/changebiography?id='+currentTimestamp, body, {
            headers : dataHeaders
        });

        if(response.data.status.status === 1){
            biographyInput.current.value = '';
            setBiographyToggle(false);
            loadGamerPage();
        }
    }

    const AddInterest = (id) => {
        const updatedGenres = [...interestData];
        const index = updatedGenres.findIndex(genre => genre.tableinterestgenreheaderid === id);
        if (index !== -1 && updatedGenres[index].tableinterestgenreheaderid === id) {
          updatedGenres[index] = { ...updatedGenres[index], tableinterestgenreisselected: 1 };
          setInterestData(updatedGenres);
        }
    };

    const RemoveInterest = (id) => {
        const updatedGenres = [...interestData];
        const index = updatedGenres.findIndex(genre => genre.tableinterestgenreheaderid === id);
        if (index !== -1 && updatedGenres[index].tableinterestgenreheaderid === id) {
          updatedGenres[index] = { ...updatedGenres[index], tableinterestgenreisselected: 0 };
          setInterestData(updatedGenres);
        }
    };

    const submitInterest = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        const selectedGenres = interestData.filter(genre => genre.tableinterestgenreisselected === 1);
        const selectedGenreIds = selectedGenres.map(genre => genre.tableinterestgenreheaderid);

        let body = {
            interestgenre : selectedGenreIds
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI+'/api/user/v01/changeinterestgenre?id='+currentTimestamp, body, {
            headers : dataHeaders
        });

        if(response.data.status.status === 1){
            setGenreToggle(false);
            loadGamerPage();
        }
    }

    return (
        <div className="m-0 p-0 overflow-auto">
            <div style={{ backgroundImage: "url('/images/main/mainBackground.svg')"}} className="w-[100vw] min-h-screen bg-cover bg-no-repeat">
                <GamersUpdateProfile modal={modal} type={type} cancel={() => setModal(false)} color={'developer'} />
                {
                    readyToRender ?
                        <>
                            <MainHeader isAuthenticated={readyToRender} userData={userData} />

                            {gamerPage ?
                            <div className="w-[70vw] m-auto mt-5">
                                <div className="w-full flex flex-row items-center gap-4">
                                    <button onClick={() => navigate('/')}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 17L6 12M6 12L11 7M6 12L18 12" stroke="#D7DCDF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <p className="text-white">Back</p>
                                </div>
                                <div className="w-full mt-5 flex flex-row justify-between">
                                    <h1 className="text-white text-[28px]">My Account</h1>
                                    <button className="bg-[#A63DFA] text-white font-semibold flex flex-row gap-2 items-center py-[8px] px-[12px] rounded-[6px]" onClick={() => navigate('/gamer/'+userIndex)}>
                                        See My Page
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.01677 10.5937C1.90328 10.414 1.84654 10.3241 1.81477 10.1855C1.79091 10.0814 1.79091 9.91727 1.81477 9.81317C1.84654 9.67458 1.90328 9.58473 2.01677 9.40503C2.95461 7.92005 5.74617 4.16602 10.0003 4.16602C14.2545 4.16602 17.0461 7.92005 17.9839 9.40503C18.0974 9.58473 18.1541 9.67458 18.1859 9.81317C18.2098 9.91727 18.2098 10.0814 18.1859 10.1855C18.1541 10.3241 18.0974 10.414 17.9839 10.5937C17.0461 12.0786 14.2545 15.8327 10.0003 15.8327C5.74617 15.8327 2.95461 12.0786 2.01677 10.5937Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.0003 12.4993C11.381 12.4993 12.5003 11.3801 12.5003 9.99935C12.5003 8.61864 11.381 7.49935 10.0003 7.49935C8.61962 7.49935 7.50034 8.61864 7.50034 9.99935C7.50034 11.3801 8.61962 12.4993 10.0003 12.4993Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="w-full mt-5">
                                    <div className='flex flex-row items-center justify-between gap-5 mb-3'>
                                        <div className='flex flex-row gap-3 items-center w-[50%]'>
                                            {gamerPage.gamer.tableuserphotofullpath ?
                                                <img src={gamerPage.gamer.tableuserphotofullpath} alt="gambar" className='w-[120px] h-[120px] rounded-md'/>
                                            :
                                                <img src={process.env.PUBLIC_URL+'/images/profil.png'} alt="gambar" className='w-[120px] h-[120px] rounded-md'/>
                                            }
                                            <input
                                                id="dropzone-file"
                                                type="file"
                                                name={'profileImg'}
                                                accept=".png, .jpg, .jpeg"
                                                onChange={(e) => _handleUpdateProfileImg(e)}
                                                style={{ display: "none" }}
                                            />
                                            <div className='flex flex-col'>
                                                <div className='flex flex-row gap-2 items-center mb-3'>
                                                    <h1 className='text-white text-[36px] font-semibold600'>{gamerPage.gamer.tableuserfullname}</h1>
                                                </div>
                                                <button className="bg-[#A63DFA] text-white font-semibold flex flex-row gap-2 items-center py-[8px] px-[12px] rounded-[6px] mb-5 w-[139px]" onClick={() => document.querySelector(`input[name=profileImg]`).click()}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.39662 15.0973C2.43491 14.7527 2.45405 14.5804 2.50618 14.4194C2.55243 14.2765 2.61778 14.1405 2.70045 14.0152C2.79363 13.8739 2.91621 13.7513 3.16136 13.5061L14.1666 2.5009C15.0871 1.58043 16.5795 1.58043 17.4999 2.5009C18.4204 3.42138 18.4204 4.91376 17.4999 5.83424L6.49469 16.8395C6.24954 17.0846 6.12696 17.2072 5.98566 17.3004C5.86029 17.383 5.72433 17.4484 5.58146 17.4946C5.42042 17.5468 5.24813 17.5659 4.90356 17.6042L2.08325 17.9176L2.39662 15.0973Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Edit Photos
                                                </button>
                                            </div>
                                        </div>
                                        <div className='w-[50%] flex flex-row gap-3 bg-[#000531] p-2 rounded-md'>
                                            <div className='bg-[#0E2E56] rounded-lg py-[12px] px-[16px] w-full'>
                                                <p className='text-[#A2A3FE] text-[16px]'>Total Review</p>
                                                <span className='text-white text-[28px]'>{gamerPage.total_approved_review} Review</span>
                                            </div>

                                            <div className='bg-[#0E2E56] rounded-lg py-[12px] px-[16px] w-full'>
                                                <p className='text-[#A2A3FE] text-[16px]'>Total Reward</p>
                                                <span className='text-[#E7C580] text-[28px]'>{gamerPage.total_reward} <span className='text-white'>ANOA</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10 w-full h-[48px] border-b-2 border-[#4B5563] flex flex-row items-center">
                                    <div className="h-full px-4 cursor-pointer" style={{borderBottomColor: step === 'about' ? '#FFFFFF' : '', borderBottomWidth: step === 'about' ? 3 : ''}} onClick={() => setStep('about')}>
                                        <p className="text-[#8084AB] text-[16px]" style={{color: step === 'about' ? '#FFFFFF' : ''}}>About</p>
                                    </div>
                                    <div className="h-full px-4 cursor-pointer" style={{borderBottomColor: step === 'review' ? '#FFFFFF' : '', borderBottomWidth: step === 'review' ? 3 : ''}} onClick={() => setStep('review')}>
                                        <p className="text-[#8084AB] text-[16px]" style={{color: step === 'review' ? '#FFFFFF' : ''}}>Review {gamerPage.listReview.length}</p>
                                    </div>
                                    <div className="h-full px-4 cursor-pointer" style={{borderBottomColor: step === 'preferred' ? '#FFFFFF' : '', borderBottomWidth: step === 'preferred' ? 3 : ''}} onClick={() => setStep('preferred')}>
                                        <p className="text-[#8084AB] text-[16px]" style={{color: step === 'preferred' ? '#FFFFFF' : ''}}>Preferred Developer {gamerPage.preferredDeveloper.length}</p>
                                    </div>
                                </div>
                                {step === 'about' ?
                                <div className="mt-[32px] pb-[32px]">
                                    {gamerPage.gamer.tableuserbiography ?
                                    <div className="mb-10">
                                        <h3 className="text-white text-[14px] font-semibold">Biography</h3>
                                        {BiographyToggle ?
                                        <div className="bg-[#130145] w-full p-[16px] rounded-[8px] flex flex-row items-center my-3">
                                            <input type="text" ref={biographyInput} className="w-full h-full bg-[#130145] outline-none text-white" maxLength={150} autoFocus={true}/>
                                            <div className="flex flex-row items-center gap-3">
                                                <button className="bg-[#A63DFA] p-[8px] rounded-[4px] border-2 border-[#A63DFA]" onClick={changeBiographyHandle}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.6666 5L7.49998 14.1667L3.33331 10" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                                <button className="border-2 border-[#A63DFA] p-[8px] rounded-[4px]" onClick={() => setBiographyToggle(false)}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14.1666 5.83398L5.83331 14.1673M5.83331 5.83398L14.1666 14.1673" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="my-3 flex flex-row items-center gap-3 w-[70%]">
                                            <p className="text-[#CCCEDD]">{gamerPage.gamer.tableuserbiography}</p>
                                            <button onClick={() => setBiographyToggle(true)}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.39662 15.0973C2.43491 14.7527 2.45405 14.5804 2.50618 14.4194C2.55243 14.2765 2.61778 14.1405 2.70045 14.0152C2.79363 13.8739 2.91621 13.7513 3.16136 13.5061L14.1666 2.5009C15.0871 1.58043 16.5795 1.58043 17.4999 2.5009C18.4204 3.42138 18.4204 4.91376 17.4999 5.83424L6.49469 16.8395C6.24954 17.0846 6.12696 17.2072 5.98566 17.3004C5.86029 17.383 5.72433 17.4484 5.58146 17.4946C5.42042 17.5468 5.24813 17.5659 4.90356 17.6042L2.08325 17.9176L2.39662 15.0973Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>
                                        }
                                    </div> : '' }
                                    
                                    <div className="mb-10">
                                        <h3 className="text-white text-[14px] font-semibold">GamersGantengIdaman’s Interest</h3>
                                        {genreToggle ?
                                        <div className="bg-[#130145] p-[16px] flex flex-row justify-between items-start my-2">
                                            <div className="w-[50%]">
                                                <div className="flex items-center gap-2 flex-wrap">
                                                    {interestData.map((result) => (
                                                        <div>
                                                            {result.tableinterestgenreisselected === 1 ?
                                                            <span className="bg-[#C53081] text-white px-[10px] py-[3px] rounded-[6px] flex flex-row items-center gap-2" key={result.tableinterestgenreheaderid}>
                                                                {result.tablegenreheadername}
                                                                <button onClick={() => RemoveInterest(result.tableinterestgenreheaderid)}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M11.3333 4.66602L4.66663 11.3327M4.66663 4.66602L11.3333 11.3327" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </button>
                                                            </span>:
                                                            <span onClick={() => AddInterest(result.tableinterestgenreheaderid)} className="border-2 border-[#FF99F5] text-[#FF99F5] px-[10px] py-[3px] rounded-[6px] flex flex-row justify-center items-center gap-2 cursor-pointer" key={result.tableinterestgenreheaderid}>
                                                                {result.tablegenreheadername}
                                                            </span>}
                                                        </div>
                                                    ))}
                                                    
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center gap-3">
                                                <button className="bg-[#A63DFA] p-[8px] rounded-[4px] border-2 border-[#A63DFA]" onClick={submitInterest}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.6666 5L7.49998 14.1667L3.33331 10" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                                <button className="border-2 border-[#A63DFA] p-[8px] rounded-[4px]" onClick={() => setGenreToggle(false)}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14.1666 5.83398L5.83331 14.1673M5.83331 5.83398L14.1666 14.1673" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="my-2 w-[50%]">
                                            <div className="flex items-center gap-2 flex-wrap">
                                                {gamerPage.interestGenre.map((result, key) => (
                                                    <span className="bg-[#C53081] text-white px-[10px] py-[3px] rounded-[6px]" key={key}>{result.tablegenreheadername}</span>
                                                ))}
                                                {gamerPage.interestGenre.length > 0 ?
                                                <button className="ml-3" onClick={() => setGenreToggle(true)}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.39686 15.0973C2.43515 14.7527 2.45429 14.5804 2.50642 14.4194C2.55268 14.2765 2.61802 14.1405 2.70069 14.0152C2.79388 13.8739 2.91645 13.7513 3.1616 13.5061L14.1669 2.5009C15.0873 1.58043 16.5797 1.58043 17.5002 2.5009C18.4207 3.42138 18.4207 4.91376 17.5002 5.83424L6.49493 16.8395C6.24978 17.0846 6.12721 17.2072 5.9859 17.3004C5.86054 17.383 5.72457 17.4484 5.5817 17.4946C5.42067 17.5468 5.24838 17.5659 4.9038 17.6042L2.0835 17.9176L2.39686 15.0973Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button> : 
                                                <button onClick={() => setGenreToggle(true)} className="border-2 border-[#FF99F5] border-dashed text-[#FF99F5] px-[10px] py-[3px] rounded-[6px] flex flex-row justify-center items-center gap-2 cursor-pointer">
                                                    Add Interest
                                                </button>
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className="mb-10">
                                        <h3 className="text-white text-[14px] font-semibold">User Info</h3>
                                        <div className="my-2 flex flex-col gap-2">
                                            <div className="flex flex-row items-center gap-3">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 12.5C7.35831 12.5 5.00904 13.7755 3.51337 15.755C3.19146 16.181 3.0305 16.394 3.03577 16.6819C3.03984 16.9043 3.17951 17.1849 3.35451 17.3222C3.58103 17.5 3.89493 17.5 4.52273 17.5H15.4773C16.1051 17.5 16.4191 17.5 16.6456 17.3222C16.8206 17.1849 16.9602 16.9043 16.9643 16.6819C16.9696 16.394 16.8086 16.181 16.4867 15.755C14.991 13.7755 12.6418 12.5 10 12.5Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M10 10C12.0711 10 13.75 8.32107 13.75 6.25C13.75 4.17893 12.0711 2.5 10 2.5C7.92897 2.5 6.25004 4.17893 6.25004 6.25C6.25004 8.32107 7.92897 10 10 10Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <p className="text-white">{userData.username}</p>
                                                <button onClick={usernameHandle}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.39686 15.0973C2.43515 14.7527 2.45429 14.5804 2.50642 14.4194C2.55268 14.2765 2.61802 14.1405 2.70069 14.0152C2.79388 13.8739 2.91645 13.7513 3.1616 13.5061L14.1669 2.5009C15.0873 1.58043 16.5797 1.58043 17.5002 2.5009C18.4207 3.42138 18.4207 4.91376 17.5002 5.83424L6.49493 16.8395C6.24978 17.0846 6.12721 17.2072 5.9859 17.3004C5.86054 17.383 5.72457 17.4484 5.5817 17.4946C5.42067 17.5468 5.24838 17.5659 4.9038 17.6042L2.0835 17.9176L2.39686 15.0973Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>

                                            <div className="flex flex-row items-center gap-3">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.6676 5H15.8343C16.7548 5 17.501 5.74619 17.501 6.66667V14.1667C17.501 15.0871 16.7548 15.8333 15.8343 15.8333H4.16764C3.24717 15.8333 2.50098 15.0871 2.50098 14.1667V6.66667C2.50098 5.74619 3.24717 5 4.16764 5H8.33431M11.6676 5V4.16667C11.6676 3.24619 10.9215 2.5 10.001 2.5C9.0805 2.5 8.33431 3.24619 8.33431 4.16667V5M11.6676 5C11.6676 5.92047 10.9215 6.66667 10.001 6.66667C9.0805 6.66667 8.33431 5.92047 8.33431 5M12.501 11.6667C11.5805 11.6667 10.8343 10.9205 10.8343 10C10.8343 9.07953 11.5805 8.33333 12.501 8.33333C13.4215 8.33333 14.1676 9.07953 14.1676 10C14.1676 10.9205 13.4215 11.6667 12.501 11.6667ZM12.501 11.6667C11.4125 11.6667 10.4865 12.3623 10.1433 13.3333M12.501 11.6667C13.5895 11.6667 14.5156 12.3623 14.8588 13.3333M7.50098 9.16667H5.00098M7.50098 12.5H5.83431" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <p className="text-white">{userData.userfullname}</p>
                                                <button onClick={() => _handleUpdate('Fullname')}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.39686 15.0973C2.43515 14.7527 2.45429 14.5804 2.50642 14.4194C2.55268 14.2765 2.61802 14.1405 2.70069 14.0152C2.79388 13.8739 2.91645 13.7513 3.1616 13.5061L14.1669 2.5009C15.0873 1.58043 16.5797 1.58043 17.5002 2.5009C18.4207 3.42138 18.4207 4.91376 17.5002 5.83424L6.49493 16.8395C6.24978 17.0846 6.12721 17.2072 5.9859 17.3004C5.86054 17.383 5.72457 17.4484 5.5817 17.4946C5.42067 17.5468 5.24838 17.5659 4.9038 17.6042L2.0835 17.9176L2.39686 15.0973Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>

                                            <div className="flex flex-row items-center gap-3">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_6360_17114)">
                                                        <path d="M13.3332 6.66745V10.8341C13.3332 11.4972 13.5966 12.133 14.0654 12.6019C14.5343 13.0707 15.1701 13.3341 15.8332 13.3341C16.4962 13.3341 17.1321 13.0707 17.6009 12.6019C18.0698 12.133 18.3332 11.4972 18.3332 10.8341V10.0008C18.3331 8.11998 17.6967 6.29452 16.5276 4.82123C15.3584 3.34794 13.7253 2.31347 11.8937 1.88603C10.0621 1.45859 8.13979 1.66332 6.4393 2.46692C4.73881 3.27053 3.36018 4.62575 2.52756 6.31222C1.69494 7.99869 1.45732 9.91723 1.85332 11.7559C2.24932 13.5945 3.25566 15.2451 4.7087 16.4393C6.16175 17.6335 7.97604 18.3011 9.85657 18.3334C11.7371 18.3657 13.5733 17.761 15.0665 16.6175M13.3332 10.0008C13.3332 11.8417 11.8408 13.3341 9.99985 13.3341C8.1589 13.3341 6.66651 11.8417 6.66651 10.0008C6.66651 8.15984 8.1589 6.66745 9.99985 6.66745C11.8408 6.66745 13.3332 8.15984 13.3332 10.0008Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_6360_17114">
                                                            <rect width="20" height="20" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p className="text-white">{userData.useremail}</p>
                                                <button onClick={() => _handleUpdate('Email')}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.39686 15.0973C2.43515 14.7527 2.45429 14.5804 2.50642 14.4194C2.55268 14.2765 2.61802 14.1405 2.70069 14.0152C2.79388 13.8739 2.91645 13.7513 3.1616 13.5061L14.1669 2.5009C15.0873 1.58043 16.5797 1.58043 17.5002 2.5009C18.4207 3.42138 18.4207 4.91376 17.5002 5.83424L6.49493 16.8395C6.24978 17.0846 6.12721 17.2072 5.9859 17.3004C5.86054 17.383 5.72457 17.4484 5.5817 17.4946C5.42067 17.5468 5.24838 17.5659 4.9038 17.6042L2.0835 17.9176L2.39686 15.0973Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>

                                            <div className="flex flex-row items-center gap-3">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.98356 7.37779C7.56356 8.58581 8.35422 9.71801 9.35553 10.7193C10.3568 11.7206 11.4891 12.5113 12.6971 13.0913C12.801 13.1412 12.8529 13.1661 12.9187 13.1853C13.1523 13.2534 13.4392 13.2045 13.637 13.0628C13.6927 13.0229 13.7403 12.9753 13.8356 12.88C14.1269 12.5887 14.2726 12.443 14.4191 12.3478C14.9715 11.9886 15.6837 11.9886 16.2361 12.3478C16.3825 12.443 16.5282 12.5887 16.8196 12.88L16.9819 13.0424C17.4248 13.4853 17.6462 13.7067 17.7665 13.9446C18.0058 14.4175 18.0058 14.9761 17.7665 15.449C17.6462 15.6869 17.4248 15.9083 16.9819 16.3512L16.8506 16.4825C16.4092 16.9239 16.1886 17.1446 15.8885 17.3131C15.5556 17.5001 15.0385 17.6346 14.6567 17.6334C14.3126 17.6324 14.0774 17.5657 13.607 17.4322C11.0792 16.7147 8.69387 15.361 6.70388 13.371C4.7139 11.381 3.36017 8.99569 2.6427 6.46786C2.50919 5.99749 2.44244 5.7623 2.44141 5.41818C2.44028 5.03633 2.57475 4.51925 2.76176 4.18633C2.9303 3.88631 3.15098 3.66563 3.59233 3.22428L3.72369 3.09292C4.16656 2.65005 4.388 2.42861 4.62581 2.30833C5.09878 2.0691 5.65734 2.0691 6.1303 2.30832C6.36812 2.42861 6.58955 2.65005 7.03242 3.09291L7.19481 3.25531C7.48615 3.54665 7.63182 3.69231 7.72706 3.8388C8.08622 4.3912 8.08622 5.10336 7.72706 5.65576C7.63182 5.80225 7.48615 5.94791 7.19481 6.23925C7.09955 6.33451 7.05192 6.38214 7.01206 6.43782C6.87038 6.63568 6.82146 6.92256 6.88957 7.15619C6.90873 7.22193 6.93367 7.27389 6.98356 7.37779Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <p className="text-white">{userData.userphonenumber}</p>
                                                <button onClick={phoneNumberHandle}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.39686 15.0973C2.43515 14.7527 2.45429 14.5804 2.50642 14.4194C2.55268 14.2765 2.61802 14.1405 2.70069 14.0152C2.79388 13.8739 2.91645 13.7513 3.1616 13.5061L14.1669 2.5009C15.0873 1.58043 16.5797 1.58043 17.5002 2.5009C18.4207 3.42138 18.4207 4.91376 17.5002 5.83424L6.49493 16.8395C6.24978 17.0846 6.12721 17.2072 5.9859 17.3004C5.86054 17.383 5.72457 17.4484 5.5817 17.4946C5.42067 17.5468 5.24838 17.5659 4.9038 17.6042L2.0835 17.9176L2.39686 15.0973Z" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                                {step === 'review' ?
                                <div className="w-full mt-[32px] pb-[32px]">
                                    {gamerPage.listReview.length > 0 ?
                                    gamerPage.listReview.map((result, key) => (
                                    <div className='flex flex-row gap-4 w-full'>
                                        <div className='w-[20%]'>                                      
                                            <div className="bg-[#1B2026] p-[12px] rounded-[12px] flex flex-col mb-3 justify-between" key={key}>
                                                <div className='flex flex-col gap-3'>
                                                    <div className="relative">
                                                        <Slider {...settings1}>
                                                            {result.tablethreadimage.map((resultImage, keyImage) => {
                                                                const fileExtension = resultImage.tablemediafilename.split('.').pop();

                                                                const isPNG = fileExtension === 'png';
                                                                const isJPG = fileExtension === 'jpg';
                                                                const isJPEG = fileExtension === 'jpeg';

                                                                return(
                                                                    <>
                                                                    {isPNG?
                                                                        <img src={resultImage.tablemediafilenamefullpath} className="h-[160px]" key={keyImage}/>
                                                                    : '' }
                                                                    {isJPG?
                                                                        <img src={resultImage.tablemediafilenamefullpath} className="h-[160px]" key={keyImage}/>
                                                                    : '' }
                                                                    {isJPEG?
                                                                        <img src={resultImage.tablemediafilenamefullpath} className="h-[160px]" key={keyImage}/>
                                                                    : '' }
                                                                    </>
                                                                )
                                                            })}
                                                        </Slider>
                                                        <div className="absolute top-3 right-2">
                                                            <div className="bg-gradient-to-r from-purple-500 to-pink-500 px-[16px] py-[8px] rounded-[12px] text-white text-[12px]">
                                                                Get Up To {result.totalreward} ANOA
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full flex flex-col gap-3">
                                                        <div className="flex flex-row gap-3 items-center">
                                                            {result.developerphotoprofilefullpath === null ?
                                                                <img src={process.env.PUBLIC_URL+'/images/profil.png'} className="w-[48px] h-[48px] rounded-[6px]"/>
                                                            : 
                                                                <img src={result.developerphotoprofilefullpath} className="w-[48px] h-[48px] rounded-[6px]"/>
                                                            }
                                                            
                                                            <div>
                                                                <div className="flex flex-row gap-2 items-center">
                                                                    <span className="text-[16px] block text-[#FFF] cursor-pointer" onClick={() => redirectTopDeveloper(result)}>{result.developerusername}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-full">
                                                            <h1 className="text-[20px] text-white cursor-pointer" onClick={() => detailThreadsHandle(result)}>{result.tablethreadtitle}</h1>
                                                        </div>
                                                        <div className='flex flex-row gap-2 items-start'>
                                                            <img src={process.env.PUBLIC_URL+'/images/Stars.png'} alt="star" className='mt-1'/>
                                                            <span className='text-white'>{result.averageRating}(1000)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-[80%] h-full'>
                                            <div className='flex flex-col w-full h-full '>
                                                <div className='flex flex-row justify-between items-center'>
                                                    <div className='flex flex-row gap-4'>
                                                        <div className='flex flex-row gap-2'>
                                                            {displayRating(result.tablereviewrating)}
                                                        </div>
                                                        <p className='text-[#44B46A]'>Reward : {result.totalreward} Anoa</p>
                                                    </div>
                                                    <p className='text-[#9CA3AF]'>{result.tablereviewtimestamp}</p>
                                                </div>
                                                <p className='text-white'>
                                                    {result.tablereviewdescription}
                                                </p>
                                                <a href={result.tablereviewyoutubelink} target='_blank' className='text-white mb-3'>
                                                    {result.tablereviewyoutubelink}
                                                </a>
                                                {result.tablereviewpictfullpath > 3 ?
                                                <div className='h-[160px] mx-3'>
                                                    {console.log(result)}
                                                    <Slider {...slideSettings}>
                                                        {result.tablereviewpictfullpath.map((result, key) => (
                                                            <img src={result} key={key} alt="image" className='h-[160px] w-[253px]  px-2 rounded-md bg-no-repeat'/>
                                                        ))}
                                                        {result.tablereviewvideopath.map((result, key) => (
                                                            <div className='h-[160px] w-[253px] 'key={key}>
                                                                <video controls>
                                                                    <source src={'https://back.thespace.world/public/'+result} />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </div>
                                                : 
                                                <div className='h-[160px] flex flex-row gap-3'>
                                                    {result.tablereviewpictfullpath.map((result, key) => (
                                                        <img src={result} key={key} alt="image" className='h-[160px] w-[253px]  px-2 rounded-md bg-no-repeat'/>
                                                    ))}
                                                    {result.tablereviewvideofullpath.map((result, key) => (
                                                        <div className='h-[160px] w-[253px] 'key={key}>
                                                            <video controls>
                                                                <source src={result} />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        </div>
                                                    ))}
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    )) : ''}
                                </div>
                                : '' }

                                {step === 'preferred' ? 
                                <div className="w-full mt-[32px] pb-[32px]">
                                    <div className="grid grid-cols-5 gap-2">
                                        {gamerPage.preferredDeveloper.map((result, key) => (
                                            <div className="bg-[#1B2026] p-[12px] rounded-[12px]" key={key}>
                                                {result.developerphotoprofilefullpath !== null ?
                                                <img src={'https://back.thespace.world/public/'+result.developerphotoprofilefullpath} className="w-full h-[160px]"/>
                                                :
                                                <img src={process.env.PUBLIC_URL+'/images/profil.png'} className="w-full h-[160px]"/>
                                                }
                                                <p className="text-white text-[20px] py-2 font-semibold">{result.developerfullname}</p>
                                                <span className="text-white">{result.totalThreads} Threads</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : ''}
                            </div> : '' }
                        </>

                        : null
                }




            </div>
        </div>
    )
}
export default GamersProfile;