// import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

// import axios from 'axios';
// import Signature from '../../helpers/Signature';
// import Modal from "../../components/modal/Modal";

const Register = () => {
    const navigate = useNavigate();

    // const [modal, setModal] = useState(false);
    // const [typeModal, setTypeModal] = useState('');
    // const [contentModal, setContentModal] = useState({});

    const registerHandle = async (action) => {
        if (action === 'developers') {
            navigate('/register/developers');
        }

        if (action === 'gamers') {
            navigate('/register/gamers');
        }
        // let payloadSignature = [
        //     'WEBSITE',
        //     'PASSS'
        // ];

        // let signature = Signature(payloadSignature);

        // let dataHeaders = {
        //     'platform': 'WEBSITE',
        //     'gtoken': 'PASSS',
        //     'signature': signature
        // }

        // let body = {}

        // let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/insertnew', body, {
        //     headers: dataHeaders
        // });

        // if (response.data.status.status === 1) {
        //     localStorage.setItem('_tokentrans', response.data.status.tokentrans);

        //     if (action === 'developers') {
        //         navigate('/register/developers');
        //     }

        //     if (action === 'gamers') {
        //         navigate('/register/gamers');
        //     }
        // }

        // if (response.data.status.status === 0) {
        //     response.data.status.message.forEach(element => {
        //         let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
        //         let replaceError = resultError.replace('###', element.codevariable);

        //         let contentModal = {
        //             'head': 'Error',
        //             'pesan': replaceError,
        //             'description': element.code
        //         };
        //         setContentModal(contentModal);
        //         setTypeModal('error');
        //         setModal(true);
        //     });
        // }
    }

    return (


        <div className="w-full h-full">
            {/* <Modal modal={modal} type={typeModal} content={contentModal} cancel={() => setModal(false)} /> */}
            <div style={{ backgroundImage: "url('/images/bg.png')" }} className="flex items-center justify-center w-full h-full bg-no-repeat bg-cover">
                <div className="container flex items-center justify-center h-full ">
                        <div className="absolute flex justify-evenly translate-y-[-50%] w-full top-[50%] ">
                            <img src={process.env.PUBLIC_URL + '/images/component-1.png'} alt="element" className="float-left block mt-[0px] mr-[15vw] w-[30%] h-[100%] animate-vPattern-slow " />
                            <img src={process.env.PUBLIC_URL + '/images/component-2.png'} alt="element" className="float-right block mt-[100px]  w-[30%] h-[100%] animate-wiggle-slow " />
                        </div>
                    <div className="relative w-full h-full">
                        <div className="absolute translate-y-[-50%] top-[50%] w-full">
                            <div className="bg-bg1 m-auto w-[640px] backdrop-blur-[21.376px] border-2 border-[#12007D] rounded-[24px] h-[367px]">
                                <div className="px-[80px]">
                                    <h3 className="text-center text-white text-[36px] py-[36px]">Register</h3>

                                    <div className="mb-5">
                                        <button className="bg-bm1 w-full p-[10px] text-white rounded-[12px]" onClick={() => registerHandle('developers')}>Register as a Developer</button>
                                    </div>
                                    <div className="mb-5">
                                        <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px]" onClick={() => registerHandle('gamers')}>Register as a Gamer</button>
                                    </div>
                                    <div>
                                        <p className="text-center text-white">Don't have an account? <Link to="/mainLogin"><span className="underline">Sign in</span></Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;