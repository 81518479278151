const ModalOne = (props) => {
    return(
        <>
            {props.modal ? <>
            <div className="bg-bg1 backdrop-blur-[3px] fixed z-30 inset-0"></div>
            <div className="fixed inset-0 z-30">
                <div className="relative w-full h-full ">
                    <div className="absolute translate-y-[-50%] top-[50%] w-full">
                        <div className="max-w-[725px] m-auto">
                            <div className="mb-[-100px] w-full">
                                {props.type === 'error' ?
                                <img src={process.env.PUBLIC_URL+'/images/component-5.png'} alt="element" aria-hidden className="block ml-auto"/>
                                : 
                                <img src={process.env.PUBLIC_URL+'/images/component-6.png'} alt="element" aria-hidden className="block ml-auto"/>
                                }
                            </div>
                            <div className="bg-white rounded-rd1 px-[48px] py-[48px]">
                                <h3 className="text-black text-[48px] pb-4">{props.content.head}</h3>
                                <p className="text-black text-[20px] pb-5">{props.content.pesan}</p>

                                <p className="text-[#989B9D] pb-8">{props.content.description}</p>
                                
                                {props.color === 'developer' ?
                                <button className="bg-bm1 w-full p-[10px] text-white rounded-[12px] m-auto block" onClick={props.cancel}>Close</button>
                                :
                                <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px] m-auto block" onClick={props.cancel}>Close</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </> : ''}
        </>
    );
}

export default ModalOne;