import { Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import BarLoader from "../../components/loader/BarLoader";
import { SuspenseImg } from "../../helpers/ImageCache";

const Login = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="relative w-full h-full ">
                <Suspense fallback={<BarLoader />}>
                    <SuspenseImg alt="element" src={process.env.PUBLIC_URL + '/images/bg.png'} className="flex items-center justify-center w-full h-full bg-no-repeat bg-cover" />
                    <div className="absolute top-0 left-0 w-full h-screen">
                        <div className="absolute flex justify-evenly translate-y-[-50%] w-full top-[50%] ">
                            <img src={process.env.PUBLIC_URL + '/images/component-1.png'} alt="element" className="float-left block mt-[0px] mr-[15vw] w-[30%] h-[100%] animate-vPattern-slow " />
                            <img src={process.env.PUBLIC_URL + '/images/component-2.png'} alt="element" className="float-right block mt-[100px]  w-[30%] h-[100%] animate-wiggle-slow " />
                        </div>

                        <div className="relative w-full h-full">
                            <div className="absolute translate-y-[-50%] top-[50%] w-full">
                                <div className="bg-bg1 m-auto w-[640px] backdrop-blur-[21.376px] border-2 border-[#12007D] rounded-[24px] h-[367px]">
                                    <div className="px-[80px]">
                                        <h3 className="text-center text-white text-[36px] py-[36px]">Login</h3>

                                        <div className="mb-5">
                                            <button className="bg-bm1 w-full p-[10px] text-white rounded-[12px]" onClick={() => navigate('/login/developers')}>Login as a Developer</button>
                                        </div>
                                        <div className="mb-5">
                                            <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px]" onClick={() => navigate('/login/gamers')}>Login as a Gamer</button>
                                        </div>
                                        <div>
                                            <p className="text-center text-white">Don't have an account? <Link to="/register"><span className="underline">Register Now</span></Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </div>

        </>
    );
}

export default Login;