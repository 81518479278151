import axios from "axios";
import Signature from "../../helpers/Signature";

export const fetchEmailverification = async (code) => {
    console.log('code', code)
    let payloadSignature = [code];
    let signature = Signature(payloadSignature);

    let headers = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'signature': signature
    }
    let body = {
        'emailverificationcode': code
    }
    let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/emailverification', body, {
        headers: headers
    });
    try {
        console.log('response', response);
        if (response.data.status.status === 1) {
            return {
                'isSuccess': true,
                'data': {},
                'isError': false,
                'errorCode': '0'
            };
        }
        else {
            const resCode = response.data.status.message[0];
            switch (resCode.code) {
                case "008005035025":
                    return {
                        'isSuccess': false,
                        'data': resCode.errormessage,
                        'isError': false,
                        'errorCode': '008005035025'
                    };
                default:
                    break;
            }
            
        }
    } catch (err) {
        return {
            'isSuccess': false,
            'data': err,
            'isError': true
        };
    }

}   