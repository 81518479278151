import './Spinner.css'
export default function Spinner() {
    return (

        <>
            <div className="bg-bg1  backdrop-blur-[3px] fixed z-10 inset-0 w-full h-full ">
                <div className="fixed inset-0 z-20 ">
                    <div className="relative w-full h-full ">
                        <div className="absolute translate-y-[-50%] top-[50%] left-[50%] w-full" >
                            <div className='bg-[#03002C] w-fit px-4 py-4 rounded-xl shadow-xl '>
                                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}