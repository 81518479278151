import axios from "axios";
import Signature from "../../../../../helpers/Signature";
import CryptoJS from "crypto-js";

export const FetchAPIUpload = async (data) => {
    // const navigate = useNavigate();
    let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    let resultUUID = JSON.parse(atob(decrypt));

    let payloadSignature = [];

    let signature = Signature(payloadSignature);

    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'userindex': resultUUID.user.userindex,
        'tokenlogin': resultUUID.user.usertokenlogin,
        'signature': signature,
        'tokentrans': localStorage.getItem('tokentrans'),
        'Content-Type': 'multipart/form-data'
    }

    let formData = new FormData();

    data.forEach(element => {
        formData.append('file', element.file);
    });

    let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/upload', formData, {
        headers: dataHeaders,

    });
    console.log('res_upload', response)

    if (response.data.status.status === 1) {
        let resUpload = {
            'status': 1,
            'showSpinner': false,
            'openCoinDistribution': true,
            'message': response.data.status.message
        }
        return resUpload;
    }

    if (response.data.status.status === 0) {
        let resUpload = {
            'status': 0,
            'showSpinner': false,
            'openCoinDistribution': true,
            'message': response.data.status.message
        }
        return resUpload;

    }
}
export const FetchAPIGenre = async () => {
    console.log('masuk')
    let payloadSignature = [];
    let signature = Signature(payloadSignature);

    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'signature': signature
    }
    let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/homepage/v01/listgenre', {
        headers: dataHeaders
    });
    try {
        console.log('res_listGenre', response)
        if (response.data.status.status === 1) {
            var value = response.data.data
            return value
        }
    }
    catch (err) {
        console.log('err', err)
    }
}
export const FetchAPIlinkwallet = async () => {
    let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    let resultUUID = JSON.parse(atob(decrypt));
    let payloadSignature = [];
    let signature = Signature(payloadSignature);
    let payload = {}
    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'userindex': resultUUID.user.userindex,
        'tokenlogin': resultUUID.user.usertokenlogin,
        'signature': signature
    }

    const currentTimestamp = new Date().getTime();

    try {
        let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/linkwallet?id='+currentTimestamp, payload, {
            headers: dataHeaders
        });
        console.log('response_linkwallet', res)
        if (res.data.status.message[0].code === "008005035015") {
            console.log('masuk')
            window.open("https://dev.wallet.anoatoken.com/signup")
        }
        if (res.data.status.message[0].code === "008005035010") {
            // setIsWalletLinked(true);
            // getWalletBallance();
            return {
                "linked": true,
            }
        }else{
            return {
                "linked": false,
                "code" : res.data.status.message[0].code,
                "message" : res.data.status.message[0].errormessage
            }
        }
    } catch (err) {
        console.log('err', err)
    }
}
export const FetchAPIThreadAdd = async (data, payloadSignature) => {
    console.log('body', data);
    console.log('Signature', payloadSignature);
    let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    let resultUUID = JSON.parse(atob(decrypt));
    // const payload = payloadSignature
    const payload = []

    let signature = Signature(payload);
    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'userindex': resultUUID.user.userindex,
        'tokenlogin': resultUUID.user.usertokenlogin,
        'signature': signature,
        'tokentrans': localStorage.getItem('tokentrans')
    }
    let body = data;
    try {
        let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/addthread', body, {
            headers: dataHeaders
        });
        console.log('res_threadAdd', res)
        return res.data.status;
    }
    catch (err) {

    }

}
export const FetchAPIUploadImages = async (data) => {
    // const navigate = useNavigate();
    console.log('IMAGE_DATA', data)
    let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    let resultUUID = JSON.parse(atob(decrypt));

    let payloadSignature = [];

    let signature = Signature(payloadSignature);

    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'userindex': resultUUID.user.userindex,
        'tokenlogin': resultUUID.user.usertokenlogin,
        'signature': signature,
        'tokentrans': localStorage.getItem('tokentrans'),
        'Content-Type': 'multipart/form-data'
    }

    let formData = new FormData();

    data.forEach(element => {
        formData.append('file', element.file);
    });

    let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/uploadimage', formData, {
        headers: dataHeaders,

    });
    console.log('res_uploadimage', response)

    if (response.data.status.status === 1) {
        let resUpload = {
            'status': 1,
            'showSpinner': false,
            'openCoinDistribution': true,
            'message': response.data.status.message
        }
        return resUpload;
    }

    if (response.data.status.status === 0) {
        let resUpload = {
            'status': 0,
            'showSpinner': false,
            'openCoinDistribution': true,
            'message': response.data.status.message
        }
        return resUpload;

    }
}
export const FetchAPIUploadVideos = async (data) => {
    let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
    let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
    let resultUUID = JSON.parse(atob(decrypt));
    let payloadSignature = [];
    let signature = Signature(payloadSignature);

    let dataHeaders = {
        'platform': 'WEBSITE',
        'gtoken': 'PASSS',
        'userindex': resultUUID.user.userindex,
        'tokenlogin': resultUUID.user.usertokenlogin,
        'signature': signature,
        'tokentrans': localStorage.getItem('tokentrans'),
        'Content-Type': 'multipart/form-data'
    }
    let formData = new FormData();
    formData.append('file', data.file);
    formData.append('file', data.thumbnailFile);
    let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/thread/v01/uploadvideo', formData, {
        headers: dataHeaders,

    });
    console.log('res_uploadvideo', response)

    if (response.data.status.status === 1) {
        let resUpload = {
            'status': 1,
            'showSpinner': false,
            'openCoinDistribution': true,
            'message': response.data.status.message
        }
        return resUpload;
    }

    if (response.data.status.status === 0) {
        let resUpload = {
            'status': 0,
            'showSpinner': false,
            'openCoinDistribution': true,
            'message': response.data.status.message
        }
        return resUpload;

    }
}


