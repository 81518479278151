import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEmailverification } from "./AuthController";

const VerificationEmail = () => {

    const { code } = useParams();
    const [resVerifyEmail, setResVerifyEmail] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        console.log('MASUK WOI');
        const fetchEmailverificationAPI = async () => {

            const res = await fetchEmailverification(code);
            console.log('res', res);
            if (!res.isError) {
                setResVerifyEmail(res);
            }
            else {

            }
        }
        fetchEmailverificationAPI();
    }, [code]);
    return (
        <div className="bg-[#041640]  w-full h-full flex items-center justify-center overflow-hidden">
            <div className={ resVerifyEmail.isSuccess ? "flex flex-col justify-between p-12 gap-12 bg-[#03002C] text-[#FFFF] border-[2px] rounded-lg border-[#1100D0] lg:w-2/5 w-[80%] overflow-auto h-[90%] sm:h-auto"
            :
            "flex flex-col justify-between p-12 gap-12 bg-[#03002C] text-[#FFFF] border-[2px] rounded-lg border-red-600 lg:w-2/5 w-[80%] overflow-auto h-[90%] sm:h-auto"
            }>
            {/* <div className="flex flex-col justify-between p-12 gap-12 bg-[#03002C] text-[#FFFF] border-[2px] rounded-lg border-[#1100D0] lg:w-2/5 w-[80%] overflow-auto h-[90%] sm:h-auto"> */}
                <div className="flex flex-col items-center justify-center">
                    {
                        resVerifyEmail.errorCode === "008005035025" ?
                            <p className="text-2xl font-semibold text-center sm:text-4xl lg:text-4xl">Verification Email Invalid</p>
                            :
                            <p className="text-2xl font-semibold text-center sm:text-4xl lg:text-4xl">Verification Email Success</p>
                    }
                </div>
                <div className="flex items-center self-center justify-center my-12 w-5/5 sm:w-3/5 ">
                    <img src={process.env.PUBLIC_URL + '/images/newSpace.svg'} alt="element" className='object-contain h-[100%] w-[100%]' />
                </div>
                {
                    resVerifyEmail.errorCode === "008005035025" ? <span className="text-md text-start sm:text-center">Code: {resVerifyEmail.errorCode} , {resVerifyEmail.data}</span>
                        :
                        <span className="text-sm text-start sm:text-center">
                            Welcome to The Space By Anoa , your email has been successfully verify. Click button below to open The Space By Anoa
                        </span>
                }

                <button onClick={() => navigate('/')} className={resVerifyEmail.isSuccess ? "w-full py-3 rounded-md  hover:bg-slate-600 bg-bm2" : "w-full py-3 rounded-md  hover:bg-slate-600 bg-bm1"}>Open Space</button>
            </div>
        </div>
    )
}
export default VerificationEmail;