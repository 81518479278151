import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import CryptoJS from "crypto-js";
import Signature from "../../../helpers/Signature";
import ErrorCode from "../../../helpers/errorcode.json";

const ThreadsNew = () => {
    const navigate = useNavigate();

    const loadThreadsNew = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : resultUUID.user.userindex,
            'tokenlogin' : resultUUID.user.usertokenlogin,
            'signature' : signature
        }

        let body = {}

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI+'/api/thread/v01/insertnew', body, {
            headers: dataHeaders
        });
        console.log('res_insertNew', response)

        if(response.data.status.status === 1){
            localStorage.setItem('tokentrans', response.data.status.tokentrans);
            navigate('/developer/threads/add');
        }

        if(response.data.status.status === 0){
            localStorage.setItem('tokentrans', 'TOKEN_NOT_EXIST');
            navigate('/developer/threads/add');
            response.data.status.message.forEach(element => {
                // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                // let replaceError = resultError.replace('###', element.codevariable);

                switch(element.code.substring(0,9)){
                    case ErrorCode.unauthenticated:
                        navigate('/login/developers');
                    break;
                    default:
                        break;
                }
            })
        }
    }

    useEffect(() => {
        loadThreadsNew();
        // eslint-disable-next-line
    }, []);
    return(
        <></>
    );
}

export default ThreadsNew;