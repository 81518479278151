import React, { lazy, useCallback, useEffect, Suspense, useState, useMemo } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CryptoJS from "crypto-js";
import './main_css/MainPage.css'
import MainHeader from './MainHeader';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Signature from '../../helpers/Signature';
import BarLoader from '../../components/loader/BarLoader';
import { SuspenseImg } from '../../helpers/ImageCache';
import io from 'socket.io-client';

const HomePageView = lazy(() => delayForDemo(import('./HomePage')));
const GamersPageView = lazy(() => delayForDemo(import('./GamersPage')));

function Loading() {
    return <BarLoader></BarLoader>
}

function delayForDemo(promise) {
    return new Promise(resolve => {
        setTimeout(resolve, 1500);
    }).then(() => promise);
}

export default function MainPage() {

    const navigate = useNavigate();
    const [userData, setUserData] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [searchValue, setSearchValue] = useState('');
    const [listGenre, setListGenre] = useState([]);

    const [imageIDCard, setImageIDCard] = useState(null);
    const [imageSelfie, setImageSelfie] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [configSocket, setConfigSocket] = useState(null);

    const [tokenTrans, setTokenTrans] = useState(null);
    const [topDeveloper, setTopDeveloper] = useState([]);
    const [topGamer, setTopGamer] = useState([]);
    const [faqToggle, setFaqToggle] = useState(false);
    const [faqClick, setFaqClick] = useState(false);
    const [detailFaqToggle, setDetailFaqToggle] = useState(false);
    const [idDetail, setIdDetail] = useState('');

    const [validationKYC, setValidationKYC] = useState({
        idCard : false,
        idCardInfo : '',
        selfie : false,
        selfiInfo : ''
    })

    const _mainHandleSearch = (value) => {
        setSearchValue(value);
        console.log('_mainHandleSearch', value)
    }

    const [listMenu, setLisMenu] = useState([
        {
            id: 1,
            label: 'Home',
            selected: true,
        },
        {
            id: 2,
            label: 'Explorer',
            selected: false,
        }
    ])
    const _handleMenu = (id) => {
        let newArr = [...listMenu]
        newArr.forEach((item) => {
            if (item.id !== id) {
                item.selected = false
            }
            else {
                item.selected = true
            }
        })
        if (newArr[0].selected) {
            setSearchValue('')
        }
        setLisMenu(newArr)
    }
    const navigateToLogin = () => {
        navigate('/mainLogin')
    }
    const fetchProfileAPI = useCallback(async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }
        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/user/v01/profile?id='+currentTimestamp, {
            headers: dataHeaders
        });
        if (response.data.status.status === 1) {
            setUserData(response.data.data)
        }
        else {
            navigate('/logout');
        }
        console.log('response_profile', response)


    }, [navigate])
    const fetchAPIGenre = async () => {
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI + '/api/homepage/v01/listgenre?id='+currentTimestamp, {
            headers: dataHeaders
        });
        try {
            console.log('res_listGenre', response)
            if (response.data.status.status === 1) {
                setListGenre(response.data.data)
            }
        }
        catch (err) {
            console.log('err', err)
        }
    }
    const MemoizedHomePage = useMemo(() => HomePageView, []);
    const MemoizedGamerPage = useMemo(() => GamersPageView, []);
    const MemoizedHeader = useMemo(() => MainHeader, []);

    useEffect(() => {
        let userRole = localStorage.getItem('userRole');
        console.log('userRole', userRole)
        if (userRole !== null) {
            setIsAuthenticated(userRole)
            fetchProfileAPI();
        }
        fetchAPIGenre();
    }, [fetchProfileAPI, navigate]);

    const activeHandle = async () => {
        setImageIDCard(null);
        setImageSelfie(null);
        
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature
        }

        let body = {};

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/activate/v01/insertnew', body, {
            headers: dataHeaders
        });

        if(response.data.status.status === 1){
            setTokenTrans(response.data.status.tokentrans);
            setModal(true);
        }

        console.log(response.data)
    }

    const handleUploadIDCard = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const imageDataUrl = event.target.result;
                setImageIDCard(imageDataUrl);
            };

            reader.readAsDataURL(file);
        }

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature,
            'tokentrans': tokenTrans
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/activate/v01/upload/idcard', formData, {
            headers: dataHeaders
        });

        if(response.data.status.status === 0){
            setImageIDCard(null);
            setValidationKYC({
                idCard : true,
                idCardInfo : response.data.status.message[0].errormessage
            });

            console.log(response.data.status.message[0].errormessage)
        }else{
            setValidationKYC({
                idCard : false,
                idCardInfo : ''
            });
        }    
    }

    const handleUploadSelfie = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const imageDataUrl = event.target.result;
                setImageSelfie(imageDataUrl);
            };

            reader.readAsDataURL(file);
        }

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature,
            'tokentrans': tokenTrans
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/activate/v01/upload/selfie', formData, {
            headers: dataHeaders
        });

        if(response.data.status.status === 0){
            setImageSelfie(null);
            setValidationKYC({
                selfie : true,
                selfiInfo : response.data.status.message[0].errormessage
            });

            console.log(response.data.status.message[0].errormessage)
        }else{
            setValidationKYC({
                selfie : false,
                selfiInfo : ''
            });
        }
    }


    const uploadActiveHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature,
            'tokentrans': tokenTrans
        }

        let body = {};

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/activate/v01/store', body, {
            headers: dataHeaders
        });

        if(response.data.status.status === 1){
            fetchProfileAPI();
            setModal(false);
            setModalInfo(true);
        }
    }

    useEffect(() => {
        const socketio = io('https://back.thespace.world');
        setConfigSocket(socketio);
    }, []);

    useEffect(() => {
        let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");

        if(getUUID){
            let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
                CryptoJS.enc.Utf8
            );
    
            let resultUUID = JSON.parse(atob(decrypt));
    
            if(configSocket !== null){
                configSocket.on(resultUUID.user.userindex, (data) => {
                    fetchProfileAPI()
                })
            }
        }
        
    }, [configSocket, fetchProfileAPI]);

    const LoadTopDeveloper = useCallback( async () => {
        let payloadSignature = []
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'signature' : signature
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI+'/api/homepage/v01/topdeveloper?id='+currentTimestamp, {
            headers : dataHeaders
        });

        setTopDeveloper(response.data.data);
    }, []);

    const LoadTopGamers = useCallback( async () => {
        let payloadSignature = []
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'signature' : signature
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAMEAPI+'/api/homepage/v01/topgamer?id='+currentTimestamp, {
            headers : dataHeaders
        });

        setTopGamer(response.data.data)
    }, []);

    useEffect(() => {
        LoadTopDeveloper();
        LoadTopGamers();
    }, [LoadTopDeveloper, LoadTopGamers]);

    const handleRedirectDeveloper = (value) => {
        navigate('/developers/'+value);
        let newArr = [...listMenu]
        newArr.forEach((item) => {
            if (item.id !== 1 || item.id !== 2) {
                item.selected = false
            }
            else {
                item.selected = true
            }
        })
        setLisMenu(newArr);
    }

    const handleRedirectGamer = (value) => {
        navigate('/gamer/'+value);   
        
        let newArr = [...listMenu]
        newArr.forEach((item) => {
            if (item.id !== 1 || item.id !== 2) {
                item.selected = false
            }
            else {
                item.selected = true
            }
        })
        setLisMenu(newArr);
    }

    const dataFAQDevelopers = [
        {
            'id' : 'developers-01',
            'title' : 'Bagaimana cara register Developer pada The Space?',
            'paragraf' : 'A tutorial for register as a Developer at The Space'
        },
        {
            'id' : 'developers-02',
            'title' : 'Bagaimana cara log in Developer pada The Space?',
            'paragraf' : 'A tutorial for login as a Developer at The Space'
        },
        {
            'id' : 'developers-03',
            'title' : 'Bagaimana cara Developer mengunggah Threads?',
            'paragraf' : 'A tutorial for Threads as a Developer at The Space'
        },
        {
            'id' : 'developers-04',
            'title' : 'Bagaimana cara Top Up pada The Space?',
            'paragraf' : 'A tutorial for Top Up as a Developer at The Space'
        },
        {
            'id' : 'developers-05',
            'title' : 'Apa itu Active Threads, Total Threads dan Average Scoring pada Dashboard Developer?',
            'paragraf' : 'A tutorial for Active Threads, Total Threads and Average Scoring a Developer at The Space'
        },
        {
            'id' : 'developers-06',
            'title' : 'Apa itu Threads List pada Dashboard Developer?',
            'paragraf' : 'A tutorial for Threads List a Developer at The Space'
        },
        {
            'id' : 'developers-07',
            'title' : 'Bagaimana menjadi TOP Developer?',
            'paragraf' : 'A tutorial for TOP Developer at The Space'
        },
        {
            'id' : 'developers-08',
            'title' : 'Apakah target review dari Developer dalam satu Threads dibatasi?',
            'paragraf' : 'A tutorial for Developer at The Space'
        },
        {
            'id' : 'developers-09',
            'title' : 'Bagaimana jika akun Freeze?',
            'paragraf' : 'A tutorial for Developer at The Space'
        },
        {
            'id' : 'developers-10',
            'title' : 'Bagaimana jika akun Developer Suspend?',
            'paragraf' : 'A tutorial for Developer at The Space'
        },
        {
            'id' : 'developers-11',
            'title' : 'Apa penyebab Blokir dapat terjadi pada akun Developer?',
            'paragraf' : 'A tutorial for Developer at The Space'
        }
    ];

    const dataFAQGamers = [
        {
            'id' : 'gamers-01',
            'title' : 'Bagaimana register Gamers pada The Space?',
            'paragraf' : 'A tutorial for register as a Gamers at The Space'
        },
        {
            'id' : 'gamers-02',
            'title' : 'Bagaimana cara login Gamers The Space?',
            'paragraf' : 'A tutorial for register as a Gamers at The Space'
        },
        {
            'id' : 'gamers-03',
            'title' : 'Bagaimana cara KYC Gamers pada The Space?',
            'paragraf' : 'A tutorial for KYC as a Gamers at The Space'
        },
        {
            'id' : 'gamers-04',
            'title' : 'Bagaimana cara saya mendapatkan token melalui review?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
        {
            'id' : 'gamers-05',
            'title' : 'Bagaiamana cara melaporkan Thread?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
        {
            'id' : 'gamers-06',
            'title' : 'Apa itu Total Review, Total Earning dan Balance pada profil Game The Space?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
        {
            'id' : 'gamers-07',
            'title' : 'Bagaimana jika akun Gamers mengalami Suspend?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
        {
            'id' : 'gamers-08',
            'title' : 'Apa penyebab Blokir dapat terjadi pada akun Gamers?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
    ];

    const dataDetailFAQ = [
        {
            'id' : 'developers-01',
            'title' : 'Bagaimana cara register Developer pada The Space?',
            'content' : 
            `<ul class='list-disc'>
                <li>Buka <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “Get Started” pada halaman The Space</li>
                <li>Klik “Register”</li>
                <li>Klik “Register as Developer”</li>
                <li>Isi Data Pendukung;</li>
                <li class='list-none'>
                    <ul class='list-decimal'>
                        <li>Nama Perusahaan</li>
                        <li>Username</li>
                        <li>Alamat Perusahaan</li>
                        <li>Email Perusahaan</li>
                        <li>Nomor Telepon</li>
                        <li>Set Password dan Confirm Password (password harus mengandung huruf kapital, huruf kecil, angka dan symbol)</li>
                    </ul>
                </li>
                <li>Klik “Register as Developer”</li>
                <li>Lalu cek email yang sudah didaftarkan untuk mendapatkan notifikasi dari The Space.</li>
            </ul>`
        },
        {
            'id' : 'developers-02',
            'title' : 'Bagaimana cara log in Developer pada The Space?',
            'content' :
            `<ul class="list-disc">
                <li>Buka <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “Get Started” pada halaman The Space</li>
                <li>Klik “Log in as Developer”</li>
                <li>Isi Email Developer yang sudah terdaftar pada akun The Space</li>
                <li>Isi Password yang dimiliki</li>
                <li>Klik “Login as Developer”</li>
            </ul>
            `
        },
        {
            'id' : 'developers-03',
            'title' : 'Bagaimana cara Developer mengunggah Threads?',
            'content' :
            `<ul class="list-disc">
                <li>Login pada halaman Developer di <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “New Threads” pada Dashboard Developer The Space</li>
                <li>Isi Basic Information;</li>
                <li class="list-none">
                    <ul class="list-decimal">
                        <li>Judul Thread</li>
                        <li>Thread Description</li>
                        <li>Demo Link (jika ada)</li>
                        <li>Genre Thread</li>
                        <li>Tanggal mulai Thread dan Tanggal akhir Thread (max. durasi 30 hari)</li>
                    </ul>
                </li>
                <li>Klik “Next”</li>
                <li>Isi Token Distribution;</li>
                <li class="list-none">    
                    <ul class="list-decimal">
                        <li>Review Max Word Count (max. 1000 kata)</li>
                        <li>Attach Artwork or Video of your Game (upload max 1 video)</li>
                        <li>Import link dari Youtube (jika ada)</li>
                        <li>Token Allocation</li>
                    </ul>
                </li>
            </ul>         
            `
        },
        {
            'id' : 'developers-04',
            'title' : 'Bagaimana cara Top Up pada The Space?',
            'content' :
            `<p>Top Up dapat dilakukan melalui wallet ANOA, maka pastikan sudah memiliki wallet ANOA di <a href="https://www.anoatoken.com/" target="_blank">https://www.anoatoken.com/</a></p>`
        },
        {
            'id' : 'developers-05',
            'title' : 'Apa itu Active Threads, Total Threads dan Average Scoring pada Dashboard Developer?',
            'content' :
            `<ul class="list-disc">
                <li>Active Threads yaitu Thread yang sedang berjalan/aktif pada The Space.</li>
                <li>Total Threads yaitu jumlah keseluruhan Thread Developer yang ada pada The Space.</li>
                <li>Average Scoring adalah skor rata-rata dari Review Gamers pada Thread Game Developer itu sendiri.</li>
            </ul>`
        },
        {
            'id' : 'developers-06',
            'title' : 'Apa itu Threads List pada Dashboard Developer?',
            'content' :
            `<p>Threads List memudahkan Developer dalam melihat Threads yang sudah di publish pada halaman The Space, dengan mencantumkan Judul Threads yang sudah dipublish, tanggal publish, tanggal akhir publish, total review yang didapat dan status dari threads tersebut.</p>`
        },
        {
            'id' : 'developers-07',
            'title' : 'Bagaimana menjadi TOP Developer?',
            'content' :
            `<p>TOP Developer adalah Developer yang memiliki Thread pada The Space yang mempunyai total review paling banyak dari para Gamers dan juga memiliki Average Scoring lebih tinggi dibandingkan Developer lain. Untuk menjadi TOP Developer, anda harus memiliki Thread yang menarik perhatian para Gamers agar dapat memberikan review pada Thread anda.</p>`
        },
        {
            'id' : 'developers-08',
            'title' : 'Apakah target review dari Developer dalam satu Threads dibatasi?',
            'content' :
            `<p>Dalam satu Thread jumlah review tidak dibatasi, semua tergantung dari keputusan Developer berapa banyak token yang dialokasikan dan jangka waktu yang diberikan Developer. Tetapi satu akun gamers hanya bisa melakukan satu review pada satu Thread.</p>`
        },
        {
            'id' : 'developers-09',
            'title' : 'Bagaimana jika akun Freeze?',
            'content' :
            `<p>Jika pengguna log in dengan kesalahan password 3x, akun akan ter-freeze secara otomatis dan tidak dapat melakukan log in akun. Silahkan klik “Forgot Password” dan ikuti langkah selanjutnya.</p>`
        },
        {
            'id' : 'developers-10',
            'title' : 'Bagaimana jika akun Developer Suspend?',
            'content' :
            `<p>Akun Developer akan ter-suspend jika adanya laporan report thread dari Gamers atau akun mengalami Freeze selama 3x dalam 24jam. Jika akun Developer ter-suspend, thread akan di takedown, allocation akan dikembalikan dan segala bentuk aktivitas pada akun Developer tidak dapat dilakukan, namun hanya dapat log in pada akun tersebut.</p>`
        },
        {
            'id' : 'developers-11',
            'title' : 'Apa penyebab Blokir dapat terjadi pada akun Developer?',
            'content' :
            `<p>Jika akun Developer sudah berkali-kali mengalami suspend, admin akan memblokir akun tersebut dan allocation balance pada The Space tidak dapat dikembalikan. Developer tidak akan bisa mengakses The Space, namun dapat mengakses Wallet ANOA.</p>`
        },
        {
            'id' : 'gamers-01',
            'title' : 'Bagaimana register Gamers pada The Space?',
            'content' : 
            `<ul class='list-disc'>
                <li>Buka <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “Get Started” pada halaman The Space</li>
                <li>Klik “Register”</li>
                <li>Klik “Register as Gamers”</li>
                <li>Isi Data Pendukung;</li>
                <li class='list-none'>
                    <ul class='list-decimal'>
                        <li>Username</li>
                        <li>Nama Lengkap</li>
                        <li>Email</li>
                        <li>No Telepon</li>
                        <li>Set Password dan Confirm Password (password harus mengandung huruf kapital, huruf kecil, angka dan symbol)</li>
                    </ul>
                </li>
                <li>Klik “Register as Gamers”</li>
                <li>Lalu cek email yang sudah didaftarkan untuk mendapatkan notifikasi dari The Space.</li>
            </ul>`
        },
        {
            'id' : 'gamers-02',
            'title' : 'Bagaimana cara log in Gamers The Space?',
            'content' :
            `<ul class="list-disc">
                <li>Buka <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “Get Started” pada halaman The Space</li>
                <li>Klik “Log in as Gamers”</li>
                <li>Isi Email Gamers yang sudah terdaftar pada akun The Space</li>
                <li>Isi Password yang dimiliki</li>
                <li>Klik “Login as Gamers”</li>
            </ul>
            `
        },
        {
            'id' : 'gamers-03',
            'title' : 'Bagaimana cara KYC Gamers pada The Space?',
            'content' :
            `<ul class="list-disc">
                <li>Login pada halaman Gamers di <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Pada dashboard Gamers akan muncul pemberitahuan KYC dikiri bawah dengan tulisan “Your Account Has Not Been Activated”, lalu klik “Activate Account”</li>
                <li>Upload ID Card (KTP)</li>
                <li>Upload Selfie with ID Card</li>
                <li>Klik “Activate Account”</li>
                <li>Setelah mengunggah, harap menunggu untuk di Approve.</li>
            </ul>`
        },
        {
            'id' : 'gamers-04',
            'title' : 'Bagaimana cara saya mendapatkan token melalui review?',
            'content' :
            `<ul class="list-disc">
                <li>Pastikan sudah memiliki wallet ANOA di <a href="https://anoatoken.com/" target="_blank">https://anoatoken.com/</a></li>
                <li>Lalu hubungkan akun Anoa dengan The Space</li>
                <li>Jika sudah terhubung, buka Dashboard The Space Gamers</li>
                <li>Klik Thread yang akan di review pada halaman dashboard Gamers</li>
                <li>Pada halaman detail Thread, terlihat
                Anda dapat melihat semua detail Thread seperti Media, Deskripsi, Review dari Gamers lain, Link Demo Game, dan juga berapa banyak Token yang akan Anda dapatkan jika berkontribusi dalam mereview Thread tersebut.</li>
                <li>Jika Anda ingin berkontribusi klik Review.</li>
                <li>Dalam form ini, ada 3 bagian:
                    <ul class="list-decimal">
                        <li>Review: Threads memiliki jumlah kata minimum yang berbeda dengan Threads lainnya, maka isi review sesuai dengan yang ditentukan dalam Threads tersebut</li>
                        <li>Rating: Berikan review dengan menambahkan rating dengan 1 sampai 5</li>
                        <li>Upload Media: Dalam review sebuah Thread, dapat ditambah dengan unggahan foto maupun video Demo Game pada Thread tersebut. Memuat media merupakan kolom opsional dan dapat menyampaikan ulasan tanpa mengisi kolom ini. Namun ada tambahan reward token yang akan di dapatkan jika mengisi kolom ini.</li>
                    </ul>
                </li>
                <li>Setelah membuat review klik submit dan review akan diperiksa kembali oleh Admin</li>
                <li>Admin dapat menyetujui ataupun menolak review dengan ketentuan;
                    <ul class="list-decimal">
                        <li>Review tidak menggunakan kata-kata kasar/kotor dan tidak mengandung SARA</li>
                        <li>Review harus sesuai dengan Demo Game yang dipilih</li>
                    </ul>
                </li>
                <li>Jika ulasan disetujui oleh Admin, maka Token Gratis dapat dilihat pada menu Profil</li>
                <li>Jika ingin memeriksa status pemeriksaan ulasan;
                    <ul class="list-decimal">
                        <li>Klik Profil</li>
                        <li>Klik Total Review</li>
                    </ul>
                </li>
            </ul>`
        },
        {
            'id' : 'gamers-05',
            'title' : 'Bagaiamana cara melaporkan Thread?',
            'content' :
                `<ul class="list-disc">
                <li>Pada Dashboard The Space, pilih Thread yang akan dilaporkan</li>
                <li>Klik “Review”</li>
                <li>Klik “Report Thread”</li>
                <li>Lalu akan diarahkan untuk menghubungi Customer Service melalui Email atau Whatsapp yang tersedia.</li>
                <li>Masukkan keluhan terhadap Thread yang akan dilaporkan tersebut</li>
            </ul>`
        },
        {
            'id' : 'gamers-06',
            'title' : 'Apa itu Total Review, Total Earning dan Balance pada profil Game The Space?',
            'content' :
            `<ul class="list-disc">
                <li>Total Review adalah total Thread yang sudah di review oleh Gamers</li>
                <li>Total Earning adalah jumlah token yang didapatkan Gamers setelah mereview Thread</li>
                <li>Balance adalah hasil semua token yang didapatkan dari Developer untuk Gamers</li>
            </ul>`
        },
        {
            'id' : 'gamers-07',
            'title' : 'Bagaimana jika akun Gamers mengalami Suspend?',
            'content' :
            `<p>Jika Gamers melakukan submit review yang tidak sesuai pada Thread yang akan direview selama 3x, admin akan melakukan suspend pada akun Gamers. Hal tersebut membuat Gamers dapat log in namun tidak dapat melakukan segala bentuk aktivitas didalamnya.</p>`
        },
        {
            'id' : 'gamers-08',
            'title' : 'Apa penyebab Blokir dapat terjadi pada akun Gamers?',
            'content' :
            `<p>Jika Gamers sering medapatkan akun tersuspend, akun tersebut akan di blokir oleh sistem, akibatnya Gamers tidak dapat mengakses The Space kembali, namun masih bisa mengakses Wallet ANOA.</p>`
        },
    ];
    

    var filterDetail = dataDetailFAQ.filter(item => item.id === idDetail)[0];

    const handleDetail = (id) => {
        setIdDetail(id);
        setFaqClick(false);
        setDetailFaqToggle(true);
    }

    return (

        <div className="relative w-full h-full">
            <Suspense fallback={<BarLoader />}>
                <SuspenseImg alt="element" src={process.env.PUBLIC_URL + '/images/main/mainBackground.svg'} className="imageBGContainer" />
                <div className='absolute top-0 left-0 w-full h-full min-h-full'>
                    <MemoizedHeader activeKyc={activeHandle} menu={listMenu} onChangeSearch={_mainHandleSearch} isAuthenticated={isAuthenticated} userData={userData} profile={fetchProfileAPI}/>
                    <div className='flex w-[100%] h-[90%]'>
                        <div className="sm:flex flex-col justify-between bg-[#03002C] px-[1.5vw] py-[1.5vw] hidden w-[20%]">
                            <div className='categoryButtonContainer sm-[100px]'>
                                {
                                    listMenu.map((key, index) => {
                                        return (
                                            key.selected ?
                                                <button key={index} className={'categoryButtonChoosen'} onClick={() => _handleMenu(key.id)}>
                                                    <span className='text-white'>{key.label}</span>
                                                </button>
                                                :
                                                <button key={index} className={'categoryButtonDefault'} onClick={() => _handleMenu(key.id)}>
                                                    <span className='text-white'>{key.label}</span>
                                                </button>
                                        )
                                    })
                                }
                            </div>
                            {
                                !isAuthenticated ?
                                    <button className='StartButton' onClick={navigateToLogin}>Get Started</button>
                                    :
                                    null
                            }
                            {console.log(userData)}
                            {isAuthenticated ? 
                            userData.useraccounttype !== 'developer' ?
                            userData.userstatus !== 'ACTIVE' ?
                            <div className='bg-[#FFF9F2] p-[16px] rounded-[16px] border-2 border-[#CD7B2E] border-dashed'>
                                <h1 className='text-[#CD7B2E] text-[20px] font-semibold leading-[28px] pb-[12px]'>Your account has not been activated</h1>
                                {userData.userstatus === 'REJECTED' || userData.userstatus === 'NOT ACTIVE'?
                                <p className='text-[#707375] text-[14px]'>Please activate your account by completing KYC verification to enjoy all the SPACE features</p>
                                :
                                <p className='text-[#707375] text-[14px]'>Thank you for completing the KYC verification. Your KYC will be reviewed by our admin.</p>
                                }
                                {userData.userstatus === 'REJECTED' || userData.userstatus === 'NOT ACTIVE'?
                                <button className='bg-[#CD7B2E] w-full text-center py-[16px] rounded-[12px] text-[16px] mt-[32px] text-white font-semibold' onClick={activeHandle}>Activate Account</button>
                                :
                                <button className='border-2 border-[#CD7B2E] w-full text-center py-[16px] rounded-[12px] text-[16px] mt-[32px] text-[#CD7B2E] font-semibold cursor-default'>ON REVIEW</button>
                                 }
                            </div>: '' : '' : '' }
                        </div>
                        <Suspense fallback={<Loading />}>
                            <MemoizedHomePage show={listMenu[0].selected} listMenu={listMenu} />
                            <MemoizedGamerPage show={listMenu[1].selected} searchValue={searchValue} isAuthenticated={isAuthenticated} userData={userData} listGenre={listGenre} topdeveloper={topDeveloper} topgamer={topGamer} redirectdeveloperpage={handleRedirectDeveloper} redirectGamerPage={handleRedirectGamer}/>
                        </Suspense>

                    </div>
                </div>
            </Suspense>
            <div className='fixed bottom-5 right-8 bg-[#0E2E56] p-[16px] rounded-[360px] cursor-pointer' onClick={() => setFaqClick(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.22766 9.00146C8.77678 7.83627 10.2584 7.00146 12.0001 7.00146C14.2092 7.00146 16.0001 8.34461 16.0001 10.0015C16.0001 11.4009 14.7224 12.5765 12.9943 12.9081C12.4519 13.0121 12.0001 13.4492 12.0001 14.0015M12 17.0015H12.01M21 12.0015C21 16.972 16.9706 21.0015 12 21.0015C7.02944 21.0015 3 16.972 3 12.0015C3 7.0309 7.02944 3.00146 12 3.00146C16.9706 3.00146 21 7.0309 21 12.0015Z" stroke="#D7DCDF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            {faqClick ?
            <div className='bg-white fixed bottom-5 right-24 w-[437px] rounded-[16px] p-[16px]'>
                <div className='flex flex-row justify-between mb-[32px]'>
                    <div>
                        <h1 className='text-[28px]'>Hey, SpaceExplorer👋</h1>
                        <h1 className='text-[28px]'>How can we help?</h1>
                    </div>
                    <div className='cursor-pointer' onClick={() => setFaqClick(false)}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 24L24 8M8 8L24 24" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className='flex flex-row gap-2 mb-[30px]'>
                    <button className="text-[12px] p-[10px] rounded-[6px] font-semibold" style={{ backgroundColor: faqToggle ? '#EBF0F4' : '#4C4DDC', color: faqToggle ? '#989B9D' : '#ffffff'}} onClick={() => setFaqToggle(false)}>Developers</button>
                    <button className="text-[12px] p-[10px] rounded-[6px] font-semibold" style={{ backgroundColor: faqToggle ? '#4C4DDC' : '#EBF0F4', color: faqToggle ? '#ffffff' : '#989B9D'}} onClick={() => setFaqToggle(true)}>Gamers</button>
                </div>
                <div className='mb-[30px] flex flex-col gap-2 overflow-auto max-h-[300px]'>
                    {faqToggle === false ?
                    dataFAQDevelopers.map((result) => (
                        <div className='w-full border-2 border-[#E4E8EC] p-[12px] rounded-[12px] cursor-pointer shadow-sm' key={result.id} onClick={() => handleDetail(result.id)}>
                            <div className='flex flex-row items-center justify-between'>
                                <div>
                                    <h1 className='text-[16px] font-semibold'>{result.title}</h1>
                                    <span className='text-[#4B5563] text-[14px]'>{result.paragraf}</span>
                                </div>
                                <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 5.00146L16 12.0015L9 19.0015" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )) : ''}

                    {faqToggle === true ?
                    dataFAQGamers.map((result) => (
                        <div className='w-full border-2 border-[#E4E8EC] p-[12px] rounded-[12px] cursor-pointer shadow-sm' key={result.id} onClick={() => handleDetail(result.id)}>
                            <div className='flex flex-row items-center justify-between'>
                                <div>
                                    <h1 className='text-[16px] font-semibold'>{result.title}</h1>
                                    <span className='text-[#4B5563] text-[14px]'>{result.paragraf}</span>
                                </div>
                                <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 5.00146L16 12.0015L9 19.0015" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )) : ''}
                </div>
                <a href="https://api.whatsapp.com/message/ZS7PKPEKCPGVC1?autoload=1&app_absent=0" target="_blank" className='no-underline'>
                    <div className='bg-[#F0F3FF] p-[12px] rounded-[12px] cursor-pointer'>
                        <div className='flex flex-row items-center gap-2'>
                            <img src={process.env.PUBLIC_URL+'/images/whatsapp-icon.png'} alt='whatsapp-icon'/>
                            <div className='flex flex-col w-full'>
                                <span className='text-[#4C4DDC] font-semibold'>Send us a message on Whatsapp!</span>
                                <span className='text-[#707375]'>We typically reply in a few minutes</span>
                            </div>
                            <div>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5146_4002)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9046 22.1976L21.1466 7.16437C21.6004 5.55796 20.1591 4.11667 18.5517 4.56945L3.51845 8.81146C3.15195 8.91489 2.81618 9.11221 2.5449 9.38356C2.27363 9.65492 2.07642 9.99075 1.97311 10.3573C1.8698 10.7238 1.86403 11.1081 1.95638 11.4716C2.04872 11.835 2.23594 12.1648 2.49919 12.4277L13.2894 23.2179C13.5523 23.4811 13.882 23.6683 14.2455 23.7607C14.6089 23.853 14.9932 23.8472 15.3598 23.7439C15.7263 23.6406 16.0621 23.4434 16.3335 23.1721C16.6048 22.9009 16.8022 22.5651 16.9056 22.1986L16.9046 22.1976ZM12.6996 13.9814L9.49989 19.4284L6.28869 16.2172L11.7337 13.0155C12.3754 12.6395 13.0756 13.3396 12.6986 13.9804L12.6996 13.9814Z" fill="#4C4DDC"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5146_4002">
                                            <rect width="24" height="24" fill="white" transform="translate(0 0.600342)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </a>
            </div> : '' }
            {detailFaqToggle ?
            <div className='bg-white fixed bottom-5 right-24 w-[437px] rounded-[16px]'>
                <div className='border-b-2 border-[#EBF0F4]'>
                    <div className='flex flex-row justify-between p-[16px]'>
                        <div onClick={() => { setDetailFaqToggle(false); setFaqClick(true) }} className='cursor-pointer'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18.9985L8 11.9985L15 4.99854" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div onClick={() => { setDetailFaqToggle(false); setFaqClick(false) }} className='cursor-pointer'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 17.999L18 5.99902M6 5.99902L18 17.999" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='p-[16px]'>
                    <div className='mb-[48px]'>
                        {filterDetail ? <>
                        <h1 className='text-[28px] pb-[48px]'>{filterDetail.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: filterDetail.content }} className='overflow-auto max-h-[200px]'></div>
                        </> : '' }
                    </div>
                    <a href="https://api.whatsapp.com/message/ZS7PKPEKCPGVC1?autoload=1&app_absent=0" target="_blank" className='no-underline'>
                        <div className='bg-[#F0F3FF] p-[12px] rounded-[12px] cursor-pointer'>
                            <div className='flex flex-row items-center gap-2'>
                                <img src={process.env.PUBLIC_URL+'/images/whatsapp-icon.png'} alt='whatsapp-icon'/>
                                <div className='flex flex-col w-full'>
                                    <span className='text-[#4C4DDC] font-semibold'>Send us a message on Whatsapp!</span>
                                    <span className='text-[#707375]'>We typically reply in a few minutes</span>
                                </div>
                                <div>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_5146_4002)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9046 22.1976L21.1466 7.16437C21.6004 5.55796 20.1591 4.11667 18.5517 4.56945L3.51845 8.81146C3.15195 8.91489 2.81618 9.11221 2.5449 9.38356C2.27363 9.65492 2.07642 9.99075 1.97311 10.3573C1.8698 10.7238 1.86403 11.1081 1.95638 11.4716C2.04872 11.835 2.23594 12.1648 2.49919 12.4277L13.2894 23.2179C13.5523 23.4811 13.882 23.6683 14.2455 23.7607C14.6089 23.853 14.9932 23.8472 15.3598 23.7439C15.7263 23.6406 16.0621 23.4434 16.3335 23.1721C16.6048 22.9009 16.8022 22.5651 16.9056 22.1986L16.9046 22.1976ZM12.6996 13.9814L9.49989 19.4284L6.28869 16.2172L11.7337 13.0155C12.3754 12.6395 13.0756 13.3396 12.6986 13.9804L12.6996 13.9814Z" fill="#4C4DDC"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_5146_4002">
                                                <rect width="24" height="24" fill="white" transform="translate(0 0.600342)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            : '' }
            {modal ? 
            <div className="fixed inset-0 w-full h-full bg-bg1 backdrop-blur-[3px] flex flex-col items-center justify-center" >
                <div className="bg-[#1B2026] w-[700px] p-[24px] rounded-xl shadow-2xl flex flex-col overflow-scroll">
                    <div className="flex flex-row gap-5 items-center justify-start w-full mb-[28px]">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_4634_3718)">
                                <circle cx="20" cy="20" r="20" fill="#D0BCFF"/>
                                <path d="M18 14H13C11.8954 14 11 14.8954 11 16V25C11 26.1046 11.8954 27 13 27H27C28.1046 27 29 26.1046 29 25V16C29 14.8954 28.1046 14 27 14H22M18 14V13C18 11.8954 18.8954 11 20 11C21.1046 11 22 11.8954 22 13V14M18 14C18 15.1046 18.8954 16 20 16C21.1046 16 22 15.1046 22 14M17 22C18.1046 22 19 21.1046 19 20C19 18.8954 18.1046 18 17 18C15.8954 18 15 18.8954 15 20C15 21.1046 15.8954 22 17 22ZM17 22C18.3062 22 19.4174 22.8348 19.8292 24M17 22C15.6938 22 14.5825 22.8348 14.1707 24M23 19H26M23 23H25" stroke="#381E72" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_4634_3718">
                                    <rect width="40" height="40" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className='flex-initial w-full'>
                            <h1 className='text-[#E6E1E5] text-[28px] font-normal'>Activate Account</h1>
                            <p className='text-[#BABEC1]'>It takes only a moment to fill in the data below and complete the KYC verification</p>
                        </div>
                    </div>
                    <div className='mb-[24px]'>
                        <h1 className='text-[#EBF0F4] text-[20px] font-semibold'>Upload ID Card</h1>
                        <p className='text-[#CAC4D0]'>Make sure you upload photos that are clearly visible and not cropped</p>
                        
                        <div className='bg-[#23282E] w-full mt-[12px] flex flex-col items-center justify-center rounded-[12px] border-2 border-dashed cursor-pointer' 
                        onClick={() => document.querySelector(`input[name=idcard]`).click()}>
                            <input type='file' className='hidden' name="idcard" onChange={(e) => handleUploadIDCard(e)}/>
                            {validationKYC.idCard ?
                            <div className='flex flex-col items-center justify-center py-[64px]'>
                                <svg width="92" height="81" viewBox="0 0 92 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3691 53.8334L32.9273 38.5474C35.9178 35.9439 40.7664 35.9439 43.7569 38.5474L61.3151 53.8334M53.6574 47.1667L59.7291 41.8808C62.7196 39.2773 67.5682 39.2773 70.5587 41.8808L76.6304 47.1667M53.6574 27.1667H53.6957M23.0268 67.1667H68.9727C73.2019 67.1667 76.6304 64.1819 76.6304 60.5V20.5C76.6304 16.8181 73.2019 13.8334 68.9727 13.8334H23.0268C18.7976 13.8334 15.3691 16.8181 15.3691 20.5V60.5C15.3691 64.1819 18.7976 67.1667 23.0268 67.1667Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                    <rect x="56" y="44.5" width="32" height="32" rx="16" fill="#4C4DDC"/>
                                    <path d="M64.3423 63.8333L64.3423 64.6666C64.3423 66.0473 65.628 67.1666 67.2139 67.1666L76.786 67.1666C78.3719 67.1666 79.6576 66.0473 79.6576 64.6666L79.6576 63.8333M75.8288 57.1666L71.9999 53.8333M71.9999 53.8333L68.1711 57.1666M71.9999 53.8333L71.9999 63.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <p className='text-white'>Drag files here or <span className='text-[#8182E6]'>browse</span></p>
                                {validationKYC.idCard ?
                                <span className='text-red-600'>{validationKYC.idCardInfo}</span>
                                : 
                                <span className='text-white'>Accepted file type .png, .jpg, .jpeg </span> }
                            </div>
                            :
                            imageIDCard != null ?
                            <div className='relative'>
                                <img src={imageIDCard} className='h-[300px]'/>
                                <div className='bg-white absolute top-0 right-0 p-2 rounded-full' onClick={() => setImageIDCard(null)}>
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.999 5.83333L15.2763 15.9521C15.214 16.8243 14.4882 17.5 13.6138 17.5H6.71755C5.84316 17.5 5.11742 16.8243 5.05512 15.9521L4.33236 5.83333M8.49902 9.16667V14.1667M11.8324 9.16667V14.1667M12.6657 5.83333V3.33333C12.6657 2.8731 12.2926 2.5 11.8324 2.5H8.49902C8.03879 2.5 7.66569 2.8731 7.66569 3.33333V5.83333M3.49902 5.83333H16.8324" stroke="#CB3A31" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                            :
                            <div className='flex flex-col items-center justify-center py-[64px]'>
                                <svg width="92" height="81" viewBox="0 0 92 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3691 53.8334L32.9273 38.5474C35.9178 35.9439 40.7664 35.9439 43.7569 38.5474L61.3151 53.8334M53.6574 47.1667L59.7291 41.8808C62.7196 39.2773 67.5682 39.2773 70.5587 41.8808L76.6304 47.1667M53.6574 27.1667H53.6957M23.0268 67.1667H68.9727C73.2019 67.1667 76.6304 64.1819 76.6304 60.5V20.5C76.6304 16.8181 73.2019 13.8334 68.9727 13.8334H23.0268C18.7976 13.8334 15.3691 16.8181 15.3691 20.5V60.5C15.3691 64.1819 18.7976 67.1667 23.0268 67.1667Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                    <rect x="56" y="44.5" width="32" height="32" rx="16" fill="#4C4DDC"/>
                                    <path d="M64.3423 63.8333L64.3423 64.6666C64.3423 66.0473 65.628 67.1666 67.2139 67.1666L76.786 67.1666C78.3719 67.1666 79.6576 66.0473 79.6576 64.6666L79.6576 63.8333M75.8288 57.1666L71.9999 53.8333M71.9999 53.8333L68.1711 57.1666M71.9999 53.8333L71.9999 63.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <p className='text-white'>Drag files here or <span className='text-[#8182E6]'>browse</span></p>
                                <span className='text-white'>Accepted file type .png, .jpg, .jpeg </span>
                            </div>
                            }
                        </div>
                    </div>
                    <div className='mb-[24px]'>
                        <h1 className='text-[#EBF0F4] text-[20px] font-semibold'>Upload Selfie with ID Card</h1>
                        <p className='text-[#CAC4D0]'>Make sure you upload photos that are clearly visible and not cropped</p>
                        
                        <div className='bg-[#23282E] w-full mt-[12px] flex flex-col items-center justify-center rounded-[12px] border-2 border-dashed cursor-pointer'
                        onClick={() => document.querySelector(`input[name=selfie]`).click()}>
                            <input type='file' className='hidden' name="selfie" onChange={(e) => handleUploadSelfie(e)}/>
                            {validationKYC.selfie ?
                            <div className='flex flex-col items-center justify-center py-[64px]'>
                                <svg width="92" height="81" viewBox="0 0 92 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3691 53.8334L32.9273 38.5474C35.9178 35.9439 40.7664 35.9439 43.7569 38.5474L61.3151 53.8334M53.6574 47.1667L59.7291 41.8808C62.7196 39.2773 67.5682 39.2773 70.5587 41.8808L76.6304 47.1667M53.6574 27.1667H53.6957M23.0268 67.1667H68.9727C73.2019 67.1667 76.6304 64.1819 76.6304 60.5V20.5C76.6304 16.8181 73.2019 13.8334 68.9727 13.8334H23.0268C18.7976 13.8334 15.3691 16.8181 15.3691 20.5V60.5C15.3691 64.1819 18.7976 67.1667 23.0268 67.1667Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                    <rect x="56" y="44.5" width="32" height="32" rx="16" fill="#4C4DDC"/>
                                    <path d="M64.3423 63.8333L64.3423 64.6666C64.3423 66.0473 65.628 67.1666 67.2139 67.1666L76.786 67.1666C78.3719 67.1666 79.6576 66.0473 79.6576 64.6666L79.6576 63.8333M75.8288 57.1666L71.9999 53.8333M71.9999 53.8333L68.1711 57.1666M71.9999 53.8333L71.9999 63.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <p className='text-white'>Drag files here or <span className='text-[#8182E6]'>browse</span></p>
                                {validationKYC.selfie ?
                                <span className='text-red-600'>{validationKYC.selfiInfo}</span>
                                : 
                                <span className='text-white'>Accepted file type .png, .jpg, .jpeg </span> }
                                
                            </div>
                            :
                            imageSelfie != null ?
                            <div className='relative'>
                                <img src={imageSelfie} className='h-[300px]'/>
                                <div className='bg-white absolute top-0 right-0 p-2 rounded-full' onClick={() => setImageSelfie(null)}>
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.999 5.83333L15.2763 15.9521C15.214 16.8243 14.4882 17.5 13.6138 17.5H6.71755C5.84316 17.5 5.11742 16.8243 5.05512 15.9521L4.33236 5.83333M8.49902 9.16667V14.1667M11.8324 9.16667V14.1667M12.6657 5.83333V3.33333C12.6657 2.8731 12.2926 2.5 11.8324 2.5H8.49902C8.03879 2.5 7.66569 2.8731 7.66569 3.33333V5.83333M3.49902 5.83333H16.8324" stroke="#CB3A31" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                            :
                            <div className='flex flex-col items-center justify-center py-[64px]'>
                                <svg width="92" height="81" viewBox="0 0 92 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3691 53.8334L32.9273 38.5474C35.9178 35.9439 40.7664 35.9439 43.7569 38.5474L61.3151 53.8334M53.6574 47.1667L59.7291 41.8808C62.7196 39.2773 67.5682 39.2773 70.5587 41.8808L76.6304 47.1667M53.6574 27.1667H53.6957M23.0268 67.1667H68.9727C73.2019 67.1667 76.6304 64.1819 76.6304 60.5V20.5C76.6304 16.8181 73.2019 13.8334 68.9727 13.8334H23.0268C18.7976 13.8334 15.3691 16.8181 15.3691 20.5V60.5C15.3691 64.1819 18.7976 67.1667 23.0268 67.1667Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                    <rect x="56" y="44.5" width="32" height="32" rx="16" fill="#4C4DDC"/>
                                    <path d="M64.3423 63.8333L64.3423 64.6666C64.3423 66.0473 65.628 67.1666 67.2139 67.1666L76.786 67.1666C78.3719 67.1666 79.6576 66.0473 79.6576 64.6666L79.6576 63.8333M75.8288 57.1666L71.9999 53.8333M71.9999 53.8333L68.1711 57.1666M71.9999 53.8333L71.9999 63.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <p className='text-white'>Drag files here or <span className='text-[#8182E6]'>browse</span></p>
                                <span className='text-white'>Accepted file type .png, .jpg, .jpeg </span>
                            </div>
                            }
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-3'>
                        <button className='w-full border-2 border-[#E2E3FF] py-[15px] rounded-[12px] text-white text-[16px]' onClick={() => setModal(false)}>Cancel</button>
                        {imageIDCard !== null || imageSelfie !== null ?
                        <button className='w-full bg-bgw3 border-2 border-blue-400 py-[15px] rounded-[12px] text-white text-[16px]' onClick={uploadActiveHandle}>Activate Account</button>
                        :
                        <button className='w-full bg-[#353A40] border-2 border-[#353A40] py-[15px] rounded-[12px] text-white text-[16px]' disabled>Activate Account</button>
                        }
                    </div>
                </div>
            </div>
            : '' }

            {modalInfo ? <>
            <div className="bg-bg1 backdrop-blur-[3px] fixed z-10 inset-0"></div>
            <div className="fixed inset-0 z-20">
                <div className="relative w-full h-full ">
                    <div className="absolute translate-y-[-50%] top-[50%] w-full">
                        <div className="max-w-[725px] m-auto">
                            <div className="mb-[-100px] w-full">
                                <img src={process.env.PUBLIC_URL + '/images/component-4.png'} alt="element" aria-hidden className="block ml-auto" />
                            </div>
                            <div className="bg-[#000032] flex flex-col gap-6 rounded-rd1 px-[40px] py-[60px]">
                                <h3 className="text-white text-[48px]">Success!</h3>
                                <p className="text-white text-[20px]">Your document successfully uploaded!</p>

                                <p className="text-[#989B9D]">Your data is being reviewed. Estimated time 1 day, maximum 3 days.</p>
                                
                                <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px] m-auto block" onClick={() => setModalInfo(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </> : ''}
        </div>
    )
}