import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { SuspenseImg } from "../../helpers/ImageCache";
import BarLoader from "../../components/loader/BarLoader";
import Signature from "../../helpers/Signature";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ErrorCode from "../../helpers/errorcode.json";
import Swal from 'sweetalert2'

const Gamer = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShowntwo, setPasswordShowntwo] = useState(false);
    const [errorPassword, setErrorPassword] = useState('');
    const [modal, setModal] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const inputPassword = useRef();
    const inputPasswordConfirm = useRef();

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const togglePasswordtwo = () => {
        setPasswordShowntwo(!passwordShowntwo);
    };

    const [count, setCount] = useState(5);

    useEffect(() => {
        let intervalId;

        if (modal) {
            intervalId = setInterval(() => {
                setCount(count => count - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [modal]);

    useEffect(() => {
        if (count === 0) {
            navigate('/')
            console.log('Countdown selesai!');
        }
    }, [count]);

    const CheckId = useCallback( async () => {
        let payloadSignature = [
            'WEBSITE',
            'PASSS',
            id,
            'developer'
        ];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature
        }

        let body = {
            'verificationcode': id
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/checkverificationcode', body, {
            headers: dataHeaders
        });

        if(response.data.status.status === 0){
            navigate('/blank');
        }
    }, [id]);

    useEffect(() => {
        CheckId();
    }, [CheckId]);

    const newPasswordHandle = async (e) => {
        e.preventDefault();

        let payloadSignature = [
            'WEBSITE',
            'PASSS',
            inputPassword.current.value,
            'developer'
        ];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature
        }

        let body = {
            'useremailverificationcode': id,
            'userpasswordnew': inputPassword.current.value,
            'userpasswordnewconfirm' : inputPasswordConfirm.current.value
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/newpassword', body, {
            headers: dataHeaders
        });

        console.log(response.data);

        if (response.data.status.status === 1) {
            setModal(true);
            // Swal.fire({
            //     title: "Success",
            //     text: "Your password updated successfuly, please wait 2 seconds, you will be redirected to login",
            //     icon: "success",
            //     showConfirmButton: false
            // });

            // setTimeout(() => {
            //     Swal.closeModal();
            //     navigate('/login/gamers')
            // }, 2000);
        }

        if (response.data.status.status === 0) {
            response.data.status.message.forEach(element => {
                let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                let replaceError = resultError.replace('###', element.codevariable);
                switch (element.code.substring(0, 9)) {
                    case ErrorCode.password:
                        setErrorPassword(replaceError);
                        break;
                    default:
                        inputPassword.current.value = '';
                        break;
                }
            });
        }
    }

    return(
        <>
        {modal ?
        <>
        <div className="bg-bg1 backdrop-blur-[3px] fixed z-10 inset-0"></div>
        <div className="fixed inset-0 z-20">
            <div className="relative w-full h-full ">
                <div className="absolute translate-y-[-50%] top-[50%] w-full">
                    <div className="max-w-[725px] m-auto">
                        <div className="mb-[-100px] w-full">
                            <img src={process.env.PUBLIC_URL + '/images/component-4.png'} alt="element" aria-hidden className="block ml-auto" />
                        </div>
                        <div className="bg-[#000032] flex flex-col gap-6 rounded-rd1 px-[40px] py-[60px]">
                            <h3 className="text-white text-[48px]">Success!</h3>
                            <p className="text-white text-[20px]">Reset password success</p>

                            <p className="text-[#989B9D]">We have successfully updated your account password</p>

                            <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px] m-auto block" onClick={() => navigate('/')}>Close</button>

                            <span className="text-white text-center block">Redirecting you in {count}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div></> : '' }
        <div className="w-full h-full overflow-hidden">
            <Suspense fallback={<BarLoader />}>
                <SuspenseImg alt="element" src={process.env.PUBLIC_URL + '/images/bg-1.png'} className="w-full h-full overflow-hidden bg-no-repeat bg-cover" />
                
                    <div className="absolute top-0 left-0 w-full h-screen px-6 m-auto overflow-hidden">
                        <div className="relative float-right w-full h-full max-w-6xl">
                            <div className="absolute translate-y-[-50%] top-[50%] w-full">
                                <img src={process.env.PUBLIC_URL + '/images/component-2.png'} alt="element" className="float-left animate-wiggle-slow" />
                                <img src={process.env.PUBLIC_URL + '/images/component-3.png'} alt="element" className="float-right mt-[-450px] animate-wiggle-slow " />
                            </div>
                        </div>

                        <div className="w-full h-full relative max-w-[550px] ">
                            <div className="absolute translate-y-[-50%] max-h-[100vh] overflow-auto top-[50%] w-full">
                                <h3 className="text-white text-[36px]">Reset Password</h3>
                                <p className="text-[#989B9D]">Please enter your registered email and we’ll send you an reset password instruction.</p>

                                <div className="mt-10">
                                    <form onSubmit={(e) =>newPasswordHandle(e)}>
                                        <div className="flex flex-col gap-5 mb-3">
                                            <div className="flex-auto">
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">New Password</label>
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={togglePassword} >
                                                        {!passwordShown ?
                                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.5 3L7.08916 6.58916M21.5 21L17.9112 17.4112M14.3749 18.8246C13.7677 18.9398 13.1411 19 12.5005 19C8.02281 19 4.23251 16.0571 2.95825 12C3.30515 10.8955 3.83851 9.87361 4.52143 8.97118M10.3787 9.87868C10.9216 9.33579 11.6716 9 12.5 9C14.1569 9 15.5 10.3431 15.5 12C15.5 12.8284 15.1642 13.5784 14.6213 14.1213M10.3787 9.87868L14.6213 14.1213M10.3787 9.87868L7.08916 6.58916M14.6213 14.1213L7.08916 6.58916M14.6213 14.1213L17.9112 17.4112M7.08916 6.58916C8.64898 5.58354 10.5066 5 12.5004 5C16.9781 5 20.7684 7.94291 22.0426 12C21.3357 14.2507 19.8545 16.1585 17.9112 17.4112" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            :
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M2.45825 12C3.73253 7.94288 7.52281 5 12.0004 5C16.4781 5 20.2684 7.94291 21.5426 12C20.2684 16.0571 16.4781 19 12.0005 19C7.52281 19 3.73251 16.0571 2.45825 12Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        }
                                                    </div>
                                                    <input type={passwordShown ? "text" : "password"} ref={inputPassword} autoComplete="on" className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-3 p-2.5" placeholder="Password" />
                                                </div>
                                                {errorPassword !== '' ?
                                                <span className="flex flex-row items-center w-full p-1 text-white rounded bg-bg2">
                                                    <div className="pr-2">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.0003 7.5V9.16667M10.0003 12.5H10.0086M4.22677 15.8333H15.7738C17.0568 15.8333 17.8587 14.4444 17.2172 13.3333L11.4436 3.33333C10.8021 2.22222 9.1984 2.22222 8.5569 3.33333L2.78339 13.3333C2.14189 14.4444 2.94377 15.8333 4.22677 15.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>

                                                    {errorPassword}
                                                </span> : ''}
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">Confirm Password</label>
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={togglePasswordtwo} >
                                                        {!passwordShowntwo ?
                                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.5 3L7.08916 6.58916M21.5 21L17.9112 17.4112M14.3749 18.8246C13.7677 18.9398 13.1411 19 12.5005 19C8.02281 19 4.23251 16.0571 2.95825 12C3.30515 10.8955 3.83851 9.87361 4.52143 8.97118M10.3787 9.87868C10.9216 9.33579 11.6716 9 12.5 9C14.1569 9 15.5 10.3431 15.5 12C15.5 12.8284 15.1642 13.5784 14.6213 14.1213M10.3787 9.87868L14.6213 14.1213M10.3787 9.87868L7.08916 6.58916M14.6213 14.1213L7.08916 6.58916M14.6213 14.1213L17.9112 17.4112M7.08916 6.58916C8.64898 5.58354 10.5066 5 12.5004 5C16.9781 5 20.7684 7.94291 22.0426 12C21.3357 14.2507 19.8545 16.1585 17.9112 17.4112" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            :
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M2.45825 12C3.73253 7.94288 7.52281 5 12.0004 5C16.4781 5 20.2684 7.94291 21.5426 12C20.2684 16.0571 16.4781 19 12.0005 19C7.52281 19 3.73251 16.0571 2.45825 12Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        }
                                                    </div>
                                                    <input type={passwordShowntwo ? "text" : "password"} ref={inputPasswordConfirm} autoComplete="on" className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-3 p-2.5" placeholder="Confirm Password" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px] m-auto block" type="submit">Create New Password</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            
            </Suspense>
        </div>
        </>
    );
}

export default Gamer;