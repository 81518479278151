import { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Signature from "../../helpers/Signature";
import ErrorCode from "../../helpers/errorcode.json";
import Modal from "../../components/modal/Modal";
import { getInsertnewAPI } from "./RegisterController";

const RegisterGamers = () => {
    const [dropdown, setDropdown] = useState(false);
    const [countryCode, setCountryCode] = useState([]);
    const [countrySelected, setCountrySelected] = useState('62');
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmationPasswordShown, setConfirmationPasswordShown] = useState(false);

    const inputFullName = useRef();
    const inputUsername = useRef();
    const inputEmail = useRef();
    const inputPhoneNumber = useRef();
    const inputPassword = useRef();
    const inputConfirmPassword = useRef();

    const [modal, setModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [contentModal, setContentModal] = useState({});

    const [errorUsername, setErrorUsername] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorFullName, setErrorFullName] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorPhoneNumber, setErrorPhoneNumber] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

    const navigate = useNavigate();
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const toggleConfirmationPassword = () => {
        setConfirmationPasswordShown(!confirmationPasswordShown);
    };
    const loadCountryCode = async () => {
        let payloadSignature = [
            'WEBSITE',
            'PASSS'
        ];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature
        }

        let body = {}

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/countrycodephone', body, {
            headers: dataHeaders
        });

        if (response.data.status.status === 1) {
            setCountryCode(response.data.data);
        }
    }

    useEffect(() => {
        const getToken = async () => {
            const token = await getInsertnewAPI();
            if (token.data.status.status === 1) {
                localStorage.setItem('_tokentrans', token.data.status.tokentrans);
            }
            if (token.data.status.status === 0) {
                token.data.status.message.forEach(element => {
                    let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                    let replaceError = resultError.replace('###', element.codevariable);

                    let contentModal = {
                        'head': 'Error',
                        'pesan': replaceError,
                        'description': element.code
                    };
                    setContentModal(contentModal);
                    setTypeModal('error');
                    setModal(true);
                });
            }
        }
        getToken();
        loadCountryCode();
    }, []);

    const registerHandle = async (e) => {
        e.preventDefault();

        let payloadSignature = [
            'WEBSITE',
            'PASSS',
            inputFullName.current.value,
            inputUsername.current.value,
            inputEmail.current.value,
            countrySelected,
            inputPhoneNumber.current.value,
            inputPassword.current.value,
            inputConfirmPassword.current.value,
            'gamer',
            localStorage.getItem('_tokentrans'),
        ];

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature
        }

        let body = {
            'fullname': inputFullName.current.value,
            'username': inputUsername.current.value,
            'email': inputEmail.current.value,
            'phonecountrycode': countrySelected,
            'phonenumbershort': inputPhoneNumber.current.value,
            'password': inputPassword.current.value,
            'confirmpassword': inputConfirmPassword.current.value,
            'accounttype': 'gamer',
            'tokentrans': localStorage.getItem('_tokentrans')
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/store', body, {
            headers: dataHeaders
        });

        if (response.data.status.status === 1) {
            inputFullName.current.value = '';
            inputUsername.current.value = '';
            inputEmail.current.value = '';
            inputPhoneNumber.current.value = '';
            inputPassword.current.value = '';
            inputConfirmPassword.current.value = '';

            let contentModal = {
                'head': 'Success',
                'pesan': 'Thank you for your registration',
                'description': 'we already sent you confirmation letter to your designated email. click the link in the confirmation letter to verify your account.'
            };
            setContentModal(contentModal);
            setTypeModal('success');
            setModal(true);
        }

        if (response.data.status.status === 0) {
            response.data.status.message.forEach(element => {
                let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
                let replaceError = resultError.replace('###', element.codevariable);
                switch (element.code.substring(0, 9)) {
                    case ErrorCode.username:
                        setErrorUsername(replaceError);
                        break;
                    case ErrorCode.password:
                        setErrorPassword(replaceError);
                        break;
                    case ErrorCode.fullname:
                        setErrorFullName(replaceError);
                        break;
                    case ErrorCode.email:
                        setErrorEmail(replaceError);
                        break;
                    case ErrorCode.phonenumber:
                        setErrorPhoneNumber(replaceError);
                        break;
                    case ErrorCode.confirmpassword:
                        setErrorConfirmPassword(replaceError);
                        break;
                    case ErrorCode.tokentrans:
                        navigate('/register');
                        break;
                    default:
                        let contentModal = {
                            'head': 'Error',
                            'pesan': replaceError,
                            'description': element.code
                        };
                        setContentModal(contentModal);
                        setTypeModal('error');
                        setModal(true);
                        break;
                }
            });

            setTimeout(() => {
                setErrorUsername('');
                setErrorPassword('');
                setErrorFullName('');
                setErrorEmail('');
                setErrorPhoneNumber('');
                setErrorConfirmPassword('');
            }, 4000);
        }
    }

    const dropdownHandle = () => {
        if (dropdown === false) {
            setDropdown(true);
        } else {
            setDropdown(false);
        }
    }

    const selectedHandle = (e) => {
        setCountrySelected(e);
        setDropdown(false);
    }

    const checkUsernameHandle = async () => {
        if(inputUsername.current.value.length > 5){
            let payloadSignature = [
                inputUsername.current.value,
            ];
    
            let signature = Signature(payloadSignature);
    
            let dataHeaders = {
                'platform': 'WEBSITE',
                'gtoken': 'PASSS',
                'signature': signature
            }

            let body = {
                'username': inputUsername.current.value
            }

            let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/checkusername', body, {
                headers: dataHeaders
            });

            if (response.data.status.status === 0){
                setErrorUsername(response.data.status.message[0].errormessage);
            }

            if (response.data.status.status === 1){
                setErrorUsername('');
            }
        }else{
            setErrorUsername('');
        }
    }

    const checkEmailHandle = async () => {
        if(inputEmail.current.value.length > 5){
            let payloadSignature = [
                inputEmail.current.value,
            ];
    
            let signature = Signature(payloadSignature);
    
            let dataHeaders = {
                'platform': 'WEBSITE',
                'gtoken': 'PASSS',
                'signature': signature
            }

            let body = {
                'useremail': inputEmail.current.value
            }

            let response = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/auth/v01/checkuseremail', body, {
                headers: dataHeaders
            });

            if (response.data.status.status === 0){
                setErrorEmail(response.data.status.message[0].errormessage)
            }
            if(response.data.status.status === 1){
                setErrorEmail('');
            }
        }else{
            setErrorEmail('');
        }
    }

    return (
        <>
            <Modal modal={modal} type={typeModal} content={contentModal} from={"registerGamers"} cancel={() => setModal(false)} color={'gamer'} />
            <div className="w-full h-full">
                <div style={{ backgroundImage: "url('/images/bg-1.png')" }} className="flex items-center justify-center w-full h-full overflow-hidden bg-no-repeat bg-cover">
                    <div className="container relative h-[80vh] min-h-[100vh] overflow-auto">
                        <div className="relative float-right w-full h-full max-w-6xl">
                            <div className="absolute translate-y-[-50%] top-[50%] w-full">
                                <img src={process.env.PUBLIC_URL + '/images/component-2.png'} alt="element" className="float-left animate-wiggle-slow" />
                                <img src={process.env.PUBLIC_URL + '/images/component-3.png'} alt="element" className="float-right mt-[-450px] animate-wiggle-slow " />
                            </div>
                        </div>

                        <div className="w-full h-full relative max-w-[550px]">
                            <div className="absolute translate-y-[-50%] max-h-[100%] top-[50%] w-full">
                                <h3 className="text-white text-[36px]">Welcome Gamers!!</h3>
                                <p className="text-[#989B9D]">Create your account for free</p>

                                <div className="mt-10">
                                    <form onSubmit={(e) => registerHandle(e)}>
                                        <div className="flex flex-col gap-5 mb-3">
                                            <div className="flex-auto">
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">Username</label>
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <input type="text" ref={inputUsername} onChange={checkUsernameHandle} id="input-group-1" className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-10 p-2.5" placeholder="Your Username" />
                                                </div>
                                                {errorUsername !== '' ?
                                                    <span className="flex flex-row items-center w-full p-1 text-white rounded bg-bg2">
                                                        <div className="pr-2">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.0003 7.5V9.16667M10.0003 12.5H10.0086M4.22677 15.8333H15.7738C17.0568 15.8333 17.8587 14.4444 17.2172 13.3333L11.4436 3.33333C10.8021 2.22222 9.1984 2.22222 8.5569 3.33333L2.78339 13.3333C2.14189 14.4444 2.94377 15.8333 4.22677 15.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>

                                                        {errorUsername}
                                                    </span> : ''}
                                            </div>

                                            <div className="flex-auto">
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">Full Name</label>
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 6H5C3.89543 6 3 6.89543 3 8V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V8C21 6.89543 20.1046 6 19 6H14M10 6V5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5V6M10 6C10 7.10457 10.8954 8 12 8C13.1046 8 14 7.10457 14 6M9 14C10.1046 14 11 13.1046 11 12C11 10.8954 10.1046 10 9 10C7.89543 10 7 10.8954 7 12C7 13.1046 7.89543 14 9 14ZM9 14C10.3062 14 11.4174 14.8348 11.8292 16M9 14C7.69378 14 6.58249 14.8348 6.17065 16M15 11H18M15 15H17" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <input type={"text"} ref={inputFullName} id="input-group-1" className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-10 p-2.5" placeholder="Your Full Name" />
                                                </div>
                                                {errorFullName !== '' ?
                                                    <span className="flex flex-row items-center w-full p-1 text-white rounded bg-bg2">
                                                        <div className="pr-2">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.0003 7.5V9.16667M10.0003 12.5H10.0086M4.22677 15.8333H15.7738C17.0568 15.8333 17.8587 14.4444 17.2172 13.3333L11.4436 3.33333C10.8021 2.22222 9.1984 2.22222 8.5569 3.33333L2.78339 13.3333C2.14189 14.4444 2.94377 15.8333 4.22677 15.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>

                                                        {errorFullName}
                                                    </span> : ''}
                                            </div>

                                            <div className="flex-auto">
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">Email</label>
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <input type="text" ref={inputEmail} onChange={checkEmailHandle} id="input-group-1" className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-10 p-2.5" placeholder="anoa@anoa.id" />
                                                </div>
                                                {errorEmail !== '' ?
                                                    <span className="flex flex-row items-center w-full p-1 text-white rounded bg-bg2">
                                                        <div className="pr-2">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.0003 7.5V9.16667M10.0003 12.5H10.0086M4.22677 15.8333H15.7738C17.0568 15.8333 17.8587 14.4444 17.2172 13.3333L11.4436 3.33333C10.8021 2.22222 9.1984 2.22222 8.5569 3.33333L2.78339 13.3333C2.14189 14.4444 2.94377 15.8333 4.22677 15.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>

                                                        {errorEmail}
                                                    </span> : ''}
                                            </div>

                                            <div className="flex-auto">
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">Phone Number</label>
                                                <div className="flex flex-row mb-3">
                                                    <div className="flex-initial w-[18%]">
                                                        <button className="text-black w-fit flex items-center p-[0.5rem] border border-gray-300 rounded-lg bg-gray-50 " type="button" onClick={dropdownHandle}>
                                                            + {countrySelected}
                                                            <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
                                                        </button>
                                                        <div className={dropdown ? 'z-10 block absolute bg-white divide-y divide-gray-100 rounded shadow w-fit' : 'z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44'}>
                                                            <ul className="py-1 text-sm text-gray-700 dark:text-gray-200 overflow-auto max-h-[200px]">
                                                                {countryCode.map((result, key) => (
                                                                    <li key={key} className="truncate cursor-pointer" onClick={() => selectedHandle(result.tablecountryphonecodephonecode)}>
                                                                        <span className="flex flex-row px-4 py-2 text-black hover:bg-gray-100">
                                                                            + {result.tablecountryphonecodephonecode} <span className="pl-4">{result.tablecountryphonecodecountryname}</span>
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="flex-auto">
                                                        <input type="text" ref={inputPhoneNumber} id="input-group-1" className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-3 p-2.5" placeholder="876-543-2111" />
                                                    </div>
                                                </div>
                                                {errorPhoneNumber !== '' ?
                                                    <span className="flex flex-row items-center w-full p-1 text-white rounded bg-bg2">
                                                        <div className="pr-2">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.0003 7.5V9.16667M10.0003 12.5H10.0086M4.22677 15.8333H15.7738C17.0568 15.8333 17.8587 14.4444 17.2172 13.3333L11.4436 3.33333C10.8021 2.22222 9.1984 2.22222 8.5569 3.33333L2.78339 13.3333C2.14189 14.4444 2.94377 15.8333 4.22677 15.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>

                                                        {errorPhoneNumber}
                                                    </span> : ''}
                                            </div>

                                            <div className="flex-auto">
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">Set New Password</label>
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={togglePassword}>
                                                        {!passwordShown ?
                                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.5 3L7.08916 6.58916M21.5 21L17.9112 17.4112M14.3749 18.8246C13.7677 18.9398 13.1411 19 12.5005 19C8.02281 19 4.23251 16.0571 2.95825 12C3.30515 10.8955 3.83851 9.87361 4.52143 8.97118M10.3787 9.87868C10.9216 9.33579 11.6716 9 12.5 9C14.1569 9 15.5 10.3431 15.5 12C15.5 12.8284 15.1642 13.5784 14.6213 14.1213M10.3787 9.87868L14.6213 14.1213M10.3787 9.87868L7.08916 6.58916M14.6213 14.1213L7.08916 6.58916M14.6213 14.1213L17.9112 17.4112M7.08916 6.58916C8.64898 5.58354 10.5066 5 12.5004 5C16.9781 5 20.7684 7.94291 22.0426 12C21.3357 14.2507 19.8545 16.1585 17.9112 17.4112" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            :
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M2.45825 12C3.73253 7.94288 7.52281 5 12.0004 5C16.4781 5 20.2684 7.94291 21.5426 12C20.2684 16.0571 16.4781 19 12.0005 19C7.52281 19 3.73251 16.0571 2.45825 12Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        }
                                                    </div>
                                                    <input type={passwordShown ? "text" : "password"} ref={inputPassword} id="input-group-1" className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-3 p-2.5" placeholder="Password" />
                                                </div>
                                                {errorPassword !== '' ?
                                                    <span className="flex flex-row items-center w-full p-1 text-white rounded bg-bg2">
                                                        <div className="pr-2">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.0003 7.5V9.16667M10.0003 12.5H10.0086M4.22677 15.8333H15.7738C17.0568 15.8333 17.8587 14.4444 17.2172 13.3333L11.4436 3.33333C10.8021 2.22222 9.1984 2.22222 8.5569 3.33333L2.78339 13.3333C2.14189 14.4444 2.94377 15.8333 4.22677 15.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>

                                                        {errorPassword}
                                                    </span> : ''}
                                            </div>

                                            <div className="flex-auto">
                                                <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-[#D7DCDF]">Confirm Password</label>
                                                <div className="relative mb-6">
                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={toggleConfirmationPassword}>
                                                        {!confirmationPasswordShown ?
                                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.5 3L7.08916 6.58916M21.5 21L17.9112 17.4112M14.3749 18.8246C13.7677 18.9398 13.1411 19 12.5005 19C8.02281 19 4.23251 16.0571 2.95825 12C3.30515 10.8955 3.83851 9.87361 4.52143 8.97118M10.3787 9.87868C10.9216 9.33579 11.6716 9 12.5 9C14.1569 9 15.5 10.3431 15.5 12C15.5 12.8284 15.1642 13.5784 14.6213 14.1213M10.3787 9.87868L14.6213 14.1213M10.3787 9.87868L7.08916 6.58916M14.6213 14.1213L7.08916 6.58916M14.6213 14.1213L17.9112 17.4112M7.08916 6.58916C8.64898 5.58354 10.5066 5 12.5004 5C16.9781 5 20.7684 7.94291 22.0426 12C21.3357 14.2507 19.8545 16.1585 17.9112 17.4112" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            :
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M2.45825 12C3.73253 7.94288 7.52281 5 12.0004 5C16.4781 5 20.2684 7.94291 21.5426 12C20.2684 16.0571 16.4781 19 12.0005 19C7.52281 19 3.73251 16.0571 2.45825 12Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        }
                                                    </div>
                                                    <input type={confirmationPasswordShown ? "text" : "password"} ref={inputConfirmPassword} id="input-group-1" className="bg-gray-50 focus:outline-0 border border-gray-300 text-sm rounded-lg block w-full pl-3 p-2.5" placeholder="Re-type password" />
                                                </div>
                                                {errorConfirmPassword !== '' ?
                                                    <span className="flex flex-row items-center w-full p-1 text-white rounded bg-bg2">
                                                        <div className="pr-2">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.0003 7.5V9.16667M10.0003 12.5H10.0086M4.22677 15.8333H15.7738C17.0568 15.8333 17.8587 14.4444 17.2172 13.3333L11.4436 3.33333C10.8021 2.22222 9.1984 2.22222 8.5569 3.33333L2.78339 13.3333C2.14189 14.4444 2.94377 15.8333 4.22677 15.8333Z" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>

                                                        {errorConfirmPassword}
                                                    </span> : ''}
                                            </div>
                                        </div>

                                        <button className="bg-bm2 w-full p-[10px] text-white rounded-[12px] m-auto block">Register as a Gamer</button>
                                        <div className="flex flex-row justify-center items-center text-[#FFFF] mt-3 gap-1">
                                            <label>Already have an account? </label>
                                            <a href="/login/gamers" className="underline focus:cursor-pointer">Sign In</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegisterGamers;