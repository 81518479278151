export const getUSFormatTime = (item) => {
    const dateString = item;
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("es-US", {
        day: "numeric",
        month: "numeric",
        year: "numeric"
    });
    return formattedDate
}
export const getCLFormatTime = (item) => {
    const dateString = item;
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("es-CL", {
        day: "numeric",
        month: "numeric",
        year: "numeric"
    });
    return formattedDate
}
export const getCAFormatTime = (item) => {
    const dateString = item;
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-CA", {
        day: "numeric",
        month: "numeric",
        year: "numeric"
    });
    return formattedDate
}
export const rangeBetweenDate = (item1, item2) => {
    const startDate = new Date(item1);
    const endDate = new Date(item2);
    const daysInRange = endDate.getDate() - startDate.getDate() + 1;
    return daysInRange;
}
export const getTime = (item) => {
    const dateString = item;
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    // const milliseconds = date.getMilliseconds();

    var formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    var formattedHours   = hours < 10 ? "0" + hours : hours;
    return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds 
}