import axios from 'axios';
import { useState, useEffect } from 'react';
import Signature from '../../helpers/Signature';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ReviewModal from './modal/ReviewModal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CryptoJS from 'crypto-js';
import MainHeader from '../main/MainHeader'
import './gamersPage_CSS/GamesDetail.css'
import ProgressBar from "@ramonak/react-progress-bar";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react'
import BlurBarLoader from '../../components/loader/BlurBarLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import ReportModal from './modal/ReportModal';
import moment from 'moment';
import YouTube from 'react-youtube';

export default function GamesDetail() {

    const [modal, setModal] = useState(false);
    const [reviewData, setReviewData] = useState([]);
    const [typeModal] = useState('');
    const [tokenTrans, setTokenTrans] = useState('');
    const [mainContent, setMainContent] = useState([]);
    const [carousel, setCarousel] = useState(null);
    const [additionalTransfrom, setadditionalTransfrom] = useState(0);
    const [isRemounting, setisRemounting] = useState(false);
    const [currentContent, setCurrentContent] = useState(null);
    const [demoLink, setDemoLink] = useState('');
    const [detailData, setDetailData] = useState();
    const { id, review } = useParams();
    const [isReadyToShow, setIsReadyToShow] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listGenre, setListGenre] = useState([]);
    const [totalRating, setTotalRating] = useState([]);
    const [cancelFilter, setCancelFilter] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [modalReport, setModalReport] = useState(false);
    const [thumbnails, setThumbnails] = useState(false);

    const [faqToggle, setFaqToggle] = useState(false);
    const [faqClick, setFaqClick] = useState(false);
    const [detailFaqToggle, setDetailFaqToggle] = useState(false);
    const [idDetail, setIdDetail] = useState('');

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4.5,
            partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4.5,
            partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 4.5,
            partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        }
    };
    const rating = [
        {
            id: 1,
            selected: false
        },
        {
            id: 2,
            selected: false
        },
        {
            id: 3,
            selected: false
        },
        {
            id: 4,
            selected: false
        },
        {
            id: 5,
            selected: false
        },
    ]
    // const totalRating = [
    //     {
    //         id: 5,
    //         percentage: 30
    //     },
    //     {
    //         id: 4,
    //         percentage: 20  
    //     },
    //     {
    //         id: 3,
    //         percentage: 0
    //     },
    //     {
    //         id: 2,
    //         percentage: 0
    //     },
    //     {
    //         id: 1,
    //         percentage: 0
    //     },
    // ]

    const navigate = useNavigate();
    const location = useLocation();
    const randomId = function (length = 6) {
        return Math.random().toString(36).substring(2, length + 2);
    };
    const { isAuthenticated, tableThreadData } = location.state
    //console.log('tableThreadData', tableThreadData)
    const fetchGameContent = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');

        let decrypt = getUUID ? CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8) : '';
        let resultUUID = getUUID ? JSON.parse(atob(decrypt)) : '';

        let payloadSignature = [id];
        let signature = Signature(payloadSignature);
        let payload = {
            // "tablethreadindex": "TRED1865442D4BE"
            // "tablethreadindex": "TRED1872B861589" //tableImage
            // "tablethreadindex": "TRED18730957484" //tableVid
            // "tablethreadindex": "TRED18730957484" //haveAccess
            "tablethreadindex": id
        }
        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature,
            'userindex': resultUUID ? resultUUID.user.userindex : ''
        } 
        console.log(isAuthenticated)
        try {
            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/homepage/v01/getthread', payload, {
                headers: dataHeaders
            });
            //console.log('res_getthread', res)
            let carouselContent = [];
            let imageData = res.data.data.tablethreadimage;
            setDemoLink(res.data.data.tablethreaddemolink);
            setDetailData(res.data.data)
            setListGenre(res.data.data.tablethreadgenre);
            setTotalRating(res.data.support.totalRating);

            console.log(res);

            // console.log(res.data.data);
            if (imageData.length > 0) {
                for (const key in imageData) {
                    if (key === '0') {

                        carouselContent.push({ 'id': 'IMG' + randomId(5), 'type': 'image', 'content': imageData[key], 'selected': true });

                    }
                    else {
                        carouselContent.push({ 'id': 'IMG' + randomId(5), 'type': 'image', 'content': imageData[key], 'selected': false });
                    }
                }
            }

            let videoData = res.data.data.tablethreadvideo
            if (videoData.length > 0 ) {
                for (const key in videoData) {

                    carouselContent.push({ 'id': 'VID' + randomId(5), 'type': 'video', 'content': videoData[key], 'selected': false });
                }
            }

            if(res.data.data.tablethreadyoutubelink !== null){
                let link = res.data.data.tablethreadyoutubelink
                let regex = /[?&]v=([^&]+)/;
                let match = link.match(regex);

                carouselContent.push({'id': 'YOUTUBE' + randomId(5), 'type': 'link', 'content': match[1], 'selected': false})
            }

            //console.log('hasdhashdas '+carouselContent)

            setCurrentContent(carouselContent[0])
            setMainContent(carouselContent);
            console.log('carocont', carouselContent)
        }
        catch (err) {
            //console.log('err', err)
        }

    }
    const fetchGameReview = async () => {
        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        let payload = {
            "threadindex": id //MAIN
            // "threadindex": "TRED1867C460609"
        }

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature
        }
        try {
            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/homepage/v01/listreview', payload, {
                headers: dataHeaders
            });
            setReviewData(res.data.data)
            //console.log('res_listreview', res)

        }
        catch (err) {
            //console.log('err_listreview', err)
        }
    }

    const getPageData = async () => {
        //console.log('isAuthenticated', isAuthenticated)
        if (isAuthenticated === "Gamers") {
            setIsAuth(true)
        }
        const [gameContent] = await Promise.allSettled([
            fetchGameContent(),
            fetchGameReview()
        ])
        if (gameContent.status === "fulfilled") {
            setIsReadyToShow(true)
        }
    }
    // const CustomRightArrow = ({ onClick, ...rest }) => {
    //     const {
    //         onMove,
    //         carouselState: { currentSlide, deviceType }
    //     } = rest;
    //     // onMove means if dragging or swiping in progress.
    //     return <button onClick={() => onClick()} />;
    // };
    const directToLoginPage = () => {
        navigate('/login/gamers')
    }
    const updateContent = (item) => {
        setThumbnails(false);
        let temp = mainContent
        temp.forEach(elem => {
            if (elem.id === item.id) {
                elem.selected = true;
                setCurrentContent(item)
            }
            else {
                elem.selected = false;
            }
        })

        setMainContent(temp)
        setisRemounting(!isRemounting);
    }
    const setRefVal = (el) => {
        if (el !== null) {
            if (carousel === null) {
                setCarousel(el)
            }
            else {
                setCarousel(carousel)
            }
        }
    }
    const getTokenTrans = async () => {
        setLoading(true);
        if(isAuthenticated !== null){
            let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
            let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
            let resultUUID = JSON.parse(atob(decrypt));
            let payloadSignature = [];
            let signature = Signature(payloadSignature);
            let payload = {}
            let dataHeaders = {
                'platform': 'WEBSITE',
                'gtoken': 'PASSS',
                'signature': signature,
                'userindex': resultUUID.user.userindex,
                'tokenlogin': resultUUID.user.usertokenlogin,
                'threadindex': id
            }
            const currentTimestamp = new Date().getTime();

            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/review/v01/insertnew?id='+currentTimestamp, payload, {
                headers: dataHeaders
            });
            try {
                if (res.data.status.status === 1) {
                    let tokenTrans = res.data.status.tokentrans;
                    setTokenTrans(tokenTrans);
                    setModal(true);
                }else{
                    if(res.data.status.message[0].code === '008005415005'){
                        Swal.fire({
                            title: "Unable to Review",
                            text: "If you don't have an Anoa wallet, then register your wallet first at https://dev.wallet.anoatoken.com/signup then, Please click the LINK WALLET on your Profile Page",
                            icon: 'warning',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, Go to profile'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // window.open('https://dev.wallet.anoatoken.com/signin')
                                navigate('/gamers/gamersProfile');
                            }
                        })
                    }else{
                        Swal.fire(
                            'Unable to Review',
                            "Threads are Ended or account is not active",
                            'warning'
                        )
                    }
                }
                setLoading(false);
                //console.log('res_insertnew', res)

            }
            catch (err) {
                //console.log('res_insertnew', err)
            }
        }else{
            navigate('/login/gamers');
        }
    }

    useEffect(() => {
        if(review === 'true'){
            fetchGameContent()

            Swal.fire({
                title: 'Process...',
                html: 'Please wait for the process to be prepared.',
                timer: 2000,
                showConfirmButton: false
            })

            setTimeout(() => {
                getTokenTrans();
            }, 2000);
        }
    }, []);

    // const CustomSlider = ({ carouselState }) => {
    //     //console.log('CSTATE', carouselState)
    //     let value = 0;
    //     let carouselItemWidth = 0;
    //     if (carousel) {
    //         //console.log('carousel', carousel)
    //         carouselItemWidth = carouselState.itemWidth;
    //         const maxTranslateX = Math.round(
    //             // so that we don't over-slide
    //             carouselItemWidth *
    //             (carouselState.totalItems -
    //                 carouselState.slidesToShow) +
    //             150
    //         );
    //         // //console.log('maxTranslateX', maxTranslateX)
    //         value = maxTranslateX / 100; // calculate the unit of transform for the slider
    //     }

    //     const { transform } = carouselState;
    //     //console.log('transform0', transform)

    //     return (
    //         <div className="custom-slider">
    //             <input
    //                 type="range"
    //                 value={Math.round(Math.abs(-transform) / value)}
    //                 defaultValue={0}
    //                 max={
    //                     (carouselItemWidth *
    //                         (carouselState.totalItems - carouselState.slidesToShow) +
    //                         (additionalTransfrom === 150 ? 0 : 150)) /
    //                     value
    //                 }
    //                 onChange={e => {
    //                     if (carousel.isAnimationAllowed) {
    //                         carousel.isAnimationAllowed = false;
    //                     }

    //                     const nextTransform = e.target.value * value;
    //                     const nextSlide = Math.round(nextTransform / carouselItemWidth);

    //                     if (e.target.value == 0 && additionalTransfrom === 150) {
    //                         carousel.isAnimationAllowed = true;
    //                         setadditionalTransfrom(0);
    //                     }

    //                     setScrollVal(Math.round(Math.abs(-nextTransform) / value))
    //                     setCarousel({
    //                         transform: -nextTransform, // padding 20px and 5 items.
    //                         currentSlide: nextSlide
    //                     })
    //                 }}
    //                 className="custom-slider__input"
    //             />
    //         </div>
    //     );
    // };
    const displayRating = (val) => {
        let totalRating = rating;
        totalRating.forEach(element => {
            if (element.id < val || element.id === val) {
                element.selected = true;
            }else{
                element.selected = false;
            }
        });
        return (
            totalRating.map((item, index) => {
                return (
                    item.selected ?
                        <div className='mt-1' key={index}>
                            <img src={process.env.PUBLIC_URL + '/images/main/rateStar.svg'} alt="element" className=' h-[100%] w-[100%] object-contain' />
                        </div>
                        :
                        <div className='w-6 mt-1' key={index}>
                            <img src={process.env.PUBLIC_URL + '/images/main/unratedStar.svg'} alt="element" className='h-[100%] w-[100%] object-contain' />
                        </div>

                )
            })
        )
    }
    const showRatingProgressBar = () => {

        // let rateVal = totalRating;
        // reviewData.forEach((elem) => {

        //     rateVal.forEach((data) => {
        //         if (data.id === elem.tablereviewgameplayrating) {
        //             data.percentage++
        //         }
        //     })
        // })
        // const totalReview = rateVal[0].percentage + rateVal[1].percentage + rateVal[2].percentage + rateVal[3].percentage + rateVal[4].percentage;
        // return (
        //     rateVal.map((item, index) => {
        //         return (
        //             <div className='flex flex-row justify-start items-center gap-3 w-[100%] mt-2 '>
        //                 <div className='w-[2vw]'>
        //                     <img src={process.env.PUBLIC_URL + '/images/main/totalRateStar.svg'} alt="element" className=' h-[100%] w-[100%] object-contain' />

        //                 </div>

        //                 <label className='text-[#BABEC1] '>{item.id}</label>
        //                 {/* <div className='py-[1%]     '> */}
        //                 <ProgressBar
        //                     completed={10}
        //                     className="w-[100%] h-[80%] border-[1px] px-1 py-1 rounded-sm"
        //                     customLabel=' '
        //                     barContainerClassName="barContainer"
        //                     bgColor='#DEDBFF'
        //                     height='100%'
        //                     width='100%'
        //                     borderRadius='rounded-sm'
        //                 />
        //                 {/* </div> */}

        //             </div>
        //         )
        //     })
        // )

        totalRating.sort((a, b) => a.id - b.id);

        return (
            <>
                {totalRating.map((result, key) => (
                    <div className='flex flex-row justify-start items-center gap-3 w-[100%] mt-2 '>
                        <div className='w-[2vw]'>
                            <img src={process.env.PUBLIC_URL + '/images/main/totalRateStar.svg'} alt="element" className=' h-[100%] w-[100%] object-contain' />

                        </div>

                        <label className='text-[#BABEC1] '>{key + 1}</label>
                        <ProgressBar
                            completed={result.percentage}
                            className="w-[100%] h-[80%] border-[1px] px-1 py-1 rounded-sm"
                            customLabel=' '
                            barContainerClassName="barContainer"
                            bgColor='#DEDBFF'
                            height='100%'
                            width='100%'
                            borderRadius='rounded-sm'
                        />
                    </div>
                ))}
            </>
        )

    }

    const calculateRangeDate = () => {
        const startDate = moment(detailData.tablethreaddatestart, "DD/MM/YYYY hh:mm:ss A");
        const endDate = moment(detailData.tablethreaddateend, "DD/MM/YYYY hh:mm:ss A");

        const daysRemaining = endDate.diff(startDate, 'days');

        return daysRemaining;
    }
    useEffect(() => {
        getPageData();
        // eslint-disable-next-line
    }, []);

    const filterRating = async (value) => {
        setCancelFilter(true);
        setSelectedFilter(value);
        // let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');

        // if(getUUID !== null){
        //     let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        //     let resultUUID = JSON.parse(atob(decrypt));
    
        //     let payloadSignature = [];
        //     let signature = Signature(payloadSignature);
    
        //     let payload = {
        //         'threadindex' : id
        //     }
        //     let dataHeaders = {
        //         'platform': 'WEBSITE',
        //         'gtoken': 'PASSS',
        //         'signature': signature,
        //         'userindex': resultUUID.user.userindex,
        //         'tokenlogin': resultUUID.user.usertokenlogin
        //     }

        //     const currentTimestamp = new Date().getTime();
    
        //     let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/homepage/v01/listreview?rating='+value+'&media=false&id='+currentTimestamp, payload, {
        //         headers: dataHeaders
        //     });
    
        //     setReviewData(res.data.data);
        // }else{
            
        // }

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let payload = {
            'threadindex' : id
        }
        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature,
            'userindex': '',
            'tokenlogin':''
        }

        const currentTimestamp = new Date().getTime();

        let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/homepage/v01/listreview?rating='+value+'&id='+currentTimestamp, payload, {
            headers: dataHeaders
        });

        setReviewData(res.data.data);
    }

    const filterMedia = async () => {
        setCancelFilter(true);
        setSelectedFilter('media');
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');

        if(getUUID !== null){
            let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
            let resultUUID = JSON.parse(atob(decrypt));
    
            let payloadSignature = [];
            let signature = Signature(payloadSignature);
    
            let payload = {
                'threadindex' : id
            }
            let dataHeaders = {
                'platform': 'WEBSITE',
                'gtoken': 'PASSS',
                'signature': signature,
                'userindex': resultUUID.user.userindex,
                'tokenlogin': resultUUID.user.usertokenlogin
            }
            const currentTimestamp = new Date().getTime();

            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/homepage/v01/listreview?media=true&id='+currentTimestamp, payload, {
                headers: dataHeaders
            });
    
            setReviewData(res.data.data);
        }else{
            let payloadSignature = [];
            let signature = Signature(payloadSignature);

            let payload = {
                'threadindex' : id
            }
            let dataHeaders = {
                'platform': 'WEBSITE',
                'gtoken': 'PASSS',
                'signature': signature,
                'userindex': '',
                'tokenlogin': ''
            }
            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/homepage/v01/listreview?&media=true', payload, {
                headers: dataHeaders
            });

            setReviewData(res.data.data);
        }
        
    }

    const cancelFilterHandle = async () => {
        setCancelFilter(false);
        setSelectedFilter(0);
        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        let payload = {
            "threadindex": id //MAIN
            // "threadindex": "TRED1867C460609"
        }

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature
        }
        try {
            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/homepage/v01/listreview', payload, {
                headers: dataHeaders
            });
            setReviewData(res.data.data)
            //console.log('res_listreview', res);

        }
        catch (err) {
            //console.log('err_listreview', err)
        }
    }

    console.log(detailData);

    const loadButtonReview = (detailData, isAuth) => {
        if(detailData.tablereviewstatus === 'REJECTED' || detailData.tablereviewstatus === null){
            return(
                <button onClick={() => isAuth ? getTokenTrans() : directToLoginPage()} className='w-[100%] bg-bm2 py-[3.5%] rounded-md text-[#FFFF] text-[14px]'>
                    Review
                </button>
            )
        }else{
            return(
                <button className='w-[100%] bg-gray-500 py-[3.5%] rounded-md text-[#FFFF] text-[14px]'>
                    Review
                </button>
            )
        }
    }

    const generateShareLink = (value) => {
        var host = window.location.protocol + "//" + window.location.host+'/share/threads/'+value;

        navigator.clipboard.writeText(host);

        Swal.fire(
            'Copied',
            'link successfully copied',
            'success'
        )
    }

    console.log(currentContent);

    const opts = {
        height: '550',
        width: '100%',
        playerVars: {
        autoplay: 1,
        },
    };

    const dataFAQDevelopers = [
        {
            'id' : 'developers-01',
            'title' : 'Bagaimana cara register Developer pada The Space?',
            'paragraf' : 'A tutorial for register as a Developer at The Space'
        },
        {
            'id' : 'developers-02',
            'title' : 'Bagaimana cara log in Developer pada The Space?',
            'paragraf' : 'A tutorial for login as a Developer at The Space'
        },
        {
            'id' : 'developers-03',
            'title' : 'Bagaimana cara Developer mengunggah Threads?',
            'paragraf' : 'A tutorial for Threads as a Developer at The Space'
        },
        {
            'id' : 'developers-04',
            'title' : 'Bagaimana cara Top Up pada The Space?',
            'paragraf' : 'A tutorial for Top Up as a Developer at The Space'
        },
        {
            'id' : 'developers-05',
            'title' : 'Apa itu Active Threads, Total Threads dan Average Scoring pada Dashboard Developer?',
            'paragraf' : 'A tutorial for Active Threads, Total Threads and Average Scoring a Developer at The Space'
        },
        {
            'id' : 'developers-06',
            'title' : 'Apa itu Threads List pada Dashboard Developer?',
            'paragraf' : 'A tutorial for Threads List a Developer at The Space'
        },
        {
            'id' : 'developers-07',
            'title' : 'Bagaimana menjadi TOP Developer?',
            'paragraf' : 'A tutorial for TOP Developer at The Space'
        },
        {
            'id' : 'developers-08',
            'title' : 'Apakah target review dari Developer dalam satu Threads dibatasi?',
            'paragraf' : 'A tutorial for Developer at The Space'
        },
        {
            'id' : 'developers-09',
            'title' : 'Bagaimana jika akun Freeze?',
            'paragraf' : 'A tutorial for Developer at The Space'
        },
        {
            'id' : 'developers-10',
            'title' : 'Bagaimana jika akun Developer Suspend?',
            'paragraf' : 'A tutorial for Developer at The Space'
        },
        {
            'id' : 'developers-11',
            'title' : 'Apa penyebab Blokir dapat terjadi pada akun Developer?',
            'paragraf' : 'A tutorial for Developer at The Space'
        }
    ];

    const dataFAQGamers = [
        {
            'id' : 'gamers-01',
            'title' : 'Bagaimana register Gamers pada The Space?',
            'paragraf' : 'A tutorial for register as a Gamers at The Space'
        },
        {
            'id' : 'gamers-02',
            'title' : 'Bagaimana cara login Gamers The Space?',
            'paragraf' : 'A tutorial for register as a Gamers at The Space'
        },
        {
            'id' : 'gamers-03',
            'title' : 'Bagaimana cara KYC Gamers pada The Space?',
            'paragraf' : 'A tutorial for KYC as a Gamers at The Space'
        },
        {
            'id' : 'gamers-04',
            'title' : 'Bagaimana cara saya mendapatkan token melalui review?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
        {
            'id' : 'gamers-05',
            'title' : 'Bagaiamana cara melaporkan Thread?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
        {
            'id' : 'gamers-06',
            'title' : 'Apa itu Total Review, Total Earning dan Balance pada profil Game The Space?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
        {
            'id' : 'gamers-07',
            'title' : 'Bagaimana jika akun Gamers mengalami Suspend?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
        {
            'id' : 'gamers-08',
            'title' : 'Apa penyebab Blokir dapat terjadi pada akun Gamers?',
            'paragraf' : 'A tutorial for Gamers at The Space'
        },
    ];

    const dataDetailFAQ = [
        {
            'id' : 'developers-01',
            'title' : 'Bagaimana cara register Developer pada The Space?',
            'content' : 
            `<ul class='list-disc'>
                <li>Buka <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “Get Started” pada halaman The Space</li>
                <li>Klik “Register”</li>
                <li>Klik “Register as Developer”</li>
                <li>Isi Data Pendukung;</li>
                <li class='list-none'>
                    <ul class='list-decimal'>
                        <li>Nama Perusahaan</li>
                        <li>Username</li>
                        <li>Alamat Perusahaan</li>
                        <li>Email Perusahaan</li>
                        <li>Nomor Telepon</li>
                        <li>Set Password dan Confirm Password (password harus mengandung huruf kapital, huruf kecil, angka dan symbol)</li>
                    </ul>
                </li>
                <li>Klik “Register as Developer”</li>
                <li>Lalu cek email yang sudah didaftarkan untuk mendapatkan notifikasi dari The Space.</li>
            </ul>`
        },
        {
            'id' : 'developers-02',
            'title' : 'Bagaimana cara log in Developer pada The Space?',
            'content' :
            `<ul class="list-disc">
                <li>Buka <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “Get Started” pada halaman The Space</li>
                <li>Klik “Log in as Developer”</li>
                <li>Isi Email Developer yang sudah terdaftar pada akun The Space</li>
                <li>Isi Password yang dimiliki</li>
                <li>Klik “Login as Developer”</li>
            </ul>
            `
        },
        {
            'id' : 'developers-03',
            'title' : 'Bagaimana cara Developer mengunggah Threads?',
            'content' :
            `<ul class="list-disc">
                <li>Login pada halaman Developer di <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “New Threads” pada Dashboard Developer The Space</li>
                <li>Isi Basic Information;</li>
                <li class="list-none">
                    <ul class="list-decimal">
                        <li>Judul Thread</li>
                        <li>Thread Description</li>
                        <li>Demo Link (jika ada)</li>
                        <li>Genre Thread</li>
                        <li>Tanggal mulai Thread dan Tanggal akhir Thread (max. durasi 30 hari)</li>
                    </ul>
                </li>
                <li>Klik “Next”</li>
                <li>Isi Token Distribution;</li>
                <li class="list-none">    
                    <ul class="list-decimal">
                        <li>Review Max Word Count (max. 1000 kata)</li>
                        <li>Attach Artwork or Video of your Game (upload max 1 video)</li>
                        <li>Import link dari Youtube (jika ada)</li>
                        <li>Token Allocation</li>
                    </ul>
                </li>
            </ul>         
            `
        },
        {
            'id' : 'developers-04',
            'title' : 'Bagaimana cara Top Up pada The Space?',
            'content' :
            `<p>Top Up dapat dilakukan melalui wallet ANOA, maka pastikan sudah memiliki wallet ANOA di <a href="https://www.anoatoken.com/" target="_blank">https://www.anoatoken.com/</a></p>`
        },
        {
            'id' : 'developers-05',
            'title' : 'Apa itu Active Threads, Total Threads dan Average Scoring pada Dashboard Developer?',
            'content' :
            `<ul class="list-disc">
                <li>Active Threads yaitu Thread yang sedang berjalan/aktif pada The Space.</li>
                <li>Total Threads yaitu jumlah keseluruhan Thread Developer yang ada pada The Space.</li>
                <li>Average Scoring adalah skor rata-rata dari Review Gamers pada Thread Game Developer itu sendiri.</li>
            </ul>`
        },
        {
            'id' : 'developers-06',
            'title' : 'Apa itu Threads List pada Dashboard Developer?',
            'content' :
            `<p>Threads List memudahkan Developer dalam melihat Threads yang sudah di publish pada halaman The Space, dengan mencantumkan Judul Threads yang sudah dipublish, tanggal publish, tanggal akhir publish, total review yang didapat dan status dari threads tersebut.</p>`
        },
        {
            'id' : 'developers-07',
            'title' : 'Bagaimana menjadi TOP Developer?',
            'content' :
            `<p>TOP Developer adalah Developer yang memiliki Thread pada The Space yang mempunyai total review paling banyak dari para Gamers dan juga memiliki Average Scoring lebih tinggi dibandingkan Developer lain. Untuk menjadi TOP Developer, anda harus memiliki Thread yang menarik perhatian para Gamers agar dapat memberikan review pada Thread anda.</p>`
        },
        {
            'id' : 'developers-08',
            'title' : 'Apakah target review dari Developer dalam satu Threads dibatasi?',
            'content' :
            `<p>Dalam satu Thread jumlah review tidak dibatasi, semua tergantung dari keputusan Developer berapa banyak token yang dialokasikan dan jangka waktu yang diberikan Developer. Tetapi satu akun gamers hanya bisa melakukan satu review pada satu Thread.</p>`
        },
        {
            'id' : 'developers-09',
            'title' : 'Bagaimana jika akun Freeze?',
            'content' :
            `<p>Jika pengguna log in dengan kesalahan password 3x, akun akan ter-freeze secara otomatis dan tidak dapat melakukan log in akun. Silahkan klik “Forgot Password” dan ikuti langkah selanjutnya.</p>`
        },
        {
            'id' : 'developers-10',
            'title' : 'Bagaimana jika akun Developer Suspend?',
            'content' :
            `<p>Akun Developer akan ter-suspend jika adanya laporan report thread dari Gamers atau akun mengalami Freeze selama 3x dalam 24jam. Jika akun Developer ter-suspend, thread akan di takedown, allocation akan dikembalikan dan segala bentuk aktivitas pada akun Developer tidak dapat dilakukan, namun hanya dapat log in pada akun tersebut.</p>`
        },
        {
            'id' : 'developers-11',
            'title' : 'Apa penyebab Blokir dapat terjadi pada akun Developer?',
            'content' :
            `<p>Jika akun Developer sudah berkali-kali mengalami suspend, admin akan memblokir akun tersebut dan allocation balance pada The Space tidak dapat dikembalikan. Developer tidak akan bisa mengakses The Space, namun dapat mengakses Wallet ANOA.</p>`
        },
        {
            'id' : 'gamers-01',
            'title' : 'Bagaimana register Gamers pada The Space?',
            'content' : 
            `<ul class='list-disc'>
                <li>Buka <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “Get Started” pada halaman The Space</li>
                <li>Klik “Register”</li>
                <li>Klik “Register as Gamers”</li>
                <li>Isi Data Pendukung;</li>
                <li class='list-none'>
                    <ul class='list-decimal'>
                        <li>Username</li>
                        <li>Nama Lengkap</li>
                        <li>Email</li>
                        <li>No Telepon</li>
                        <li>Set Password dan Confirm Password (password harus mengandung huruf kapital, huruf kecil, angka dan symbol)</li>
                    </ul>
                </li>
                <li>Klik “Register as Gamers”</li>
                <li>Lalu cek email yang sudah didaftarkan untuk mendapatkan notifikasi dari The Space.</li>
            </ul>`
        },
        {
            'id' : 'gamers-02',
            'title' : 'Bagaimana cara log in Gamers The Space?',
            'content' :
            `<ul class="list-disc">
                <li>Buka <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Klik “Get Started” pada halaman The Space</li>
                <li>Klik “Log in as Gamers”</li>
                <li>Isi Email Gamers yang sudah terdaftar pada akun The Space</li>
                <li>Isi Password yang dimiliki</li>
                <li>Klik “Login as Gamers”</li>
            </ul>
            `
        },
        {
            'id' : 'gamers-03',
            'title' : 'Bagaimana cara KYC Gamers pada The Space?',
            'content' :
            `<ul class="list-disc">
                <li>Login pada halaman Gamers di <a href="https://thespace.world/" target="_blank">https://thespace.world/</a></li>
                <li>Pada dashboard Gamers akan muncul pemberitahuan KYC dikiri bawah dengan tulisan “Your Account Has Not Been Activated”, lalu klik “Activate Account”</li>
                <li>Upload ID Card (KTP)</li>
                <li>Upload Selfie with ID Card</li>
                <li>Klik “Activate Account”</li>
                <li>Setelah mengunggah, harap menunggu untuk di Approve.</li>
            </ul>`
        },
        {
            'id' : 'gamers-04',
            'title' : 'Bagaimana cara saya mendapatkan token melalui review?',
            'content' :
            `<ul class="list-disc">
                <li>Pastikan sudah memiliki wallet ANOA di <a href="https://anoatoken.com/" target="_blank">https://anoatoken.com/</a></li>
                <li>Lalu hubungkan akun Anoa dengan The Space</li>
                <li>Jika sudah terhubung, buka Dashboard The Space Gamers</li>
                <li>Klik Thread yang akan di review pada halaman dashboard Gamers</li>
                <li>Pada halaman detail Thread, terlihat
                Anda dapat melihat semua detail Thread seperti Media, Deskripsi, Review dari Gamers lain, Link Demo Game, dan juga berapa banyak Token yang akan Anda dapatkan jika berkontribusi dalam mereview Thread tersebut.</li>
                <li>Jika Anda ingin berkontribusi klik Review.</li>
                <li>Dalam form ini, ada 3 bagian:
                    <ul class="list-decimal">
                        <li>Review: Threads memiliki jumlah kata minimum yang berbeda dengan Threads lainnya, maka isi review sesuai dengan yang ditentukan dalam Threads tersebut</li>
                        <li>Rating: Berikan review dengan menambahkan rating dengan 1 sampai 5</li>
                        <li>Upload Media: Dalam review sebuah Thread, dapat ditambah dengan unggahan foto maupun video Demo Game pada Thread tersebut. Memuat media merupakan kolom opsional dan dapat menyampaikan ulasan tanpa mengisi kolom ini. Namun ada tambahan reward token yang akan di dapatkan jika mengisi kolom ini.</li>
                    </ul>
                </li>
                <li>Setelah membuat review klik submit dan review akan diperiksa kembali oleh Admin</li>
                <li>Admin dapat menyetujui ataupun menolak review dengan ketentuan;
                    <ul class="list-decimal">
                        <li>Review tidak menggunakan kata-kata kasar/kotor dan tidak mengandung SARA</li>
                        <li>Review harus sesuai dengan Demo Game yang dipilih</li>
                    </ul>
                </li>
                <li>Jika ulasan disetujui oleh Admin, maka Token Gratis dapat dilihat pada menu Profil</li>
                <li>Jika ingin memeriksa status pemeriksaan ulasan;
                    <ul class="list-decimal">
                        <li>Klik Profil</li>
                        <li>Klik Total Review</li>
                    </ul>
                </li>
            </ul>`
        },
        {
            'id' : 'gamers-05',
            'title' : 'Bagaiamana cara melaporkan Thread?',
            'content' :
                `<ul class="list-disc">
                <li>Pada Dashboard The Space, pilih Thread yang akan dilaporkan</li>
                <li>Klik “Review”</li>
                <li>Klik “Report Thread”</li>
                <li>Lalu akan diarahkan untuk menghubungi Customer Service melalui Email atau Whatsapp yang tersedia.</li>
                <li>Masukkan keluhan terhadap Thread yang akan dilaporkan tersebut</li>
            </ul>`
        },
        {
            'id' : 'gamers-06',
            'title' : 'Apa itu Total Review, Total Earning dan Balance pada profil Game The Space?',
            'content' :
            `<ul class="list-disc">
                <li>Total Review adalah total Thread yang sudah di review oleh Gamers</li>
                <li>Total Earning adalah jumlah token yang didapatkan Gamers setelah mereview Thread</li>
                <li>Balance adalah hasil semua token yang didapatkan dari Developer untuk Gamers</li>
            </ul>`
        },
        {
            'id' : 'gamers-07',
            'title' : 'Bagaimana jika akun Gamers mengalami Suspend?',
            'content' :
            `<p>Jika Gamers melakukan submit review yang tidak sesuai pada Thread yang akan direview selama 3x, admin akan melakukan suspend pada akun Gamers. Hal tersebut membuat Gamers dapat log in namun tidak dapat melakukan segala bentuk aktivitas didalamnya.</p>`
        },
        {
            'id' : 'gamers-08',
            'title' : 'Apa penyebab Blokir dapat terjadi pada akun Gamers?',
            'content' :
            `<p>Jika Gamers sering medapatkan akun tersuspend, akun tersebut akan di blokir oleh sistem, akibatnya Gamers tidak dapat mengakses The Space kembali, namun masih bisa mengakses Wallet ANOA.</p>`
        },
    ];
    

    var filterDetail = dataDetailFAQ.filter(item => item.id === idDetail)[0];

    const handleDetail = (id) => {
        setIdDetail(id);
        setFaqClick(false);
        setDetailFaqToggle(true);
    }


    return (
        <>
            {
                loading ?
                    <BlurBarLoader></BlurBarLoader>
                    : null
            }

            <Transition
                show={modal}
                enter="ease-out duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
            <ReviewModal modal={modal} type={typeModal} threadIndex={id} tokenTrans={tokenTrans} count={tableThreadData} datathreads={detailData} loaddatapage={getPageData} loadgetthreads={fetchGameContent} cancel={() => setModal(false)} color={'developer'} />
            </Transition>
            <div className="flex flex-col w-full h-full">


                <div style={{ backgroundImage: "url('/images/main/mainBackground.svg')" }} className={isReadyToShow ? "gamesDetailBGContainerReady" : "gamesDetailBGContainer"}>
                    <MainHeader isAuthenticated={isAuthenticated} userData={location.state.userData} />

                    {
                        isReadyToShow ?
                            <div className='flex flex-col items-center justify-center overflow-hidden'>
                                <div className='flex justify-center flex-col items-center h-[90vh] w-[100%] overflow-auto'>
                                    <div className='mainContainer'>
                                        <div className='flex flex-row lg:px-[10%] sm:px-[5%] pt-[6%] items-center'>
                                            <div className='flex flex-col w-[69%]'>
                                                <div className='flex flex-row items-center gap-3 mb-3'>
                                                    <button onClick={() => navigate('/')}>
                                                        <FontAwesomeIcon icon={faArrowLeftLong} className="h-[3vh] w-[3vh] " style={{ color: "#ffffff", }} />
                                                    </button>
                                                    <span className='text-[#FFFF] text-xl'>back</span>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className='flex flex-col'>
                                                        <div className='text-3xl mb-3 text-[#FFFF] font-semibold' >{detailData.tablethreadtitle}</div>
                                                        <p className='mb-3 text-[#FFFF] font-semibold' >ID : {detailData.tablethreadindex}</p>
                                                    </div>
                                                    <div className='text-sm font-semibold px-8 items-center flex text-white bg-red-600 rounded-md h-[5vh]'>
                                                        {detailData.tablethreadstatus !== 'ENDED' ?
                                                            <span>Thread Period End In : {calculateRangeDate()} Day(s)</span>
                                                        :
                                                            <span>Thread is {detailData.tablethreadstatus}</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='flex flex-row items-center gap-3 mb-3'>
                                                    <div className='text-sm text-[#FFFF] self-center font-semibold flex flex-row gap-2'>
                                                        <a href={`/developers/`+detailData.tablethreaddeveloperuserindex}>{detailData.developer_name}</a> |
                                                        {
                                                            listGenre.length > 0 ?
                                                            listGenre.map((item, index) => {
                                                                return (
                                                                    // <div className='flex justify-center items-center bg-[#4C4DDC] px-3 py-1 rounded-2xl text-[#FFFF] text-sm'>{item.genrename}</div>
                                                                    // <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-[#080237] dark:text-yellow-400 border-2 border-[#E086DD]" key={index}>{item.genrename}</span>
                                                                    <span className='border-2 border-[#FF99F5] text-[#FF99F5] rounded-md px-2 mr-2 cursor-pointer' onClick={() => window.location.replace('/genre/'+item.genreid+'?name='+item.genrename)}>{item.genrename}</span>
                                                                )
                                                            }) : ''
                                                        }
                                                    </div>
                                                </div>
                                                {/* <div className='flex flex-row flex-wrap gap-2 mb-3'>
                                                    {
                                                        listGenre.map((item, index) => {
                                                            return (
                                                                // <div className='flex justify-center items-center bg-[#4C4DDC] px-3 py-1 rounded-2xl text-[#FFFF] text-sm'>{item.genrename}</div>
                                                                <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{item.genrename}</span>
                                                            )
                                                        })
                                                    }



                                                </div> */}

                                            </div>
                                        </div>
                                        <div className='flex flex-row lg:px-[10%] sm:px-[5%]'>
                                            <div className='w-[69%]'>
                                                {/* <div className='flex flex-col sm:h-[50vh] lg:h-[30vw] mb-12 w-[100%] bg-[#181332] '> */}
                                                <div className='flex flex-row '>
                                                    <div className='flex flex-col w-full bg-black '>

                                                        {
                                                            //top content
                                                            currentContent !== null ?
                                                                currentContent.type === 'image' ?
                                                                    <div className='relative w-full h-[60vh]'>
                                                                        <img src={currentContent.content.tablemediafilenamefullpath} alt='element' className='h-[100%] w-[100%] object-contain ' />
                                                                        {/* <div className='absolute top-[5vh] right-[1vw] text-md font-semibold z-10 px-8 mt-3 pt-[0.75vh] text-white bg-bm1  rounded-2xl h-[5vh]'>
                                                                            {calculateRangeDate()} Day
                                                                        </div>
                                                                        <div className='absolute bottom-[1vh] left-[1vw] text-md font-semibold z-10  pt-[0.75vh] backdrop-blur-md  text-white  h-[15vh]'>
                                                                            <div className='flex flex-col p-4'>
                                                                                <div></div>
                                                                                <div className='text-xl text-[#FFFF] font-semibold' >Game Title: {detailData.tablethreadtitle}</div>
                                                                                <div className='flex flex-row gap-3 mt-3'>
                                                                                    <div className=' text-[#EBFF00] bg-red-700 px-4 py-1 rounded-sm '>Reward: {detailData.tablethreadreviewreward} Anoa </div>
                                                                                    <div className=' text-[#EBFF00] py-1'>| Available Token: {detailData.tablethreadcoinallocation}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                    :
                                                                    <div className='relative h-[60vh] w-full' >
                                                                        {currentContent.type === 'link' ?
                                                                        <YouTube videoId={currentContent.content} opts={opts} />
                                                                        :
                                                                        thumbnails ?
                                                                            <video controls={true} loop={true} autoPlay={true} className='w-full h-full'>
                                                                                <source src={currentContent.content.tablemediafilenamefullpath}/>
                                                                            </video>
                                                                        : 
                                                                            <img src={currentContent.content.tablemediathumbnailfullpath} alt='element' className='cursor-pointer h-[100%] w-[100%] object-contain ' onClick={() => setThumbnails(true)}/>
                                                                        }
                                                                    </div>
                                                                : null
                                                        }
                                                        {/* <div className='flex flex-col p-4'>
                                                    <div className='text-xl text-[#FFFF] font-semibold' >Game Title: {detailData.tablethreadtitle}</div>
                                                    <div className='flex flex-row gap-3 mt-3'>
                                                        <div className=' text-[#EBFF00] bg-red-700 px-4 py-1 rounded-sm '>Reward: {detailData.tablethreadreviewreward} Anoa </div>
                                                        <div className=' text-[#EBFF00] py-1'>| Available Token: {detailData.tablethreadcoinallocation}</div>
                                                    </div>
                                                </div> */}
                                                    </div>


                                                </div>

                                                <div className='flex justify-center w-[100%]  sm:h-[10vh]'>
                                                    <Carousel
                                                        // key={isRemounting}
                                                        ssr={false}
                                                        showThumbs={false}
                                                        swipeable={true}
                                                        ref={el => setRefVal(el)}
                                                        customTransition="all .5"
                                                        transitionDuration={500}
                                                        partialVisbile={false}
                                                        // customButtonGroup={<CustomSlider />}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        itemClass="slider-image-item"
                                                        responsive={responsive}
                                                        containerClass="carousel-container-with-scrollbar"
                                                        additionalTransfrom={-additionalTransfrom}
                                                        beforeChange={nextSlide => {
                                                            if (nextSlide !== 0 && additionalTransfrom !== 150) {
                                                                setadditionalTransfrom(150)
                                                            }
                                                            if (nextSlide === 0 && additionalTransfrom === 150) {
                                                                setadditionalTransfrom(0)
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            mainContent.map((item) => {
                                                                console.log(item.type)
                                                                return (
                                                                    item.type === 'image' ?
                                                                        <div key={item.content} onClick={() => updateContent(item)} className={item.selected ? 'thumbnailContainerSelected' : 'thumbnailContainer'}>
                                                                            <img src={item.content.tablemediafilenamefullpath} alt='element' className='thumbnailImage' />
                                                                        </div>
                                                                        :
                                                                        item.type === 'link' ? 
                                                                        <div key={item.content} onClick={() => updateContent(item)} className={item.selected ? 'thumbnailContainerSelected' : 'thumbnailContainer'}>
                                                                            {/* <div style={{ backgroundImage: `url(https://back.thespace.world/public/${item.content})` }} className='thumbnailImage'>  */}
                                                                            <div style={{ backgroundImage: `url('/images/youtube.png')` }} className='thumbnailVideo'>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div key={item.content} onClick={() => updateContent(item)} className={item.selected ? 'thumbnailContainerSelected' : 'thumbnailContainer'}>
                                                                            {/* <div style={{ backgroundImage: `url(https://back.thespace.world/public/${item.content})` }} className='thumbnailImage'>  */}
                                                                            <div style={{ backgroundImage: `url('${item.content.tablemediathumbnailfullpath}')` }} className='thumbnailVideo'>
                                                                                <div className='playBtnContainer'>
                                                                                    <img src={process.env.PUBLIC_URL + '/images/main/playbtn.png'} alt="element" className='playLogo' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>;
                                                </div>
                                                <div className='w-full flex flex-row justify-between mt-[10vh] '>
                                                    <div className='text-md w-full text-[#D7DCDF] break-words'>
                                                    {detailData.tablethreaddescription}
                                                    </div>
                                                </div>
                                                <div className='flex flex-row mt-5 justify-end w-full'>
                                                    <div className='flex flex-row gap-3 w-[31%]'>
                                                        <button onClick={() => generateShareLink(detailData.tablethreadindex)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.68289 13.3419C8.88518 12.9381 8.99902 12.4823 8.99902 12C8.99902 11.5176 8.88518 11.0618 8.68289 10.658M8.68289 13.3419C8.19036 14.3251 7.17351 15 5.99902 15C4.34217 15 2.99902 13.6568 2.99902 12C2.99902 10.3431 4.34217 8.99997 5.99902 8.99997C7.17351 8.99997 8.19036 9.67488 8.68289 10.658M8.68289 13.3419L15.3152 16.658M8.68289 10.658L15.3152 7.3419M15.3152 7.3419C15.8077 8.32505 16.8245 8.99997 17.999 8.99997C19.6559 8.99997 20.999 7.65682 20.999 5.99997C20.999 4.34312 19.6559 2.99997 17.999 2.99997C16.3422 2.99997 14.999 4.34312 14.999 5.99997C14.999 6.48234 15.1129 6.93812 15.3152 7.3419ZM15.3152 16.658C15.1129 17.0618 14.999 17.5176 14.999 18C14.999 19.6568 16.3422 21 17.999 21C19.6559 21 20.999 19.6568 20.999 18C20.999 16.3431 19.6559 15 17.999 15C16.8245 15 15.8077 15.6749 15.3152 16.658Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                        </button>
                                                        {
                                                            isAuthenticated === "Developer" ?
                                                                <></>
                                                                :
                                                                <>
                                                                    <button onClick={() => isAuth ? window.open(demoLink, '_blank', 'noopener') : directToLoginPage()} className='w-[100%] bg-bm2 py-[3.5%]  rounded-md text-[#FFFF] text-[14px]'>
                                                                        Game Demo
                                                                    </button>

                                                                    {/* {
                                                                        reviewData[0].tablethreadstatus === null ?
                                                                            <button onClick={() => isAuth ? getTokenTrans() : directToLoginPage()} className='w-[100%] bg-bm2 py-[3.5%] rounded-md text-[#FFFF] text-[14px]'>
                                                                                Review
                                                                            </button>
                                                                            :
                                                                            <button className='w-[100%] bg-bm2 py-[5%] rounded-md text-[#FFFF] text-[14px]'>
                                                                                {
                                                                                    reviewData[0].tablethreadstatus === "NEED REVIEW" ? 'On Review'
                                                                                        : reviewData[0].tablethreadstatus === "REJECT" ? 'Rejected'
                                                                                            : reviewData[0].tablethreadstatus === "APPROVE" ? 'Approved'
                                                                                                : null
                                                                                }
                                                                            </button>
                                                                    } */}

                                                                    {/* { reviewData.length > 0 ?
                                                                        <button className='w-[100%] bg-bm2 py-[5%] rounded-md text-[#FFFF] text-[14px]'>
                                                                            {
                                                                                reviewData[0].tablereviewstatus === "NEED REVIEW" ? 'On Review'
                                                                                    : reviewData[0].tablereviewstatus === "REJECT" ? 'Rejected'
                                                                                        : reviewData[0].tablereviewstatus === "APPROVE" ? 'Approved'
                                                                                            : null
                                                                            }
                                                                        </button>
                                                                    : 
                                                                        
                                                                    } */}

                                                                    {loadButtonReview(detailData, isAuth)}

                                                                    
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="mt-5">
                                                    <h3 className="text-[28px] text-white font-semibold">Gamer Reviews</h3>
                                                    <div className="overflow-auto flex flex-row gap-5 mt-2 items-center">
                                                        {cancelFilter === true ?
                                                        <div className="cursor-pointer" onClick={cancelFilterHandle}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                        </div> : '' }
                                                        {totalRating.map((result, key) => (
                                                            <div className={selectedFilter === result.id ? 'bg-[#0E2E56] flex flex-row items-center gap-2 justify-center py-[10px] px-[16px] rounded-md cursor-pointer border-2 border-yellow-400' : `bg-[#0E2E56] flex flex-row items-center gap-2 justify-center py-[10px] px-[16px] rounded-md cursor-pointer`} key={key} onClick={() => filterRating(result.id)}>
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_3393_26083)">
                                                                        <path d="M13.7735 3.5936C13.0481 2.12362 10.9519 2.12362 10.2265 3.59359L8.93346 6.21349C8.64537 6.79722 8.0885 7.20181 7.44432 7.29542L4.55309 7.71554C2.93088 7.95126 2.28313 9.9448 3.45698 11.089L5.54909 13.1283C6.01522 13.5827 6.22793 14.2373 6.11789 14.8789L5.62401 17.7585C5.3469 19.3741 7.04271 20.6062 8.49367 19.8434L11.0797 18.4839C11.6558 18.1809 12.3442 18.1809 12.9203 18.4839L15.5063 19.8434C16.9573 20.6062 18.6531 19.3741 18.376 17.7585L17.8821 14.8789C17.7721 14.2373 17.9848 13.5827 18.4509 13.1283L20.543 11.089C21.7169 9.9448 21.0691 7.95126 19.4469 7.71554L16.5557 7.29542C15.9115 7.20181 15.3546 6.79722 15.0665 6.21349L13.7735 3.5936Z" stroke="white" stroke-width="2"/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_3393_26083">
                                                                        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                                <label className="text-white cursor-pointer">{result.id} ( {result.totalrating} )</label>
                                                            </div>
                                                        ))}
                                                        <div className={selectedFilter === 'media' ? 'bg-[#0E2E56] flex flex-row items-center gap-2 justify-center py-[10px] px-[16px] rounded-md cursor-pointer border-2 border-yellow-400' : `bg-[#0E2E56] flex flex-row items-center gap-2 justify-center py-[10px] px-[16px] rounded-md cursor-pointer`} onClick={filterMedia}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21 4H8M21 8H12M21 12H15M11 12L7 8M7 8L3 12M7 8V20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>

                                                            <label className="text-white cursor-pointer">Media</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='w-[100%] lg:h-[12.5%] sm:h-[5.5%]  flex flex-row mt-3 flex-wrap justify-between items-center'>
                                                    <div className='flex flex-row h-[12vh] gap-6 items-center '>
                                                        <img src={"https://back.thespace.world/public/" + detailData.developer_pict} alt="element" className='object-fit w-[100px] h-[100px] rounded-[50%]' />
                                                        <div>
                                                            <h4 className='text-[#D7DCDF]'>{detailData.developer_name}</h4>
                                                        </div>
                                                    </div>
                                                    <div className='py-3 w-fit h-fit'>
                                                        <div className='flex flex-row bg-[#181332] h-[100%] rounded-3xl py-1 pr-3'>
                                                            <div className='flex flex-col px-3  w-[50%]'>
                                                                <div className='h-fit'>
                                                                    <label className='text-[#FFFFFF] text-[12px]'>Review</label>
                                                                </div>
                                                                <div className='flex flex-row text-[#EBFF00] justify-start items-center gap-2'>
                                                                    <h3 >{(detailData.total_review)}</h3>
                                                                    <h7 >Review</h7>

                                                                </div>
                                                            </div>
                                                            <div className='flex flex-col w-[50%] px-3  border-l-[1px] border-l-[#7A7BFB] h-[100%]'>
                                                                <div className='h-fit '>
                                                                    <label className='text-[#FFFFFF] text-[12px]'>Rating</label>
                                                                </div>
                                                                <div className='flex flex-row text-[#EBFF00] justify-start items-center gap-2'>
                                                                    <h3 >{detailData.avg_rating}</h3>
                                                                    <h7 >/5.0</h7>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                            </div>
                                            <div className='flex flex-col w-[27%] ml-[3.75%] '>
                                                <span className='text-[#FFFF] text-2xl'>Token Disbursement</span>
                                                <div className='shadow-2xl bg-[#A300BD] flex flex-col justify-center gap-3 h-[13vh]  px-6 mt-3'>
                                                    <span className='text-[#FFFF] text-lg'>Gamers' Earning</span>
                                                    <div className='flex flex-row text-[#FFFF] gap-1 items-center'>
                                                        {/* <FontAwesomeIcon icon={faChartSimple} className="h-[3vh] w-[3vw]" /> */}
                                                        <span className='text-lg place-self-end'>Up To </span>
                                                        <span className='text-3xl'>{detailData.tablethreadreviewreward}/</span>
                                                        <span className='text-lg place-self-end'>Review</span>
                                                    </div>
                                                </div>
                                                <div className='shadow-2xl bg-[#181332] flex flex-col justify-center gap-3 h-[13vh] px-6 mt-3'>
                                                    <span className='text-[#FFFF] text-lg'>Available Token</span>
                                                    <div className='flex flex-row text-[#FFFF] gap-1 items-center'>
                                                        {/* <FontAwesomeIcon icon={faWallet} className="h-[3vh] w-[3vw]" /> */}
                                                        <span className='text-3xl'>{detailData.availabletoken}</span>
                                                        <span className='text-lg place-self-end'>Token Left</span>
                                                    </div>
                                                </div>

                                                <span className='text-[#FFFF] text-2xl mt-5'>Reviews</span>
                                                <div className='shadow-2xl bg-[#181332] flex flex-col justify-center gap-3 h-[13vh] px-6 mt-3'>
                                                    <span className='text-[#FFFF] text-lg'>AVG Rating</span>
                                                    <div className='flex flex-row text-[#FFFF] gap-1 items-center'>
                                                        {/* <FontAwesomeIcon icon={faStar} className="h-[3vh] w-[3vw]" /> */}
                                                        <span className='text-3xl'>{detailData.avg_rating}/</span>
                                                        <span className='text-lg place-self-end'>5.0</span>
                                                    </div>
                                                </div>
                                                {/* <div className='shadow-2xl bg-[#181332] flex flex-col justify-center gap-3 h-[13vh] px-6 mt-3'>
                                                    <span className='text-[#FFFF] text-lg'>Review Approved</span>

                                                    <div className='flex flex-row text-[#FFFF] gap-1 items-center'>
                                                        <FontAwesomeIcon icon={faCheck} className="w-[3vw]" size='2xl' />
                                                        <span className='text-3xl'>{detailData.tablethreadcoinallocation}</span>
                                                        <span className='place-self-end'>Not Available Yet</span>
                                                    </div>
                                                </div> */}
                                                <div className='shadow-2xl bg-[#181332] flex flex-col justify-center gap-3 h-[13vh] px-6 mt-3'>
                                                    <span className='text-[#FFFF] text-lg'>Total Reviews</span>
                                                    <div className='flex flex-row text-[#FFFF] gap-1 items-center'>
                                                        {/* <FontAwesomeIcon icon={faUserCheck} className="w-[3vw]" size='2xl' /> */}
                                                        <span className='text-3xl'>{detailData.total_review}</span>
                                                        <span className='self-end text-xl'>Review(s)</span>
                                                    </div>
                                                </div>

                                                <div className='bg-[#404040] mt-[36px] px-[16px] py-[12px] rounded-lg flex flex-row gap-4 cursor-pointer' onClick={() => setModalReport(true)}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.9998 8.99999V13M11.9998 17H12.0098M10.6151 3.89171L2.39019 18.0983C1.93398 18.8863 1.70588 19.2803 1.73959 19.6037C1.769 19.8857 1.91677 20.142 2.14613 20.3088C2.40908 20.5 2.86435 20.5 3.77487 20.5H20.2246C21.1352 20.5 21.5904 20.5 21.8534 20.3088C22.0827 20.142 22.2305 19.8857 22.2599 19.6037C22.2936 19.2803 22.0655 18.8863 21.6093 18.0983L13.3844 3.89171C12.9299 3.10654 12.7026 2.71396 12.4061 2.58211C12.1474 2.4671 11.8521 2.4671 11.5935 2.58211C11.2969 2.71396 11.0696 3.10655 10.6151 3.89171Z" stroke="#CB3A31" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    <label className="text-[#CB3A31] text-[16px] font-bold">Report Thread</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-wrap lg:px-[10%] sm:px-[5%] justify-between mt-12 sm:flex-col lg:flex-row-reverse'>
                                            <div className='flex flex-col lg:w-[27%] sm:w-[27%] mb-5 '>
                                                <div className='w-[100%]'>
                                                    <h2 className='text-[#FFFFFF]'>Rating</h2>
                                                    <div className='flex flex-row h-[30%] items-center'>
                                                        <h2 className='text-[#EBFF00]'>{detailData.avg_rating}</h2>

                                                        <h7 className='text-[#EBFF00]'>/5</h7>

                                                    </div>
                                                    <div className='flex flex-col'>
                                                        {
                                                            showRatingProgressBar()
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex flex-col  text-[#989B9D] sm:items-center sm:w-[100%] lg:w-[69%]'>
                                                {
                                                    reviewData.map((item, index) => {
                                                        return (
                                                            <div className='flex flex-row mt-3 sm:w-full pl-[3%] '>
                                                                <div className='flex items-start justify-start w-12 h-12 mr-6 overflow-hidden '>
                                                                    {
                                                                        item.tableuserphotopathfullpath ?
                                                                            <img src={item.tableuserphotopathfullpath} alt="element" className='object-fit  h-[100%] w-[100%] rounded-[50%]' />
                                                                            :
                                                                            <img src={process.env.PUBLIC_URL + '/images/main/userPhotoTemp.svg'} alt="element" className='object-fit  h-[100%] w-[100%] rounded-[50%]' />
                                                                    }
                                                                </div>
                                                                <div className='flex flex-col border-b-2 h-fit pb-9 border-b-[#656CFF] w-[95%]'>
                                                                    <h5>{item.tableuserfullname}</h5>
                                                                    <div className='flex flex-row gap-1 '>
                                                                        {
                                                                            displayRating(item.tablereviewrating)
                                                                        }

                                                                    </div>
                                                                    <h6 className='mt-3 text-[#D7DCDF]'>
                                                                        {item.tablereviewdescription}
                                                                    </h6>
                                                                    <p className='mt-5'><a href={item.tablereviewyoutubelink} target='_blank' className='text-white'>{item.tablereviewyoutubelink}</a></p>
                                                                    <div className='flex flex-row flex-wrap pl-[0%] w-[100%]'>
                                                                        {item.tablereviewpictfullpath.length > 0 ?
                                                                        item.tablereviewpictfullpath.map((result, key) => (
                                                                            <div className='flex min-w-[10vw] mt-3 h-[15vh] 'key={key}>
                                                                                <img src={result} alt='element' className='w-[100%] h-[100%] object-contain' ></img>
                                                                            </div>
                                                                        )) : '' }

                                                                        {item.tablereviewvideofullpath.length > 0 ?
                                                                        item.tablereviewvideofullpath.map((result, key) => (
                                                                            <div className='flex min-w-[10vw] mt-3 h-[15vh] 'key={key}>
                                                                                <video controls>
                                                                                    <source src={result} />
                                                                                    Your browser does not support the video tag.
                                                                                </video>
                                                                            </div>
                                                                        )) : '' }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                        {/* <div className='footer-content'>
                                            <div className='flex flex-row justify-between border-b-2 border-b-[#27B1FF] pb-16'>
                                                <div className=' flex flex-col justify-start w-[30%]  '>
                                                    <div className='w-[100%] flex justify-start'>
                                                        <img src={process.env.PUBLIC_URL + '/images/main/spaceanoalogo.svg'} alt="element" className="object-fill w-[40%] " />
                                                    </div>
                                                    <label className=' text-[#707375]'>
                                                        dolor sit amet consectetur. Facilisi sed id feugiat id in facilisi, dolor sit amet consectetur. Facilisi sed id feugiat id in facilisi, dolor sit amet consectetur. Facilisi sed id feugiat id in facilisi, dolor sit amet consectetur. Facilisi sed id feugiat id in facilisi
                                                    </label>
                                                </div>
                                                <div className='flex flex-col text-[#FFFF] gap-4'>
                                                    <label>All Games</label>
                                                    <label>RPG</label>
                                                    <label>FPS</label>
                                                    <label>MOBA</label>
                                                    <label>Adventure</label>

                                                </div>
                                            </div>

                                        </div> */}
                                    </div>

                                </div>

                            </div> : null
                    }


                </div>
            </div>
            <ReportModal modal={modalReport} closemodal={() => setModalReport(false)}/>
            <div className='fixed bottom-5 right-8 bg-[#0E2E56] p-[16px] rounded-[360px] cursor-pointer' onClick={() => setFaqClick(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.22766 9.00146C8.77678 7.83627 10.2584 7.00146 12.0001 7.00146C14.2092 7.00146 16.0001 8.34461 16.0001 10.0015C16.0001 11.4009 14.7224 12.5765 12.9943 12.9081C12.4519 13.0121 12.0001 13.4492 12.0001 14.0015M12 17.0015H12.01M21 12.0015C21 16.972 16.9706 21.0015 12 21.0015C7.02944 21.0015 3 16.972 3 12.0015C3 7.0309 7.02944 3.00146 12 3.00146C16.9706 3.00146 21 7.0309 21 12.0015Z" stroke="#D7DCDF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            {faqClick ?
            <div className='bg-white fixed bottom-5 right-24 w-[437px] rounded-[16px] p-[16px]'>
                <div className='flex flex-row justify-between mb-[32px]'>
                    <div>
                        <h1 className='text-[28px]'>Hey, SpaceExplorer👋</h1>
                        <h1 className='text-[28px]'>How can we help?</h1>
                    </div>
                    <div className='cursor-pointer' onClick={() => setFaqClick(false)}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 24L24 8M8 8L24 24" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className='flex flex-row gap-2 mb-[30px]'>
                    <button className="text-[12px] p-[10px] rounded-[6px] font-semibold" style={{ backgroundColor: faqToggle ? '#EBF0F4' : '#4C4DDC', color: faqToggle ? '#989B9D' : '#ffffff'}} onClick={() => setFaqToggle(false)}>Developers</button>
                    <button className="text-[12px] p-[10px] rounded-[6px] font-semibold" style={{ backgroundColor: faqToggle ? '#4C4DDC' : '#EBF0F4', color: faqToggle ? '#ffffff' : '#989B9D'}} onClick={() => setFaqToggle(true)}>Gamers</button>
                </div>
                <div className='mb-[30px] flex flex-col gap-2 overflow-auto max-h-[300px]'>
                    {faqToggle === false ?
                    dataFAQDevelopers.map((result) => (
                        <div className='w-full border-2 border-[#E4E8EC] p-[12px] rounded-[12px] cursor-pointer shadow-sm' key={result.id} onClick={() => handleDetail(result.id)}>
                            <div className='flex flex-row items-center justify-between'>
                                <div>
                                    <h1 className='text-[16px] font-semibold'>{result.title}</h1>
                                    <span className='text-[#4B5563] text-[14px]'>{result.paragraf}</span>
                                </div>
                                <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 5.00146L16 12.0015L9 19.0015" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )) : ''}

                    {faqToggle === true ?
                    dataFAQGamers.map((result) => (
                        <div className='w-full border-2 border-[#E4E8EC] p-[12px] rounded-[12px] cursor-pointer shadow-sm' key={result.id} onClick={() => handleDetail(result.id)}>
                            <div className='flex flex-row items-center justify-between'>
                                <div>
                                    <h1 className='text-[16px] font-semibold'>{result.title}</h1>
                                    <span className='text-[#4B5563] text-[14px]'>{result.paragraf}</span>
                                </div>
                                <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 5.00146L16 12.0015L9 19.0015" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )) : ''}
                </div>
                <a href="https://api.whatsapp.com/message/ZS7PKPEKCPGVC1?autoload=1&app_absent=0" target="_blank" className='no-underline'>
                    <div className='bg-[#F0F3FF] p-[12px] rounded-[12px] cursor-pointer'>
                        <div className='flex flex-row items-center gap-2'>
                            <img src={process.env.PUBLIC_URL+'/images/whatsapp-icon.png'} alt='whatsapp-icon'/>
                            <div className='flex flex-col w-full'>
                                <span className='text-[#4C4DDC] font-semibold'>Send us a message on Whatsapp!</span>
                                <span className='text-[#707375]'>We typically reply in a few minutes</span>
                            </div>
                            <div>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5146_4002)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9046 22.1976L21.1466 7.16437C21.6004 5.55796 20.1591 4.11667 18.5517 4.56945L3.51845 8.81146C3.15195 8.91489 2.81618 9.11221 2.5449 9.38356C2.27363 9.65492 2.07642 9.99075 1.97311 10.3573C1.8698 10.7238 1.86403 11.1081 1.95638 11.4716C2.04872 11.835 2.23594 12.1648 2.49919 12.4277L13.2894 23.2179C13.5523 23.4811 13.882 23.6683 14.2455 23.7607C14.6089 23.853 14.9932 23.8472 15.3598 23.7439C15.7263 23.6406 16.0621 23.4434 16.3335 23.1721C16.6048 22.9009 16.8022 22.5651 16.9056 22.1986L16.9046 22.1976ZM12.6996 13.9814L9.49989 19.4284L6.28869 16.2172L11.7337 13.0155C12.3754 12.6395 13.0756 13.3396 12.6986 13.9804L12.6996 13.9814Z" fill="#4C4DDC"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5146_4002">
                                            <rect width="24" height="24" fill="white" transform="translate(0 0.600342)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </a>
            </div> : '' }
            {detailFaqToggle ?
            <div className='bg-white fixed bottom-5 right-24 w-[437px] rounded-[16px]'>
                <div className='border-b-2 border-[#EBF0F4]'>
                    <div className='flex flex-row justify-between p-[16px]'>
                        <div onClick={() => { setDetailFaqToggle(false); setFaqClick(true) }} className='cursor-pointer'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18.9985L8 11.9985L15 4.99854" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div onClick={() => { setDetailFaqToggle(false); setFaqClick(false) }} className='cursor-pointer'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 17.999L18 5.99902M6 5.99902L18 17.999" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='p-[16px]'>
                    <div className='mb-[48px]'>
                        {filterDetail ? <>
                        <h1 className='text-[28px] pb-[48px]'>{filterDetail.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: filterDetail.content }} className='overflow-auto max-h-[500px]'></div>
                        </> : '' }
                    </div>
                    <a href="https://api.whatsapp.com/message/ZS7PKPEKCPGVC1?autoload=1&app_absent=0" target="_blank" className='no-underline'>
                        <div className='bg-[#F0F3FF] p-[12px] rounded-[12px] cursor-pointer'>
                            <div className='flex flex-row items-center gap-2'>
                                <img src={process.env.PUBLIC_URL+'/images/whatsapp-icon.png'} alt='whatsapp-icon'/>
                                <div className='flex flex-col w-full'>
                                    <span className='text-[#4C4DDC] font-semibold'>Send us a message on Whatsapp!</span>
                                    <span className='text-[#707375]'>We typically reply in a few minutes</span>
                                </div>
                                <div>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_5146_4002)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9046 22.1976L21.1466 7.16437C21.6004 5.55796 20.1591 4.11667 18.5517 4.56945L3.51845 8.81146C3.15195 8.91489 2.81618 9.11221 2.5449 9.38356C2.27363 9.65492 2.07642 9.99075 1.97311 10.3573C1.8698 10.7238 1.86403 11.1081 1.95638 11.4716C2.04872 11.835 2.23594 12.1648 2.49919 12.4277L13.2894 23.2179C13.5523 23.4811 13.882 23.6683 14.2455 23.7607C14.6089 23.853 14.9932 23.8472 15.3598 23.7439C15.7263 23.6406 16.0621 23.4434 16.3335 23.1721C16.6048 22.9009 16.8022 22.5651 16.9056 22.1986L16.9046 22.1976ZM12.6996 13.9814L9.49989 19.4284L6.28869 16.2172L11.7337 13.0155C12.3754 12.6395 13.0756 13.3396 12.6986 13.9804L12.6996 13.9814Z" fill="#4C4DDC"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_5146_4002">
                                                <rect width="24" height="24" fill="white" transform="translate(0 0.600342)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            : '' }
        </>
    )
}
