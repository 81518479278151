const ReportModal = (props) => {
    return(
        <>
            {props.modal ? 
            <div className="fixed inset-0 w-full h-full bg-bg1 backdrop-blur-[3px] flex flex-col items-center justify-center" onClick={props.closemodal}>
                <div className="bg-[#1B2026] w-[612px] p-[24px] rounded-xl shadow-2xl flex flex-col justify-center items-center">
                    <div className="mt-10 mb-10">
                        <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M63.9988 47.9999V69.3333M63.9988 90.6666H64.0521M56.6138 20.7558L12.7477 96.5245C10.3147 100.727 9.09811 102.828 9.27791 104.553C9.43474 106.057 10.2228 107.424 11.4461 108.314C12.8485 109.333 15.2766 109.333 20.1327 109.333H107.865C112.721 109.333 115.149 109.333 116.551 108.314C117.775 107.424 118.563 106.057 118.72 104.553C118.899 102.828 117.683 100.727 115.25 96.5244L71.3837 20.7558C68.9594 16.5683 67.7472 14.4745 66.1657 13.7713C64.7862 13.1579 63.2114 13.1579 61.8318 13.7713C60.2504 14.4745 59.0382 16.5683 56.6138 20.7558Z" stroke="#CB3A31" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className="mb-[64px]">
                        <h1 className="text-[20px] text-white text-center">Report Thread</h1>
                        <p className="text-[#E6E1E5] text-center">You can make a report to this thread by contacting our customer service via email info@atn.co.id, or whatsapp to +628-517-236-6789.</p>
                    </div>
                </div>
            </div> : '' }
        </>
    );
}

export default ReportModal;