import errormessage from "./errormessage.json";

export default function ErrorController(type) {
  const { error } = type;
  return (
    <>
      <span className="flex items-center font-sans tracking-wide text-xs mt-1 ml-1 border px-1.5 py-1.5 bg-[#CB3A3140] rounded-lg text-white">
        <div className="pr-3">
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.00003 6.5V8.16667M9.00003 11.5H9.00836M3.22653 14.8333H14.7735C16.0565 14.8333 16.8584 13.4444 16.2169 12.3333L10.4434 2.33333C9.8019 1.22222 8.19815 1.22222 7.55665 2.33333L1.78315 12.3333C1.14165 13.4444 1.94353 14.8333 3.22653 14.8333Z"
              stroke="#FFF4F2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {error === "genre"
          ? errormessage.genre_message
          : error === "title"
          ? errormessage.title_message
          : error === "description"
          ? errormessage.description_message
          : error === "datestart"
          ? errormessage.datestart_message
          : error === "dateend"
          ? errormessage.dateend_message
          : error === "artwork"
          ? errormessage.artwork_message
          : error === "wordCount"
          ? errormessage.wordcount_message
          : error === "tokenerror"
          ? errormessage.tokenError_message
          : error === "dateRangeNotValid"
          ? errormessage.dateRangeNotValid_message
          : error === 'targetType'
          ? errormessage.targetType_message
          : null}
      </span>
    </>
  );
}
