import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

export const LineCharts = (props) => {
    const { data, from } = props;
 
    return (

        <ResponsiveContainer width="100%" height="100%">
            {
                from !== 'disbursement' ?
                    <LineChart
                        width={600}
                        height={600}
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" type="category" />
                        <YAxis dataKey="views" />
                        <Tooltip />
                        <Legend />
                        <Line dataKey="views" stroke="#8884d8" />
                        <Line dataKey="review" stroke="#82ca9d" />
                    </LineChart> :
                    <LineChart
                        width={600}
                        height={600}
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" type="category" />
                        <YAxis dataKey="anoa" />
                        <Tooltip />
                        <Legend />
                        <Line dataKey="anoa" stroke="#4C4DDC" />
                        {/* <Line dataKey="views" stroke="#82ca9d" /> */}
                    </LineChart>
            }

        </ResponsiveContainer>
    )
}