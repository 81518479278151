import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "./containers/login/Login";
import LoginDevelopers from "./containers/login/LoginDevelopers";
import LoginGemers from "./containers/login/LoginGames";
import Home from "./containers/pages/home/Home";
import Profile from "./containers/pages/profile/Profile";
import Threads from "./containers/pages/threads/Threads";
import ThreadsDetail from "./containers/pages/threads/threadDetail/view/ThreadsDetail";
import ThreadsAdd from "./containers/pages/threads/threadsAdd/view/ThreadAdd";
import ThreadsNew from "./containers/pages/threads/ThreadsNew";
import Register from "./containers/register/Register";
import RegisterDevelopers from "./containers/register/RegisterDevelopers";
import RegisterGamers from "./containers/register/RegisterGamers";
import Logout from "./containers/logout/Logout";
import MainPage from "./containers/main/MainPage";
import GamesDetail from "./containers/gamersPage/GamesDetail";
import GamersProfile from "./containers/gamersPage/profile/GamersProfile";
import VerificationEmail from "./containers/auth/VerificationEmail";
import PageNotFound from "./containers/auth/PageNotFound";
import Review from "./containers/gamersPage/profile/review/Review";
import Earning from "./containers/gamersPage/profile/earning/Earning";
import Developer from "./containers/password/Developer";
import Gamer from "./containers/password/Gamer";
import Username from "./containers/password/Username";
import DeveloperPage from "./containers/main/DeveloperPage";
import ShareThreads from "./containers/main/ShareThreads";
import ForgotPasswordGamer from "./containers/login/ForgotPasswordGamer";
import ForgotPasswordDeveloper from "./containers/login/ForgotPasswordDeveloper";
import ReviewList from "./containers/pages/review/Review";
import DetailReview from "./containers/pages/review/DetailReview";
import { ThreadsExport } from "./containers/pages/threads/threadDetail/view/ThreadsExport";
import GamerPage from "./containers/main/GamerPage";
import MyWallet from "./containers/gamersPage/profile/MyWallet";
import GenrePage from "./containers/main/GenrePage";
import BlankPage from "./components/blankPage";
const App = () => {

	return(
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<MainPage/>}/>
				<Route path="/mainLogin" element={<Login/>}/>
				<Route path="/login/developers" element={<LoginDevelopers/>}/>
				<Route path="/login/gamers" element={<LoginGemers/>}/>

				<Route path="/verificationEmail/:code" element={<VerificationEmail/>}/>
				<Route path="/pageNotFound" element={<PageNotFound/>}/>

				<Route path="/register" element={<Register/>}/>
				<Route path="/register/developers" element={<RegisterDevelopers/>}/>
				<Route path="/register/gamers" element={<RegisterGamers/>}/>

				<Route path="/developer/home" element={<Home/>}/>
				<Route path="/developer" element={<Navigate to="/developer/home" />} />

				<Route path="/developer/threads/new" element={<ThreadsNew/>}/>
				<Route path="/developer/threads" element={<Threads/>}/>
				<Route path="/developer/threads/add" element={<ThreadsAdd/>}/>
				<Route path="/developer/threads/:id" element={<ThreadsDetail/>}/>
				<Route path="/developer/threads/export/:id" element={<ThreadsExport/>}/>
				<Route path="/developer/new-password/:id" element={<Developer/>}/>
				<Route path="/developer/forgot-password" element={<ForgotPasswordDeveloper/>}/>
				<Route path="/developer/review" element={<ReviewList/>}/>
				<Route path="/developer/review/:id" element={<DetailReview/>}/>

				{/* <Route path="/gamers/gamersHome" element={<HomePage/>}/>
				<Route path="/gamers/gamersGames" element={<GamersPage/>}/> */}
				<Route path="/gamers/gamesDetail/:id" element={<GamesDetail/>}/>
				<Route path="/gamers/gamesDetail/:id/:review" element={<GamesDetail/>}/>
				<Route path="/gamers/gamersProfile" element={<GamersProfile/>}/>
				<Route path="/gamers/wallet" element={<MyWallet/>}/>
				<Route path="/gamers/review" element={<Review/>}/>
				<Route path="/gamers/earning" element={<Earning/>}/>
				<Route path="/developers/:id" element={<DeveloperPage/>}/>
				<Route path="/gamer/:id" element={<GamerPage/>}/>
				<Route path="/share/threads/:id" element={<ShareThreads/>}/>
				<Route path="/gamers/forgot-password" element={<ForgotPasswordGamer/>}/>
				<Route path="/genre/:id" element={<GenrePage/>}/>

				<Route path="/gamers/new-password/:id" element={<Gamer/>}/>
				<Route path="/gamers/new-username/:id" element={<Username/>}/>

				<Route path="/blank" element={<BlankPage/>}/>

				<Route path="/developer/profile" element={<Profile/>}/>
				<Route path="/logout" element={<Logout/>}/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;