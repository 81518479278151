import axios from "axios";
import { useEffect, useState } from "react";
import Signature from "../../../helpers/Signature";

import CryptoJS from "crypto-js";

const VerificationModal = (props) => {
    console.log('props', props)
    const [eligibleToVerify, setEligibleToVerify] = useState(false)

    //ID CARD
    const [idCardImage, setIdCardImage] = useState(null);
    const [idCardImagePreview, setIdCardImagePreview] = useState(null);
    const [showIdCardContainer, setShowIdCardContainer] = useState(false);

    //NPWP
    const [NPWPImage, setNPWPImage] = useState(null);
    const [NPWPImagePreview, setNPWPImagePreview] = useState(null);
    const [showNPWPImageContainer, setShowNPWPImageContainer] = useState(false);

    //COMPANY DATA
    const [CPImage, setCPImage] = useState(null);
    const [CPImagePreview, setCPImagePreview] = useState(null);
    const [showCPImageContainer, setShowCPImageContainer] = useState(false);

    const [dataIsSubmitted, setDataIsSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const clearIdCardImage = () => {
        setIdCardImage(null)
        setShowIdCardContainer(false)
        setIdCardImagePreview(null)
    }
    const clearNPWPImage = () => {
        setShowNPWPImageContainer(false)
        setNPWPImage(null)
        setNPWPImagePreview(null)
    }
    const clearCPData = () => {
        setShowCPImageContainer(false)
        setCPImagePreview(null)
        setCPImage(null)
    }

    const _handleUploadIdCard = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        setIdCardImage(file);

        if (file) {
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                setIdCardImagePreview({ "result": reader.result, "isImage": true, "name": file.name, "file": file });
                setShowIdCardContainer(true)
            };
        }
    }
    const _handleUploadNPWP = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        setNPWPImage(file);

        if (file) {
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                setNPWPImagePreview({ "result": reader.result, "isImage": true, "name": file.name, "file": file });
                setShowNPWPImageContainer(true)
            };
        }
    }
    const _handleUploadCPData = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        setCPImage(file);

        if (file) {
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                setCPImagePreview({ "result": reader.result, "isImage": true, "name": file.name, "file": file });
                setShowCPImageContainer(true)
            };
        }
    }
    const getTokenTrans = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        let payload = {}
        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature,
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,

        }
        
        try {
            let res = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/activate/v01/insertnew', payload, {
                headers: dataHeaders
            });

            if (res.data.status.status === 1) {
                uploadKYC(res.data.status.tokentrans);
                console.log('process success');
            }

            console.log('res_insertnew', res)

        }
        catch (err) {
            console.log('err', err)
        }
    }

    const uploadKYC = async (token) => {
        setLoading(true)
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = []

        let formData_idCard = new FormData();
        formData_idCard.append("file", idCardImage)

        let formData_npwp = new FormData();
        formData_npwp.append("file", NPWPImage)

        let formData_compProfile = new FormData();
        formData_compProfile.append("file", CPImage)

        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'userindex': resultUUID.user.userindex,
            'tokenlogin': resultUUID.user.usertokenlogin,
            'signature': signature,
            'tokentrans': token
        }
        try {
            let response_idCard = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/activate/v01/upload/idcard', formData_idCard, {
                headers: dataHeaders
            });
            console.log('response_idCard', response_idCard)
            if (response_idCard.data.status.status === 1) {
                try {
                    let response_npwp = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/activate/v01/upload/npwp', formData_npwp, {
                        headers: dataHeaders
                    });
                    console.log('response_npwp', response_npwp)
                    if (response_idCard.data.status.status === 1) {
                        try {
                            let response_CompProfile = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/activate/v01/upload/companydocument', formData_compProfile, {
                                headers: dataHeaders
                            });
                            if (response_CompProfile.data.status.status === 1) {

                                try {
                                    let response_Store = await axios.post(process.env.REACT_APP_HOSTNAMEAPI + '/api/activate/v01/store', {}, {
                                        headers: dataHeaders
                                    });
                                    console.log('response_Store', response_Store)
                                    if (response_Store.data.status.status === 1) {
                                        setDataIsSubmitted(true);
                                    }
                                } catch (err) {
                                    console.log('err_response_Store', err)
                                }
                            }
                            console.log('response_CompProfile', response_CompProfile)
                        }
                        catch (err) {
                            console.log('CProfile_err', err)
                        }
                    }
                }
                catch (err) {
                    console.log('NPWP_err', err)
                }
            }
            setLoading(false)
        } catch (err) {
            console.log('IdCard_err', err)
        }

    }
    useEffect(() => {
        console.log('isEf')
        if ((idCardImage !== null && CPImage !== null & NPWPImage !== null)) {
            setEligibleToVerify(true)
        }
        else {
            setEligibleToVerify(false)
        }
    }, [idCardImage, CPImage, NPWPImage])

    const buttonVerification = () => {
        if(eligibleToVerify){
            if(isLoading){
                return(
                    <button className="flex justify-center w-full rounded-lg bg-[#D2D2D2] py-6">
                        <span className="text-[20px] font-medium text-white">Process...</span>
                    </button>
                )
            }else{
                return(
                    <button className="flex justify-center w-full rounded-lg bg-bm1 py-6" onClick={() => getTokenTrans()}>
                        <span className="text-[20px] font-medium text-white">Activate My Account</span>
                    </button>
                )
            }
        }else{
            return(
                <button className="flex justify-center w-full rounded-lg bg-[#D2D2D2] py-6">
                    <span className="text-[20px] font-medium text-white">Activate My Account</span>
                </button>
            )
        }
    }
    return (
        <>

            <div className="bg-bg1 backdrop-blur-[3px] fixed z-10 inset-0"></div>
            <div className="fixed z-20 inset-0 overflow-auto">
                <div className=" w-full h-full relative">
                    <div className="absolute translate-y-[-50%] top-[50%] w-full">
                        <div className="max-w-[725px] m-auto">
                            <div className="mb-[-100px] w-full">
                                <img src={process.env.PUBLIC_URL + '/images/KYC/activate.svg'} alt="element" aria-hidden className="block ml-auto" />
                            </div>
                            <div className="bg-[#FFFFFF]  rounded-rd1 px-[48px] py-[48px]">
                                {
                                    dataIsSubmitted ?
                                        <>
                                            <h3 className="text-[#3D3F40] text-[48px] pb-4">Upload Success</h3>
                                            <p className="text-[#3D3F40] text-[20px] pb-1">Your document successfully uploaded!</p>
                                            <p className="text-[#989B9D] text-[20px] pb-5">Your document sedang dalam review oleh pihak The Space</p>
                                            <button className="flex justify-center w-full rounded-lg bg-bm1 py-6" onClick={props.cancel}>
                                                <span className="text-[20px] font-medium text-white">Close</span>
                                            </button>
                                        </>
                                        :
                                        <>
                                            <h3 className="text-[#3D3F40] text-[48px] pb-4">Activate your account</h3>
                                            <p className="text-[#3D3F40] text-[20px] pb-5">Please complete the KYC verification to activate your account.</p>
                                            <div className="flex flex-col gap-12">
                                                <div className="flex flex-col">
                                                    <div className="flex flex-row ">
                                                        <span className="font-semibold ">Upload ID Card</span>
                                                        <span className="font-semibold text-red-600">*</span>
                                                    </div>

                                                    <div className="flex flex-row rounded-lg border-[#D7DCDF] border-[1px] px-3 py-3 justify-between">
                                                        <div className="items-center justify-center flex">
                                                            <img src={process.env.PUBLIC_URL + '/images/KYC/idcard.svg'} alt="element" aria-hidden className="block ml-auto" />
                                                        </div>
                                                        <input
                                                            type="file"
                                                            name="idcard"
                                                            accept=".png, .jpg, .jpeg"
                                                            onChange={_handleUploadIdCard}
                                                            style={{ display: "none" }}

                                                        />
                                                        <button className="ml-3" onClick={() => document.querySelector('input[name="idcard"]').click()}>
                                                            <img className="h-[40px]" src={process.env.PUBLIC_URL + '/images/KYC/uploaddoc.svg'} alt="element" aria-hidden />
                                                        </button>

                                                    </div>
                                                    {
                                                        showIdCardContainer ?
                                                            <div className="flex flex-row rounded-lg bg-[#F3F3F3] border-[#D7DCDF] px-2 border-[1px]  py-3 h-[64px] w-full justify-between items-center mt-[6px]">
                                                                <div className="flex flex-row items-center">
                                                                    <img src={idCardImagePreview.result} alt="Preview" aria-hidden className="h-[40px] object-cover" />
                                                                    <label className="pl-3">{idCardImagePreview.name}</label>
                                                                </div>
                                                                <button className="ml-3" onClick={() => clearIdCardImage()}>
                                                                    <img src={process.env.PUBLIC_URL + '/images/KYC/trashcan.svg'} alt="element" aria-hidden />
                                                                </button>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="flex flex-col">
                                                    <div className="flex flex-row">
                                                        <span className="font-semibold">Upload NPWP</span>
                                                        <span className="font-semibold text-red-600">*</span>
                                                    </div>
                                                    <div className="flex flex-row rounded-lg border-[#D7DCDF] border-[1px] px-3 py-3 justify-between">
                                                        <div className="items-center justify-center flex">
                                                            <img src={process.env.PUBLIC_URL + '/images/KYC/npwp.svg'} alt="element" aria-hidden className="block ml-auto" />
                                                        </div>
                                                        <input
                                                            type="file"
                                                            name="npwp"
                                                            accept=".png, .jpg, .jpeg"
                                                            onChange={_handleUploadNPWP}
                                                            style={{ display: "none" }}

                                                        />
                                                        <button className="ml-3" onClick={() => document.querySelector('input[name="npwp"]').click()}>
                                                            <img className="h-[40px]" src={process.env.PUBLIC_URL + '/images/KYC/uploaddoc.svg'} alt="element" aria-hidden />
                                                        </button>

                                                    </div>
                                                    {
                                                        showNPWPImageContainer ?
                                                            <div className="flex flex-row rounded-lg bg-[#F3F3F3] border-[#D7DCDF] px-2 border-[1px]  py-3 h-[64px] w-full justify-between items-center mt-[6px]">
                                                                <div className="flex flex-row items-center">
                                                                    <img src={NPWPImagePreview.result} alt="Preview" aria-hidden className="h-[40px] object-cover" />
                                                                    <label className="pl-3">{NPWPImagePreview.name}</label>
                                                                </div>
                                                                <button className="ml-3" onClick={() => clearNPWPImage()}>
                                                                    <img src={process.env.PUBLIC_URL + '/images/KYC/trashcan.svg'} alt="element" aria-hidden />
                                                                </button>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="flex flex-col">
                                                    <div className="flex flex-row">
                                                        <span className="font-semibold">Upload Company Data</span>
                                                        <span className="font-semibold text-red-600">*</span>
                                                    </div>
                                                    <div className="flex flex-row rounded-lg border-[#D7DCDF] border-[1px] px-3 py-3 justify-between">
                                                        <div className="items-center justify-center flex">
                                                            <img src={process.env.PUBLIC_URL + '/images/KYC/companyData.svg'} alt="element" aria-hidden className="block ml-auto" />
                                                        </div>
                                                        <input
                                                            type="file"
                                                            name="cp_data"
                                                            accept=".png, .jpg, .jpeg"
                                                            onChange={_handleUploadCPData}
                                                            style={{ display: "none" }}

                                                        />
                                                        <button className="ml-3" onClick={() => document.querySelector('input[name="cp_data"]').click()}>
                                                            <img className="h-[40px]" src={process.env.PUBLIC_URL + '/images/KYC/uploaddoc.svg'} alt="element" aria-hidden />
                                                        </button>

                                                    </div>
                                                    {
                                                        showCPImageContainer ?
                                                            <div className="flex flex-row rounded-lg bg-[#F3F3F3] border-[#D7DCDF] px-2 border-[1px]  py-3 h-[64px] w-full justify-between items-center mt-[6px]">
                                                                <div className="flex flex-row items-center">
                                                                    <img src={CPImagePreview.result} alt="Preview" aria-hidden className="h-[40px] object-cover" />
                                                                    <label className="pl-3">{CPImagePreview.name}</label>
                                                                </div>
                                                                <button className="ml-3" onClick={() => clearCPData()}>
                                                                    <img src={process.env.PUBLIC_URL + '/images/KYC/trashcan.svg'} alt="element" aria-hidden />
                                                                </button>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                    {buttonVerification()}
                                                <div>
                                                    <button onClick={props.cancel} className="text-[#1100D0] underline flex justify-center items-center w-full mt-2"> Cancel</button>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default VerificationModal;