import { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import {
  FetchAPIGenre,
  FetchAPIlinkwallet,
} from "../../threadsAdd/controller/ThreadsAddController";
import Signature from "../../../../../helpers/Signature";
import Navbar from "../../../../../components/layouts/Navbar";
import Sidebar from "../../../../../components/layouts/Sidebar";
import ErrorCode from "../../../../../helpers/errorcode.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ErrorController from "../viewController/errorController";
import Spinner from "../../../../../components/loader/Spinner";
import CoinDistribution from "./CoinDistribution";
import Swal from "sweetalert2";

const ThreadsAdd = () => {
  const navigate = useNavigate();

  const inputTitle = useRef();
  const inputDescription = useRef();
  const inputStartDate = useRef();
  const inputStartEnd = useRef();
  const inputDemoLink = useRef();
  const inputGenre = useRef();

  const [selectedGenre, setSelectedGenre] = useState([]);
  const [isErrorTitle, setIsErrorTitle] = useState();
  const [isErrorDescription, setIsErrorDescription] = useState();
  const [isErrorDateStart, setIsErrorDateStart] = useState();
  const [isErrorDateEnd, setIsErrorDateEnd] = useState();
  const [isErrorGenre, setIsErrorGenre] = useState();
  const [isErrorDateRange, setIsErrorDateRange] = useState(false);
  const [openCoinDistribution, setOpenCoinDistribution] = useState(false);

  //advance setting
  const [isWalletLinked, setIsWalletLinked] = useState(false);
  const [walletData, setWalletData] = useState({});

  //Checker
  const [basicInfoData, setBasicInfoData] = useState({});

  const [listGenre, setListGenre] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [kycNeeded, setKYCNeeded] = useState(false);
  const _handleGenreDropDown = (e) => {
    const value = JSON.parse(e.target.value);
    const isExist = selectedGenre.some((x) => x.id === value.id);
    if (isExist === false && value !== "undefined") {
      setSelectedGenre((prev) => [...prev, value]);
    }
  };
  const _removeSelectedGenre = (item) => {
    const array = [...selectedGenre];
    const index = array.indexOf(item);
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedGenre(array);
    }
  };
  const _linkWalletHandler = async () => {
    const res = await FetchAPIlinkwallet();
    if (res.linked === true) {
      getWalletBallance();
    }else{
      console.log(res)
      Swal.fire({
        icon: "error",
        title: res.code,
        text: res.message,
      });
    }

    getWalletBallance();
  };

  function validateDate() {
    const x = new Date(inputStartDate.current.value);
    const y = new Date(inputStartEnd.current.value);

    const calc = (y.getTime() - x.getTime()) / (1000 * 3600 * 24);
    const errorDStart = inputStartDate.current.value === "" ? true : false;
    const errorEStart = inputStartEnd.current.value === "" ? true : false;
    const errorDateRange = calc <= 30 && y > x ? false : true;

    setIsErrorDateStart(errorDStart);
    setIsErrorDateEnd(errorEStart);
    setIsErrorDateRange(errorDateRange);

    if (!errorDStart && !errorEStart && !errorDateRange) {
      return true;
    } else {
      return false;
    }
  }

  function validateBIInput() {
    let errTitle = inputTitle.current.value === "" ? true : false;
    let errDesc = inputDescription.current.value === "" ? true : false;
    let errGenre = selectedGenre.length === 0 ? true : false;

    setIsErrorTitle(errTitle);
    setIsErrorDescription(errDesc);
    setIsErrorGenre(errGenre);

    if (!errTitle && !errDesc && !errGenre) {
      return true;
    } else {
      return false;
    }
  }
  const BasicInformationValidation = async () => {
    setShowSpinner(true);

    const arrayGenre = [];
    selectedGenre.forEach((key) => {
      arrayGenre.push(key.id);
    });
    const dateIsValid = validateDate();
    const inputBIIsValid = validateBIInput();
    if (dateIsValid && inputBIIsValid) {
      let body =
        inputDemoLink.current.value !== ""
          ? {
              threadtitle: inputTitle.current.value,
              threaddescription: inputDescription.current.value,
              datestart: inputStartDate.current.value,
              dateend: inputStartEnd.current.value,
              demolink: inputDemoLink.current.value,
              genre: arrayGenre,
              issharesocialmedia: false,
              sharesocialmediareward: 0,
              isplaydemo: false,
            }
          : {
              threadtitle: inputTitle.current.value,
              threaddescription: inputDescription.current.value,
              datestart: inputStartDate.current.value,
              dateend: inputStartEnd.current.value,
              genre: arrayGenre,
              issharesocialmedia: false,
              sharesocialmediareward: 0,
              isplaydemo: false,
            };

      setOpenCoinDistribution(true);
      setBasicInfoData(body);
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
    }
  };
  function redArsterik() {
    return (
      <>
        <label className="text-lg text-red-600">*</label>
      </>
    );
  }
  const getWalletBallance = async () => {
    let getUUID = localStorage.getItem("X3V1aWRfZGV2ZWxvcGVy");
    let decrypt = CryptoJS.AES.decrypt(getUUID, "salt").toString(
      CryptoJS.enc.Utf8
    );
    let resultUUID = JSON.parse(atob(decrypt));
    let payloadSignature = [];
    let signature = Signature(payloadSignature);

    let dataHeaders = {
      platform: "WEBSITE",
      gtoken: "PASSS",
      userindex: resultUUID.user.userindex,
      tokenlogin: resultUUID.user.usertokenlogin,
      signature: signature,
    };

    const currentTimestamp = new Date().getTime();


    try {
      let response = await axios.get(
        process.env.REACT_APP_HOSTNAMEAPI + "/api/thread/v01/walletbalance?id="+currentTimestamp,
        {
          headers: dataHeaders,
        }
      );
      console.log("res_walletbalance", response);
      if (response.data.status.status !== 0) {
        setIsWalletLinked(true);
        setWalletData(response.data.data);
      } else {
        response.data.status.message.forEach((element) => {
          // let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.errormessage : element.code
          // let replaceError = resultError.replace('###', element.codevariable);

          switch (element.code.substring(0, 9)) {
            case ErrorCode.unauthenticated:
              navigate("/login/developers");
              break;
            default:
              break;
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWalletBallance();
    const token = localStorage.getItem("tokentrans");
    console.log("token", token);
    if (token === "TOKEN_NOT_EXIST") {
      setKYCNeeded(true);
    } else {
      setKYCNeeded(false);
    }
    const fetchAPI = async () => {
      const value = await FetchAPIGenre();
      setListGenre(
        value.map((genreItem) => {
          return {
            label: genreItem.genrename,
            id: genreItem.genreid,
            selected: false,
          };
        })
      );
    };
    fetchAPI();
    // eslint-disable-next-line
  }, []);
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <>
      <div
        className="w-full min-h-full bg-no-repeat bg-cover"
        style={{ backgroundImage: "url('/images/bg-2.png')" }}
      >
        <div className="w-full h-full">
          <Sidebar />
          <div className="w-full h-full pl-[256px]">
            {showSpinner ? <Spinner></Spinner> : null}
            <div className="flex flex-col w-full h-full">
              <div className="w-full h-full max-w-[1500px] m-auto flex flex-col pl-3">
                <Navbar
                  heading="New Thread"
                  paragraf="Create your new thread, make your game come true"
                />

                <div className="w-full">
                  <div className="mt-[41px]">
                    <div className="flex flex-row">
                      <div className="flex-auto">
                        <div className="flex flex-row">
                          <button
                            className={
                              !openCoinDistribution
                                ? "bg-white border border-[#CDD5DF] py-[10px] px-[16px] rounded-tl-[8px] rounded-bl-[8px]"
                                : "border border-[#CDD5DF] py-[10px] px-[16px] rounded-tl-[8px] rounded-bl-[8px]"
                            }
                            onClick={() => setOpenCoinDistribution(false)}
                          >
                            Basic Information
                          </button>
                          <button
                            className={
                              openCoinDistribution
                                ? "bg-white border border-[#CDD5DF] py-[10px] px-[16px] rounded-tr-[8px] rounded-br-[8px]"
                                : "border border-[#CDD5DF] py-[10px] px-[16px] rounded-tr-[8px] rounded-br-[8px]"
                            }
                          >
                            Token Distribution
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        openCoinDistribution === false
                          ? "mt-10 block"
                          : "mt-10 hidden"
                      }
                    >
                      <div className="flex flex-row flex-wrap justify-between w-[80%]">
                        <div className="w-[40%]">
                          <div className="mb-8">
                            <label
                              htmlFor="title"
                              className="block mb-3 font-bold"
                            >
                              Thread Title {redArsterik()}
                            </label>
                            <div className="relative">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                                    stroke="#5D5F61"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
                                    stroke="#5D5F61"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <input
                                type="text"
                                ref={inputTitle}
                                className="bg-gray-50 border border-gray-300 focus:outline-none text-sm rounded-lg w-full pl-10 p-2.5"
                                placeholder="Placeholder"
                              />
                            </div>
                            {isErrorTitle ? (
                              <ErrorController
                                error={"title"}
                              ></ErrorController>
                            ) : null}
                          </div>
                          <div className="mb-8">
                            <label
                              htmlFor="description"
                              className="block mb-3 font-bold"
                            >
                              Thread Description {redArsterik()}
                            </label>
                            <textarea
                              name="description"
                              ref={inputDescription}
                              className="w-full border border-gray-300 h-[134px] rounded-lg pt-[12px] pl-[12px]"
                              placeholder="Description here"
                            ></textarea>
                            {isErrorDescription ? (
                              <ErrorController
                                error={"description"}
                              ></ErrorController>
                            ) : null}
                          </div>
                          <div className="flex flex-col w-full">
                            <div className="inline-flex gap-10">
                              <div className="w-1/2">
                                <label
                                  htmlFor="startdate"
                                  className="block mb-3 font-bold"
                                >
                                  Date Start {redArsterik()}
                                </label>
                              </div>
                              <div className="w-1/2">
                                <label
                                  htmlFor="startdate"
                                  className="block mb-3 font-bold"
                                >
                                  Date End {redArsterik()}
                                </label>
                              </div>
                            </div>

                            <div className="flex flex-row justify-between gap-10 mb-2">
                              <input
                                type="date"
                                ref={inputStartDate}
                                min={disablePastDate()}
                                className="bg-gray-50 border border-gray-300 focus:outline-none text-sm rounded-lg w-full p-2.5"
                              />
                              <input
                                type="date"
                                ref={inputStartEnd}
                                min={disablePastDate()}
                                className="bg-gray-50 border border-gray-300 focus:outline-none text-sm rounded-lg w-full p-2.5"
                              />
                            </div>
                            <div className="mb-3 text-sm font-semibold text-gray-500">
                              Maximum Thread duration is 30 day after this
                              Thread approved by admin
                            </div>

                            {isErrorDateStart ? (
                              <ErrorController
                                error={"datestart"}
                              ></ErrorController>
                            ) : null}
                            {isErrorDateEnd ? (
                              <ErrorController
                                error={"dateend"}
                              ></ErrorController>
                            ) : null}
                            {isErrorDateRange ? (
                              <ErrorController
                                error={"dateRangeNotValid"}
                              ></ErrorController>
                            ) : null}
                          </div>
                        </div>
                        <div className="w-[40%]">
                          <div className="mb-8">
                            <label
                              htmlFor="title"
                              className="block mb-3 font-bold"
                            >
                              Demo Link (Optional)
                            </label>
                            <div className="relative">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.8284 10.1716C12.2663 8.60948 9.73367 8.60948 8.17157 10.1716L4.17157 14.1716C2.60948 15.7337 2.60948 18.2663 4.17157 19.8284C5.73367 21.3905 8.26633 21.3905 9.82843 19.8284L10.93 18.7269M10.1716 13.8284C11.7337 15.3905 14.2663 15.3905 15.8284 13.8284L19.8284 9.82843C21.3905 8.26633 21.3905 5.73367 19.8284 4.17157C18.2663 2.60948 15.7337 2.60948 14.1716 4.17157L13.072 5.27118"
                                    stroke="#5D5F61"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <input
                                type="text"
                                ref={inputDemoLink}
                                className="bg-gray-50 border border-gray-300 focus:outline-none text-sm rounded-lg w-full pl-10 p-2.5"
                                placeholder="Placeholder"
                              />
                            </div>
                          </div>
                          <div className="mb-8">
                            <label
                              htmlFor="title"
                              className="block mb-3 font-bold"
                            >
                              Genre {redArsterik()}
                            </label>
                            <div className="flex flex-row flex-wrap w-full gap-3 mb-3">
                              {selectedGenre.map((item, idx) => {
                                return (
                                  <div className="flex flex-row items-center justify-around gap-6 px-6 py-3 bg-[#FFFF] rounded-md">
                                    <span>{item.label}</span>
                                    <button
                                      onClick={() => _removeSelectedGenre(item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faXmark}
                                        className="h-[2vh] w-[2vh]"
                                      />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="relative mt-0">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM15 12C15 13.6569 13.6569 15 12 15V17C14.7614 17 17 14.7614 17 12H15ZM12 15C10.3431 15 9 13.6569 9 12H7C7 14.7614 9.23858 17 12 17V15ZM9 12C9 10.3431 10.3431 9 12 9V7C9.23858 7 7 9.23858 7 12H9ZM12 9C13.6569 9 15 10.3431 15 12H17C17 9.23858 14.7614 7 12 7V9ZM17.6569 4.92893L14.1213 8.46447L15.5355 9.87868L19.0711 6.34315L17.6569 4.92893ZM14.1213 15.5355L17.6569 19.0711L19.0711 17.6569L15.5355 14.1213L14.1213 15.5355ZM9.87868 8.46447L6.34315 4.92893L4.92893 6.34315L8.46447 9.87868L9.87868 8.46447ZM8.46447 14.1213L4.92893 17.6569L6.34315 19.0711L9.87868 15.5355L8.46447 14.1213Z"
                                    fill="#5D5F61"
                                  />
                                </svg>
                              </div>
                              <select
                                onChange={(e) => _handleGenreDropDown(e)}
                                ref={inputGenre}
                                className="bg-gray-50 border border-gray-300 focus:outline-none text-sm rounded-lg w-full pl-10 p-2.5 cursor-pointer"
                              >
                                {selectedGenre.length === 0 ? (
                                  <option
                                    className="py-2 mt-3 "
                                    value={"undefined"}
                                  >
                                    {"Select"}
                                  </option>
                                ) : null}
                                {listGenre.map((key, idx) => {
                                  return (
                                    <option
                                      className="py-2 mt-3"
                                      value={JSON.stringify(key)}
                                    >
                                      {key.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {isErrorGenre ? (
                              <ErrorController
                                error={"genre"}
                              ></ErrorController>
                            ) : null}
                          </div>
                          <button
                            onClick={() =>
                              !kycNeeded ? BasicInformationValidation() : null
                            }
                            className={
                              !kycNeeded
                                ? " relative flex ml-auto mr-0 text-sm rounded-lg w-[30%] justify-center text-white bg-[#FF0000] border p-2.5"
                                : " relative flex ml-auto mr-0 text-sm rounded-lg w-[30%] justify-center text-white bg-gray-300 border p-2.5"
                            }
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <CoinDistribution
                      show={openCoinDistribution}
                      basicInfoData={basicInfoData}
                      isWalletLinked={isWalletLinked}
                      walletData={walletData}
                      _linkWalletHandler={_linkWalletHandler}
                    ></CoinDistribution>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThreadsAdd;
